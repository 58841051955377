.grn-report-container{
    width: 60vw;
    padding:2%;
    /* height: auto; */
}
.grn-report-container h3{
    margin: 2% 0 4% 0;
}
.grn-report-input-container{
    padding: 2%;
    border: 1px solid rgb(204, 194, 194);
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
}
.grn-net-amount-container{
    /* margin:1%; */
    padding: 1%;
    background-color: #a6a7af;
    display: flex;
    justify-content: flex-end;
}
.grn-report-net-amount-input p{
    color: #fff !important;
}
.grn-report-net-amount-input input{
    background-color: #fff !important;
}
.grn-report-table-container{
    margin: 2% 0;
}
.grn-report-btn{
   display: flex;
  justify-content: center;
  margin: 2% 0;
}