.utility-corrections-container{
    background-color: #fff;
    padding:1%;
    border-radius: 8px;
    margin: 1% 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.utility-corrections-container-top{
    
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.utility-corrections-container-top p{
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 20px;
}
.utility-corrections-container-top-right{
    display: flex;
    justify-content: space-evenly;
}


