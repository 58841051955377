.staff-dashboard-container{
    display: flex;
    flex-direction: column;
}
.staff-dashboard-body-container{
    display: flex;
    justify-content: space-between;
}
.staff-dashboard-total-expense-container{
    background-color: #fff;
    padding: 1%;
    width: 66%;
}
.staff-dashboard-total-expense-container .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.staff-dashboard-total-expense-container .top-bar p {
    font-weight: 600;
}
.staff-dashboard-total-expense-container .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #f27474 !important;
}

.staff-dashboard-notice-board-container{
    background-color: #fff;
    padding: 1%;
    width: 32%;   
}
.staff-dashboard-notice-board-container-body{
    height: 25vw;
    overflow-y: scroll;
}
.staff-dashboard-notice-board-container-body::-webkit-scrollbar {
    width: 2px;
    
    /* background-color: #d3e3ec; */
    background-color: #f1f1f1;
  }
.staff-dashboard-notice-board-container-body::-webkit-scrollbar-thumb {
    /* background-color: #a7c6d8; */
    background-color: #c1c1c1;
    height: 20px;
  }

.staff-dashboard-notice-board-container-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1%;
}
.staff-dashboard-notice-board-container-top .btn-notice-board{
    background-color: #e64949;
    width: 30%;
    font-size: 0.9rem !important;
}
.staff-dashboard-notice-board-container-section{
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 6px;
    position: relative;
    background-color: #eef2f9;
    align-items: center;
    padding: 1%;
    margin-top: 20px;
}
.staff-dashboard-notice-board-container-section-left{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 2% 5%;
    background-color: #efc6c3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.staff-dashboard-notice-board-container-section-left p{
    margin: 0;
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
}
.staff-dashboard-notice-board-container-section-right{
    width:60%;
}
.staff-dashboard-notice-board-container-section-right h4{
    margin: 0;
    font-size: 0.9rem;
}
.staff-dashboard-notice-board-container-section-right p{
    margin: 0;
    font-size: 0.6rem;
    font-weight: 600;
}
.staff-dashboard-body-container-two-right-notification-content h3{
    font-size: 1rem;
    margin: 1%;
}
.staff-dashboard-body-container-two-right-notification-content p{
    font-size: 0.9rem;
    margin: 1%;
}
.staff-dashboard-body-container-two{
    display: flex;
    justify-content: space-between;
    margin-top:1%;
}
.staff-dashboard-body-container-two-left{
    background-color: #fff;
    padding: 1%;
    width: 30%;
}
.staff-dashboard-body-container-two-right{
    background-color: #fff;
    
    width: 68%;
}

.chart-container-total-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 41%;
}
.chart-container-total-count h4,.chart-container-total-count h1{
    margin: 0;
}
.staff-dashboard-doughnut{
    position: relative;
}
.staff-dashboard-body-container-two-right .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%;
}
.staff-dashboard-body-container-two-right .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #ac0000 !important;
}


.staff-dashboard-body-container-two-right-notification{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.staff-dashboard-body-container-two-right-notification-left{
    content: "";
    height: 9vh;
    width: 8px;
    background-color: #e64949;
}
.staff-dashboard-body-container-two-right-notification-content{
    width: 83%;
    
}

.staff-dashboard-body-container-two-right-notification-right{
    margin-right: 3%;
}
.staff-dashboard-body-container-two-right-notification-right p{
    font-weight: 600;

}

.staff-dashboard-body-container-bottom{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin:2% 0;
}
.staff-dashboard-body-container-bottom .top-bar{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
}
.staff-dashboard-body-container-bottom .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #ac0000 !important;
}

.btn-green-outlined{
    background-color: #d4e3d3;
    color:#40a534;
    font-weight: 600;
    width: 75%;
    font-size: 0.75rem;
    height: 30px !important;
  }



  /* DialogBOxes */
  /* DialogBOxes */
  /* DialogBOxes */


  .notice-add-board-container{
    width: 30vw;
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
  }

  .notice-add-board-input{
    width: 100%;
  }
  .notice-add-board-input input{
    background-color: #eef2f9;
  }
  .notice-add-board-input textarea{
    resize: none;
    background-color: #eef2f9;
    height: 80px;
  }
  .notice-add-board-container-section{
    display: flex;
    justify-content:space-between;
  }
  .notice-add-board-container-section .notice-add-board-input{
    width: 45%;
  }
  .notice-add-board-container-section-two {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 60%;
  }
  .notice-add-board-container-section-two .notice-add-board-input{
    width: 32%;
  }



  .staff-dashboard-notice-board-container-section-delete-btn{
    position: absolute;
  }