.order-list-top-container{
    display: flex;
    justify-content: space-between;
    margin: 1% 2%;
}
.sales-order-list-search-container{
    display: flex;
    justify-content: center;
    position: relative;
    width: 36%;
    height: 36px;
}
.sales-order-list-search-container input{
   width: 100%;
   padding: 2%;
   font-size: 0.9rem;
}
.sales-order-list-search-container input::placeholder{
   font-size: 0.7rem;
 }
.sales-order-list-search-container .search-icon{
    position: absolute;
    right: 3%;
    top: 20%;
    color: #817e7e;
    font-size: 1.3rem;
    cursor: pointer;
}
.sales-order-list-payment-status{
    color:  #01be34;
}
.sales-order-list-status{
    background-color: #d4e3d3;
    color: #3da228;
    padding: 3% 12%;
    font-size: 0.75rem;
    font-weight: 600;
}
.sales-order-list-status-pending{
    background-color: #f4dbb9;
    color: #fe9b31;
    padding: 3% 12%;
    font-size: 0.75rem;
    font-weight: 600;
}
.sales-order-list-status-returned{
    background-color: #fbd0cd;
    color: #f70303;
    padding: 3% 8%;
    font-size: 0.75rem;
    font-weight: 600;
}
/* view */
.sales-order-list-view-container{
    width: 60vw;
    padding:2% 4% ;
}
.sales-order-list-view-container h2{
    margin: 0;
}
.sales-order-list-view-top-container{
    display: flex;
    justify-content: space-between;
}

.sales-order-list-view-top-left-first{
    margin: 14% 0;
}
.sales-order-list-view-top-left-first p{
    margin:6% 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.sales-order-list-view-top-left-Second{
    margin: 18% 0;
}
.sales-order-list-view-top-left-Second p{
    margin: 8% 0;
    font-size: 0.8rem;
}
.sales-order-list-view-top-right-address{
    text-align: right;
}
.sales-order-list-view-top-right-address p{
    margin: 2% 0;
    font-size: 0.9rem;
}

.sales-order-list-view-table{
  max-height: 75vh;
  overflow-y: scroll;
}
.sales-order-list-view-table table{
    width: 100%;
    border-collapse: collapse;
}
.sales-order-list-view-table table thead{
  position: sticky;
  top: -6px;
  background-color: #e8e8e8;
  height: 52px;
  z-index: 2;
}
.sales-order-list-view-table table thead tr th{
    height: 100%;
    text-align: center;
    font-size: 0.8rem;
}
.sales-order-list-view-table table tbody{
    min-height: 36px;
}
.sales-order-list-view-table table tbody tr td{
    background-color: #f3f4f6;
    text-align: center;
    min-height: 40px;
    height: 72px;
    font-size: 0.8rem;
}
.sales-order-list-view-table::-webkit-scrollbar{
    width: 4px;
    background-color: #d3e3ec;
}
.sales-order-list-view-table::-webkit-scrollbar-thumb{
    background-color: #a6b9c4;
}

.sales-order-list-view-bottom-container{
    padding: 2%;
    background-color:#f8f8f8;
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
}
.sales-order-list-view-bottom-left-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;


}
.sales-order-list-view-bottom-left-container p{
    margin: 16% 0;
    font-size: 0.8rem;
}
.sales-order-list-view-bottom-right-container{
    width: 43%;
}
.sales-order-list-view-bottom-right-single-container{
    display: flex;
    justify-content: space-between;
}
.sales-order-list-view-bottom-right-single-container h4{
    margin: 2% 0;
}
.sales-order-list-view-bottom-right-single-container p{
    margin: 2% 0;
}
.sales-order-list-total-amt-container{
    background-color: #ffe5e5;
    color:  #ff2d17;
    width: 26%;
    display: flex;
    justify-content: space-between;
    padding: 2%;
    margin: 0 2%;
    align-items: center;
    height: 37px;
}
.sales-order-list-total-amt-container p{
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
}

.order-list-filter-submit-ctn{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2%;
}