.arabic-text{
    text-align: right;
}
.billing-invoice-container{
    width:350px;
    height:auto;
    padding: 2%;
}
.billing-invoice-first-container{
    display: flex;
    justify-content: space-between;
    padding: 4%;
}
.billing-invoice-first-left-container{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.billing-invoice-first-left-container img{
    width:65%;
}
.restaurant-name{
    margin: 0;
    font-size:0.9rem;
    font-weight: 600;
    width:65%;
    text-align: center;
}
.restaurant-name-arabic{
    margin: 0;
    width:65%;
    font-size: 1.3rem;
    font-weight: 500;
   text-align: center;
}
.billing-invoice-first-left-container{
    /* text-align: right; */
    width: 50%;
}
.billing-invoice-first-right-container{
    width: 45%;
    /* text-align: right; */
}
.vat-container{
    display: flex;
    /* justify-content: flex-end; */
    width:100%;
    justify-content: space-between;
    
}
.invoice-date-time-label-container{
    width:35%;
    display:flex;
    align-items: flex-end;
    flex-direction: column;
}
.invoice-date-time-label-container p{
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
    
}
.invoice-date-time-data-container{
   width:65%
}
.invoice-date-time-data-container p{
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
}

/* .vat-container p{
    margin: 0;
    font-size:0.75rem;
    font-weight: 500;
   
} */

.billing-invoice-second-container{
    display: flex;
    justify-content: space-between;
    padding:2% 4%;
}
.billing-invoice-second-left-container{
    width: 50%;
}
.billing-invoice-second-right-container{
    width: 50%;
    display: flex;
    justify-content: right;
}
.billing-invoice-second-left-container p{
    width:70%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    margin: 0;
}
.billing-invoice-second-right-container p{
    width:73%;
    font-size: 1rem;
    font-weight: 600;
    text-align:right;
    margin: 0;

}
.billing-invoice-third-container{
    padding: 4%;
    display: flex;
    justify-content: space-between;
    width:100%
}
.billing-invoice-third-left-container{
    /* flex: 50; */
    width: 47%;
    /* display: flex; */

    /* justify-content: flex-start; */
}
.billing-invoice-third-right-container{
    width: 47%;
    /* display: flex; */
}
.billing-invoice-third-left-content{
   /* width: 100%; */
   display: flex;
   /* justify-content: space-between; */
   
   
}
.billing-invoice-third-left-head{
    width: 44%;
}
 .billing-invoice-third-left-head p{
   margin: 0;
   font-size: 0.7rem;
   font-weight: 500;
   text-align: left;
   /* line-height:1; */
} 
.staff-id{
    margin: 0;
    font-size: 0.7rem;
    font-weight: 500;
    width: 56%;
   
}
.bill-no{
    margin-top:4%;
}
.billing-invoice-table-container{
    display: flex;
    padding:4% 0;
}
.billing-invoice-table-container table{
    width: 100%;
    margin-top: 1%;
}
.billing-invoice-table-container table thead tr th{
    background-color: #fff; 
    font-size: 0.75rem;
    font-weight: 600;
    padding:0 4%;
    text-align: left;
    border-bottom: 2px dashed rgb(199, 197, 197);
    
}
.billing-invoice-table-container table tbody tr td{
    text-align: left;
    padding:0 4%;
    font-size: 0.92rem;
}

.heading-container{
    /* width: 40%; */
}
.heading-container p{
    margin: 0;

}
.content-container{
    margin:8px 0 4px 0;
}
.content-container p{
    margin: 0;
    line-height: 1;
    font-size: 0.7rem;
}
.table-arabic{
    text-align: left !important;
}
.divider-bottom{
    border-bottom:dashed 2px  rgb(199, 197, 197); 
}
.invoice-saved-text{
    margin: 8px auto 4px;
    font-weight: 600 !important;
}
hr{
    border: none;
}
.billing-invoice-sub-total-container{
    display: flex;
    padding: 4%;
    flex-direction: column;
}
.billing-invoice-sub-total-top-container{
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
}
.billing-invoice-sub-total-top-container P{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.total-arabic{
   text-align: right !important;
   font-weight:400 !important;

}
.billing-invoice-grand-total-top-container p{
    margin: 0;
    width:47%;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 500;
}
.billing-invoice-grand-total-bottom-container{
    display: flex;
    justify-content: space-between;
    margin: 4% 0 0 0;
}
.billing-invoice-grand-total-bottom-container p{
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: left;
    /* margin-left:20px; */
}
.grand-total-arabic{
    font-weight: 500 !important;
    font-size: 1.1rem  !important;
    text-align: right !important;
    margin-right:16px !important;
}
.billing-invoice-grand-total-bottom-left-container{
    width:40%;
}
.billing-invoice-qr-bar-code-container{
    display: flex;
    flex-direction: column;
    padding: 4%;
}
.billing-invoice-qr-bar-code-content-container p{
 margin: 0;
 width: 70%;
 font-size:1rem;
 font-weight: 500;
}
.billing-invoice-qr-bar-code-image-container{
    display: flex;
    padding: 4% 8%;
}
.invoice-bar-code{
    width:230px;
    height: 70px;
}
.invoice-qr-code{
    width:90px;
    height: 90px;
    position: relative;
    bottom: 10px;
    margin-left:28px;
}
.billing-invoice-terms-and-conditions-container{
    display: flex;
    padding: 4%;
    justify-content: space-between;
}
.billing-invoice-terms-and-conditions-left{
    width: 50%;
}
.billing-invoice-terms-and-conditions-left p{
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    
}
.invoice-terms-condition-content{
    font-size: 0.75rem !important;
    color: rgb(146, 143, 143);
    margin-top: 8px !important;
}
.billing-invoice-terms-and-conditions-right{
    width: 50%;
}
.billing-invoice-terms-and-conditions-right p{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}
.terms-arabic-content{
    font-size: 0.75rem !important;
    color: rgb(146, 143, 143);
    margin-top: 8px !important;
}
.invoice-bottom-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2% 0;
    
}
.invoice-bottom-container p{
    margin:1%;
    font-size: 1.2rem;
    font-weight: 600;
}



/************************************/
/* invoice print btn styling  */
.invoice-print-btn{
    margin: 4px 0;
    cursor: pointer;
    width:60px;
    height:60px;
    /* position: relative;
    right: 10px;
    bottom: 10px; */
    box-shadow: 0 0 8px rgb(182, 182, 182);
  }
  .invoice-print-btn:hover{
    background-color: #2e06ff !important;
  }
  .invoice-print-btn:hover .print-icon{
    color:#fff !important;
  }
  .invoice-print-icon-container{
    position: fixed;
    bottom: 8%;
    left: 47%;
  }
  .billing-parent-wrapper{
    position: relative;
  }