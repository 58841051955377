.print-cutting-slip-container {
  background-color: #fff;
  padding: 2%;
}
.print-cutting-slip-top-container {
  background-color: #f6f6f6;
  padding: 3%;
}
.print-cutting-slip-top-container h3 {
  margin: 1% 0;
}
.print-cutting-slip-top-input-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.print-cutting-slip-submit-btn {
  margin: 3% 1% 1% 4% !important;
}
.print-cutting-slip-table {
  margin: 4% 0 !important;
  padding: 0 !important;
}

/* single view css */
.print-cutting-slip-single-view {
  width: 62vw;
  padding: 3%;
}
.print-cutting-slip-view-top-container {
  background-color: #f6f6f6;
  padding: 2% 3%;
  margin-top: 3%;
}
.print-cutting-slip-view-heading-container {
  display: flex;
  justify-content: space-between;
}
.print-cutting-slip-view-top-container h3 {
  margin: 0;
}
.print-cutting-slip-view-input-container {
  display: flex;
}
.print-cutting-slip-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4% 0;
}
.tailor-update-btn{
  height: 28px;
  position: relative;
  top: 24px;
}