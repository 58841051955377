.utility-job-completion-container{
    background-color: #fff;
    padding: 1%;
    border-radius: 8px;
    margin: 1% 0;
    justify-content: center;
    align-items: center;
    display: flex;
}
.utility-job-completion-container-top{
    width: 100%;
    display: flex;
    padding: 2%;
    justify-content: space-between;
    align-items: flex-end;
}
.utility-job-completion-container-top-left{
    width: 80%;
    display: flex;
}

/* forgot password utility */
.utility-forgot-pass-container{
    background-color: #fff;
    float: right;
    width: 68%;
    padding: 1% 10%;
    border-radius: 8px;
    margin: 1% 0;
    justify-content: center;
    margin-right: 5px;
    align-items: center;
    display: flex;
}
.utility-forgot-pass-container-section{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.utility-forgot-pass-container-section-top{
    display: flex;
    align-items: center;
    margin: 7% 0;
    width: 60%;
    justify-content: space-around;
}

.utility-forgot-pass-container-section-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 7% 0;
}
.forgot-pass-input-utility input::placeholder{
    margin-left: 10px;
}
.utility-job-completion-table{
    margin: 1% 0 !important;
    padding: 2% !important;
}
.disabled-td-job-completion{
    color: #7b7b7c;
    cursor: no-drop;
}
.utility-job-completion-btn-ctn{
    display: flex;
    justify-content: center;
    margin: 4%;
}
.disabled-edit-job-completion td{
    cursor: no-drop;
    color: rgb(156, 156, 156)!important;
    pointer-events: none;
}
.disabled-edit-job-completion{
    cursor: no-drop;
    color: rgb(156, 156, 156)!important;
}
.job-completion-td-autocomplete{
    padding: 2% 1%;
}
.job-completion-td-autocomplete div.MuiOutlinedInput-root
{
    border: 1px solid #000;
}
.job-completion-td-autocomplete div fieldset {
    border: none;
    border-radius: 0;
  }
.edit-job-completion-input {
    width: 80px;
}
.edit-job-completion-input input{
    width: 60%;
    height: 40px;
    text-align: center;
    background-color: #eee;
    border: 1px solid #000;
}