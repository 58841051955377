.stock-item-monthly-summary .ledger-input-container-btn {
  height: 3em;
}
.stock-item-monthly-summary .input-current-asset-reports {
  justify-content: center;
  gap: 1% !important;
}
.stock-item-monthly-summary .ledger-input-container-btn {
  margin-top: 2.6em;
}
.stock-item-monthly-summary .total-container > div {
  padding: 0% !important;
  gap: 0% !important;
}
.stock-item-monthly-summary .total-container > div .total-head {
  flex: 0 0 !important;
  width: 10%;
  margin-right: 1%;
}
.stock-item-monthly-summary .total-container > div .global-single-input input {
  margin-right: 1%;
  width: 100% !important;
}
.stock-item-monthly-summary .total-container > div .global-single-input {
  margin-right: 2.2%;
  width: 10% !important;
  font-weight: 600;
}

.stock-item-voucher-report .total-container > div {
  gap: 2% !important;
  justify-content: flex-end;
  padding: 0 !important;
  width: 47% !important;
}
.stock-item-voucher-report .quantity-and-value {
  display: flex;
}
.stock-item-voucher-report .total-container > div .global-single-input {
  width: 50% !important;
}
.stock-item-voucher-report .total-head {
  width: 50%;
  padding-left: 37%;
  margin-top: 0.7em !important;
}
.location-summary .transfer-btn {
  height: 2em;
  width: 7em;
  border-radius: 5px;
  background-color: #f2d936;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.location-summary .data-table-row-head td {
  padding: 1.5% 0;
}

@media print {
  #currentAsset-report .stock-item-monthly-summary-total-container .total-head {
    width: 100%;
    margin: 1% 0;
    color: white;
    padding: 1% 6%;
  }

  #currentAsset-report .stock-item-monthly-summary-total-container > div {
    display: flex;
    gap: 1%;
    padding: 0;
  }

  #currentAsset-report
    .stock-item-monthly-summary-total-container
    > div
    .global-single-input {
    flex-grow: 1;
    background-color: white;
  }

  #currentAsset-report
    .stock-item-monthly-summary-total-container
    > div
    .global-single-input
    input {
    width: 100%;
    background-color: white;
    margin-right: 10%;
  }

  #currentAsset-report
    .stock-item-voucher-report-total-container
    .total-container {
    width: 100%;
  }
  #currentAsset-report
    .stock-item-voucher-report-total-container
    .total-container
    > div {
    gap: 5%;
    justify-content: flex-end;
    padding: 0 0;
  }
  #currentAsset-report
    .stock-item-voucher-report-total-container
    .quantity-and-value {
    display: flex;
    width: 100%;
  }

  #currentAsset-report .stock-item-voucher-report-total-container .total-head {
    width: 100%;
    padding-left: 45%;
    margin-top: 0.7em;
  }
  #currentAsset-report
    .stock-item-voucher-report-total-container
    > div
    .quantity-and-value {
    width: 100%;
  }
  #currentAsset-report
    .stock-item-voucher-report-total-container
    > div
    .quantity-and-value
    .global-single-input {
    width: 100%;
  }
  #currentAsset-report
    .stock-item-voucher-report-total-container
    > div
    .quantity-and-value
    .global-single-input
    input {
    width: 100%;
  }
}
