.supplier-manage-container{
    background-color: #fff;
    /* padding:1% 2%; */
}
.supplier-manage-top-container{
    display: flex;
    justify-content: space-between;
    padding:2%;
}
.supplier-manage-top-container button{
   width:30% !important;
}
.addSupplierDialog{
    width: 45vw;
    padding:2% 4%;
}
.addSupplierDialog h3{
    margin:1% 0;
}
.addSupplierDialog-input-container{
    display: flex;
    flex-wrap: wrap;
}
.add-supplier-input{
    width:48% !important;
    position: relative;
}
.add-supplier-divider{
    border:'none';
    border-top: 1px dotted rgb(128, 123, 123);
}
.add-supplier-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}

/* add credit limit dialog */
.supplier-add-credit-limit-container{
    width: 30vw;
    padding: 4%;
}
.add-credit-limit-input-container{
    display:flex;

}
.add-credit-limit-input{
    width:47%;
    margin:5%
}
.add-credit-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}
.add-credit-btn-container button{
    width: 25%;
}
.supplier-manage-top-container-right{
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
}