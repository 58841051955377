.invoice-main-container{
    width: 300px;
    padding:4%;
    height:auto;
}
.invoice-first-container{
    display: flex;
    justify-content: space-between;
    width:100%;
    padding:4% 2% 2% 2%;
  
}
.invoice-first-left-container{
    width:50%;
}

.invoice-first-left-container img{
  width:100%;
  /* height:50%; */
}
.invoice-first-left-container h4{
    margin: 1%;
    text-align: center;
    width: 100%;
}
.invoice-first-left-container p{
   margin: 1%;
   text-align: center;
   font-size: 0.75rem;
}
.invoice-first-right-container .date-arabic{
    text-align: left !important;
    margin: 0;
}
.invoice-first-right-container h5{
    margin: 8% 0 0 0;
    font-size: 0.7rem;
}
.invoice-hr{
    border :none;
    border-bottom: 1px solid #eee;
    margin:0
}
.invoice-hr-dashed{
    border :none;
    border-bottom: 2px dashed rgb(199, 197, 197);
    margin:0
}

.invoice-second-container{
    padding: 2%;
    background-color: #f1f4f7;
}
.invoice-second-label-data-container{
    display: flex;
    width :100%

}
.invoice-second-label-data-container h5{
    margin:0% 2%;
    font-size: 0.7rem;
}
.invoice-second-label-data-container .vat-label{
    width:40%
}
.invoice-second-label-data-container .vat-data{
    width:60%
}
.invoice-third-container{
    padding:1% 2%;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.invoice-third-container h5{
    margin:2%;
    font-size: 0.65rem;
}
.invoice-third-left-container{
    width:45%
}
.invoice-third-right-container{
    width:45%
}
.invoice-fourth-container{
    padding: 3% 2%;
    display:flex;
    justify-content:space-between;
    background-color: #f1f4f7;
}
.invoice-fourth-left-container{
    width:49%
}
.invoice-fourth-right-container{
    width:47%;
    margin-right: 1%
}
.invoice-fourth-left-first-container{
    display: flex;
}
.invoice-fourth-left-first-label-container{
    width:46%
}
.invoice-fourth-left-first-label-container h6{
    margin:0;
    font-size: 0.6rem;
}
.invoice-fourth-left-first-data-container{
    width:54%
}
.invoice-fourth-left-first-data-container h6{
    margin:0;
    font-size: 0.6rem;
    
} 
.billing-invoice-table-container{
    display: flex;
    padding:4% 0;
}
.billing-invoice-table-container table{
    width:100%;
    margin-top:1%
}
.billing-invoice-table-container table thead tr th{
    background-color: #fff; 
    font-size: 0.7rem;
    font-weight: 600;
    padding:0 4%;
    text-align: left;
    border-bottom: 2px dashed rgb(199, 197, 197);
}
.billing-invoice-table-container table tbody tr td{
    text-align: left;
    padding:0 4%;
    font-size: 0.8rem;
}
.heading-container p{
    margin:0;
    
}
.content-container{
    display:flex;
    flex-direction:column
}
.invoice-sub-total-container{
    padding:2%
}
.invoice-sub-total-first-container{
    display: flex;
    justify-content: space-between;
    margin:2% 0
}
.invoice-sub-total-first-left-container h5{
    margin:0
}
.invoice-sub-total-first-right-container h5{
 margin:0
}
.invoice-grand-total-container{
    padding: 2%;
}
.invoice-grand-total-first-container{
    margin: 2% 0;
    width:53%;
}
.invoice-grand-total-first-container h5{
margin:0;
}
.invoice-grand-total-second-container{
    display:flex;
    justify-content: space-between;
    margin:4% 0
}
.invoice-grand-total-second-container h4{
   margin:0;
   width: 100%;
}
.invoice-grand-total-second-container h5{
    margin:0;
}
.barcode-qrcode-container{
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    margin:6% 0
    
}
.barcode-qrcode-container-barcode {
    width:60%;
   
}
.barcode-qrcode-container-qrcode {
    width:20%;
    margin:0 3%
    
}
.terms-conditions-container{
    padding:2%;
    display:flex;
    justify-content: space-between;
}
.terms-conditions-left-container{
    width:47%;
}
.terms-conditions-right-container{
    width:47%
}
.terms-conditions-left-container p{
margin:0;
font-size:0.7rem
}
.terms-conditions-left-container h5{
    margin: 2% 0;
}
.terms-conditions-right-container p{
    margin:0;
    font-size:0.7rem
}
.terms-conditions-right-container h5{
    margin: 2% 0;
}
.invoice-last-container h4{
   text-align: center;
   margin:4% 0;
   width: 100%;
}
.saved-amount-container{
    margin: 2% 0;
    text-align: center;
}

.invoice-bill-tax-table{
    width: 100%;
}
.invoice-bill-tax-table h5{
    margin: 0;
}
.invoice-bill-tax-table tr td{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.invoice-bill-tax-table  th{
    border-bottom: 1px solid #e7e7e7;
}