.backup-settings-container{
    display: flex;
}
.backup-settings-container-left{
    margin-right: 1%;
    flex: 40% 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 3% 0;
    height: 85vh;
}
.backup-settings-container-left div:hover{
    background-color:#eeeff8 ;
}
.backup-settings-container-left-list{
    width: 100%;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 6% 8px 6% 6%;
    margin: 12px 0;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}

.backup-settings-container-right{
    flex: 55%;
}
.backup-settings-container-right-section{
    padding: 40px 20px;
    background-color: #fff;
    margin: 0 1%;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-direction: row;
}
.backup-settings-container-right-auto{
    width: 40%;
}
.backup-settings-right-container-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}


.backup-setting-path-button{
    margin: 0;
    border-radius: 0;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backup-setting-path-button img{
    width: 20px;
}


/* updates */

.backup-clear-data-confirmation-ctn{
    display: flex;
    width: 50vw;
    flex-direction: column;
    padding:5%;
}
.backup-clear-data-confirmation-img{
    text-align: center;
}
.backup-clear-data-confirmation-img img{
    width: 15%;
}
.backup-clear-data-confirmation-ctn h2{
    text-align: center;
    color: red;
}
.backup-clear-data-confirmation-btn{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}
.backup-clear-data-confirmation-btn button{
    width: 20%;
}
.backup-clear-data-confirmation-body{
    margin-top: 2%;
}
.backup-clear-data-confirmation-body p{
    font-weight: 600;
    font-size: 0.9rem;
}

.backup-clear-data-popup-ctn{
    display: flex;
    width: 50vw;
    flex-direction: column;
    padding:5%;
    margin: 10% 0;
    padding-bottom: 0;
}
.backup-clear-data-popup-img{
    text-align: center;
}
.backup-clear-data-popup-img img{
    width: 20%;
}
.backup-clear-data-popup-ctn h2{
    text-align: center;
    color: red;
}