.staff-payroll-item-container{
    display: flex;
    flex-wrap: wrap;
}
.staff-payroll-item-container-left{
    width: 48%;
    margin: 1%;
    padding: 2% 3%;
    background-color: #fff;
}
.staff-payroll-item-container-top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.staff-payroll-item-container-top .btn{
    background-color: rgb(51 102 255);
    width: 30%;
    font-size: 0.7rem;
}
.staff-payroll-item-container-top .btn:hover{
    background-color: rgb(0, 81, 255);

}
.staff-payroll-item-container-top .btn-staff-payroll-deduction{
    background-color: rgb(255 100 51);
}
.staff-payroll-item-container-top .btn-staff-payroll-deduction:hover{
    background-color: rgb(255, 60, 0);
}

.staff-payroll-item-table{
    padding: 0;
    margin: 0;
}
.staff-payroll-item-table thead tr th{
    text-align: start;
    padding-left: 1%;
}
.staff-payroll-item-table tbody tr td{
    text-align: start;
    padding-left: 1%;
    width: 25%;
}



/* DialogBox */
/* DialogBox */

.staff-payroll-overtime-container{
    width: 30vw;
    background-color: #fff;
    padding: 2% 8%;
    display: flex;
    flex-direction: column;
}
.staff-payroll-overtime-container .global-single-input{
    width: 100%;
}
.staff-payroll-overtime-container-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4% 0;
}
.staff-payroll-overtime-container-button .btn{
    width: 30%;
}

/*  */
.payroll-add-radio-button{
    display: flex;
    align-items: center;
    width: 100%;
}
.payroll-add-radio-button input{
    width: 3%;
    margin: 0 2% !important;
}
.payroll-add-radio-button label{
    font-size: 0.7rem;
}