.utility-shift-document-container{
    width: 60vw;
    padding: 3%;
    display: flex;
    flex-direction: column;
}

.utility-shift-document-container-top{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.utility-shift-document-container-top-left{
    display: flex;
    width: 80%;
}
/* .utility-shift-document-table th{
    background-color: #eee!important;
} */
.utility-shift-document-container-bottom{
    width: 100%;
    display: flex;
    justify-content: center;
}


.utility-shift-document-table thead{
    background-color: #eee !important;
    border-bottom: 20px solid #fff;
    height: 60px !important;
}
.utility-shift-document-table thead tr th{
    background-color: #eee !important;
}
.utility-shift-document-table tbody tr td input[type="checkbox"]{
    accent-color: #000;
    cursor: pointer;
}


.utility-payment-type{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.utility-payment-type button{
    background-color: #fff;
    border: none;
    cursor: pointer;
    height: 24px;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.utility-payment-type button img{
    width: 17px;
}
.utility-payment-amount{
    width: 13px !important;
}
.utility-payment-type-anchor-container{
   display: flex;
   padding: 1%;
   width: 500px;
   flex-wrap: wrap;
}
.input-payment-type-utility{
    width: 31% !important;
}
.utility-payment-type-heading{
    margin: 1% 2%;
    font-size: 0.9rem;
    font-weight: 600;
}
.utility-payment-type-btn{
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 2% 0;
}
.utility-payment-type-btn button{
    width:12% !important;
    font-size: 0.9rem;
}
.utility-amount-change-container{
    width: 800px;
    display: flex;
}
.utility-amount-change-left-container{
    width: 300px;
    border-right: 0.8px solid #eee;
}
.utility-amount-change-right-container{
    width: 300px;
}
.utility-amount-change-single-container{
    display: flex;
    padding: 2% 4% 2% 10%;
    align-items: center;
   
}
.utility-amount-change-single-container input{
    background-color: #eee;
    margin: 0 0 0 8%;
    border: none;
    height: 30px;
    padding: 0 4%;
    text-align: right;
    width: 35%;
}
.utility-amount-change-single-container p{
    font-size: 0.8rem;
    margin: 1%;
    width: 100px;
}
.utility-amount-change-left-container-input{
    width: 80%;
    background-color: #eee;
    border: none;
    height: 30px;
    padding: 0 4px;
    text-align: center;
}
.qty-utility-amount-input{
    width: 50% !important;
}
.utility-amount-change-middle-container{
    width: 300px;
    border-right: 0.8px solid #eee;
}
.utility-amount-change-single-container-grand-total{
    display: flex;
    background-color: rgb(248, 248, 248);
    margin: 0 5%;
    padding-left: 5%;
    padding-right: 0%;
}