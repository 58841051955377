.sales-quotation-icons{
    width: 42% !important;
    gap: 5px;
    justify-content: flex-end !important;
    margin: 1% 0 !important;
    align-items: center;
}
.sales-add-quotation-dialog-container{
    width: 60vw;
    padding: 2%;
}
.sales-add-quotation-dialog-container h3{
    margin: 0;
}
.sales-add-quotation-dialog-input-container{
    display: flex;
    flex-wrap: wrap;
}
.sales-add-quotation-dialog-item-container{
    display: flex;
    /* border: 1px solid rgb(148, 146, 146); */
    padding:1%;
    margin:1%;
    border-radius: 4px;
    align-items: center;
}
.add-quotation-item-container-add-button{
    background-color: #009b53;
    border: none;
    color: #fff;
    width: 6%;
    height: 40px;
    margin: 3% 0 0 2%;
    font-size: 1.4rem;
    border-radius: 4px;
    cursor: pointer; 

}
.sales-quotation-small-input{
    width: 15% !important;
}
.sales-quotation-amount-input{
    width: 17%;
}
.quotation-table{
    border: 1px solid rgb(148, 146, 146);
    border-radius: 4px;
    margin: 2% 1%;
}
.sales-add-quotation-bottom-container{
    padding: 1%;
    background-color: #eee;
    margin:3% 1%;
    display: flex;
    justify-content: space-around;
}
.sales-add-quotation-bottom-container .quotation-total-amt-input{
    width: 17% !important;
}
.sales-add-quotation-bottom-container .quotation-total-amt-input input{
   background-color: rgb(185, 182, 182);
  
}
.add-quotation-product-container{
    border: 1px solid rgb(148, 146, 146);
    border-radius: 4px;
    margin:2% 1%;
}
.add-quotation-product-container-radio{
    display: flex;
    margin: 1%;
    width: 45%;
    justify-content: space-between;
}
.add-quotation-product-container-radio input{
    cursor: pointer;
    accent-color: #000;
}
.add-quotation-product-container-radio label{
    font-size: 0.9rem;
    cursor: pointer;
}