.post-dated-cheque-summary.total-container > div {
  width: 100% !important;
}
.cheque-register.total-container > div {
  width: 92% !important;
}
.instrument-wise-report.post-dated-cheque-summary.total-container > div {
  padding: 0% 1% !important;
}
.instrument-wise-report.post-dated-cheque-summary.total-container
  > div
  .global-single-input {
  width: 11% !important;
}
.instrument-wise-report.post-dated-cheque-summary.total-container
  > div
  .global-single-input
  input {
  width: 100% !important;
}
.instrument-wise-report.input-current-asset-reports {
  gap: 0 !important;
}
.instrument-wise-report.input-current-asset-reports .global-single-input {
  width: 23% !important;
}

/* total container */
.total-amount-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1.5em 2em;
  background-color: #a6a7af;
  align-items: center;
}
.total-amount-wrapper .total-head {
  width: 3em;
  margin: 0;
  color: white;
}
.total-amount-wrapper .total-input {
  width: 10%;
  border: none;
  padding: 10px;
  background-color: white;
  border-radius: 2px;
}
/* ---------------- */
.cheque-register.total-amount-wrapper {
  padding: 1.5em 24% 1.5em 0;
}
.cheque-register.total-input {
  width: 13%;
}

@media print {
  .total-container > div {
    width: 100%;
  }
  .cheque-register.total-container > div {
    width: 90%;
  }
  .post-dated-cheque-summary.total-container > div {
    justify-content: flex-end;
  }
  .stock-item-monthly-summary .total-container > div .global-single-input {
    width: 100%;
  }

  .total-amount-wrapper {
    padding: 0.5em;
  }
  .total-amount-wrapper .total-input {
    width: 11%;
  }
  .cheque-register.total-amount-wrapper {
    padding: 0.5em 21.5% 0.5em 0;
  }
  .cheque-register.total-input {
    width: 18%;
  }
}
