.payment-voucher-parent-container .payment-voucher-main-container{
    background-color: #fff;
    padding: 1%;
}

.payment-voucher-single-view-container{
    width: 35vw;
    padding: 2%;
}
.payment-voucher-single-view-container .payment-voucher-single-view-form-container{
    display: flex;
    flex-wrap: wrap;
}
.payment-voucher-single-view-container .payment-voucher-single-view-form-container .pvsv{
    width: 48%;
}

.payment-voucher-single-view-container .cancel-submit-container{
    margin-top: 4%;
}