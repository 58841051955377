.reportForward-settings-container{
    display: flex;
}
.reportForward-settings-container-left{
    margin-right: 1%;
    flex: 40% 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 3% 0;
    height: 85vh;
}
.reportForward-settings-container-left div:hover{
    background-color:#eeeff8 ;
}
.reportForward-settings-container-left-list{
    width: 100%;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 6% 8px 6% 6%;
    margin: 12px 0;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}
.reportForward-settings-container-left>:nth-child(1){
    
    justify-content: space-between;
}
.reportForward-settings-container-right{
    flex: 55%;
}
.reportForward-settings-container-right-section{
    padding: 20px 20px;
    background-color: #fff;
    margin: 0 1%;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 4px;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.reportForward-settings-container-right-auto{
    width: 40%;
}
.reportForward-settings-right-container-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.reportForward-settings-container-left-list-checkbox{
    display: flex;
}
.reportForward-settings-container-left-list-checkbox label{
    cursor: pointer;
}
.reportForward-settings-container-right-section-input{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.reportForward-settings-container-right-section-input input{
    width: 90%;
    height: 40px;
    border: 2px solid gainsboro;
}
.btn-report-forward{
    width: 8%;
    background-color: #000;
    margin: 0;
}

.reportForward-settings-container-right-section-output{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    font-weight: 600;
    font-size: 0.9rem;
}



.reportForward-settings-container-right-section-body{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.reportForward-settings-container-right-section-body label{
    margin: 0 10px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}
.reportForward-settings-container-right-section-body input{
    cursor: pointer;
}
.report-forward-radio-ctn{
    display: flex;
}
.report-forward-radio-group{
    display: flex;
    align-items: center;
}
.report-forward-radio-group label{
    font-size: 0.9rem !important;
}