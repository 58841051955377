.sales-payments-container{
   padding: 2% 3% !important;
}
.sales-payments-container h3{
   margin: 0;
}
.sales-payments-input-container{
    display: flex;
    align-items: center;
    
}
.sales-payments-input-container button{
    margin: 2.5% 0 0 4%;
}
.sales-payments-table-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:1% 2%;
}
.sales-payments-table-top-left-container{
    display: flex;
    width: 44%;
    justify-content: space-between;
}
.sales-payments-icons{
    width: 21% !important;
    justify-content: space-between !important;
    margin: 1% 0 !important;
}
.sales-payments-dialog-container{
    width: 40vw;
    padding: 1% 4%;
    display: flex;
    flex-direction: column;
}
.sales-payments-dialog-input-container{
    display: flex;
    flex-wrap: wrap;
}
.sales-payments-dialog-input-container .sales-payments-input{
    width: 48%;
}
.sales-payments-view-button{
    align-self: center;
    width: 25%;
    margin: 8% 0;
}

.sms-icon-receipts{
    font-size: 1.1rem;
    color:rgb(72 29 252);
}
.email-icon-receipts{
    font-size: 1.1rem;
    color:rgb(223 35 35);
}
.whatsapp-icon-receipts{
    font-size: 1.1rem;
    color: rgb(45 190 79);
}