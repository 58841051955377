.clickable-card-parent-container{
    min-width: 150px;   
    width: 19%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 1% 0;
    cursor: pointer;
}
.label-container p.label{
    color: #fff;
}
.icon-container img{
    width: 30px;
    height: 30px;
    margin-top: 8px;
}