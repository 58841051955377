.custom-radio-group-container{
    /* width:34%; */
    padding: 2%;
    height:32px;
    display:flex;
    flex-direction: row;
    justify-content:space-around;
    align-items:center;
    background-color: #e64949;
    border-radius: 4px;
}
.custom-radio-group{

    width:34%;
    height:32px;
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
   

}
.custom-radio-group input[type="radio"]{
    display:none;
   
}
.custom-radio-group label{
    color:#fff;
    cursor:pointer;
    font-size: 0.9rem;
    border-radius:4px;
    margin:2% 3%; 
    width:89%;
    height:24px;
    padding:0px 6px;
}
.custom-radio-group input[type="radio"]:checked + label{
    background-color: #1900c8;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.custom-radio-group hr{
    transform: rotate(180deg);
    height:20px;
    border: none;
    border-right:1px solid #fff !important;
    /* margin: 14% 17%; */
    padding-right:8px
}
