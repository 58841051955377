.dashboard-employee-payslip-view-container {
  width: 55vw;
  padding: 1% 0;
}
.dashboard-employee-payslip-view-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
}
.dashboard-employee-payslip-view-top-icon-container {
  width: 25%;
  display: flex;
  justify-content: space-between;
}
.dashboard-employee-payslip-view-top-icon-container .payslip-print-icon {
  background-color: #cccfff;
  border-radius: 4px;
  width: 64px;
  height: 36px;
}
.dashboard-employee-payslip-view-top-icon-container .payslip-print-icon i {
  color: #2e3cff;
}
.dashboard-employee-payslip-view-top-icon-container .payslip-download-icon {
  border-radius: 4px;
  width: 64px;
  height: 36px;
  background-color: #fccccc;
}
.dashboard-employee-payslip-view-top-icon-container .payslip-download-icon i {
  color: #f10808;
}
.dashboard-employee-payslip-view-bottom-container {
  padding: 1% 4%;
}
.dashboard-employee-payslip-view-bottom-first-container {
  display: flex;
  justify-content: space-between;
}
.dashboard-employee-payslip-view-bottom-first-container p {
  font-size: 1rem;
  font-weight: 500;
  margin: 1% 0;
}
.dashboard-employee-payslip-view-bottom-container .payslip-view-table {
  display: flex;
  border-top: 1px solid rgb(221, 220, 220);
  border-left: 1px solid rgb(221, 220, 220);
  border-bottom: 1px solid rgb(221, 220, 220);
}
.dashboard-employee-payslip-view-bottom-container
  .payslip-view-table
  .earnings {
  width: 50%;
  border-right: 1px solid rgb(221, 220, 220);
}
.dashboard-employee-payslip-view-bottom-container
  .payslip-view-table
  .deduction {
  width: 50%;
}
.dashboard-employee-payslip-view-bottom-container .payslip-view-table h3 {
  margin: 0;
  padding: 4%;
  border-bottom: 1px solid rgb(221, 220, 220);
}
.dashboard-employee-payslip-view-bottom-container
  .payslip-view-table
  .earnings
  .earnings-sub-heading {
  display: flex;
}
.dashboard-employee-payslip-view-bottom-container
  .payslip-view-table
  .earnings
  .earnings-sub-heading
  p {
  /* width: 50%; */
  margin: 0;
  border-bottom: 1px solid rgb(221, 220, 220);
  padding: 2% 4%;
  font-size: 1rem;
  font-weight: 500;
}
.earnings-sub-heading .pay-type {
  border-right: 1px solid rgb(221, 220, 220);
  width: 60%;
}
.earnings-sub-heading .amount {
  width: 40%;
}
.earnings-content {
  display: flex;
}
.earnings-content .pay-type {
  width: 60%;
  padding: 4%;
  font-size: 0.8rem;
  border-right: 1px solid rgb(221, 220, 220);
  margin: 0;
}
.earnings-content .amount {
  width: 40%;
  padding: 4%;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
.total-earnings-salary {
  display: flex;
}
.total-earnings-salary .pay-type {
  width: 60%;
  margin: 0;
  padding: 6% 4%;
  border-top: 1px solid rgb(221, 220, 220);
  border-right: 1px solid rgb(221, 220, 220);
  font-weight: 600;
  font-size: 0.9rem;
}
.total-earnings-salary .amount {
  width: 40%;
  margin: 0;
  padding: 6% 4%;
  border-top: 1px solid rgb(221, 220, 220);
  font-weight: 600;
  font-size: 0.9rem;
}
.payslip-net-salary-container {
  display: flex;
  border: 1px solid rgb(221, 220, 220);
  padding: 2%;
  margin: 2% 0;
}
.payslip-net-salary-left-container {
  width: 30%;
}
.payslip-net-salary-left-container h4 {
  margin: 1% 0;
}
.payslip-net-salary-left-container p {
  margin: 1% 0;
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(192, 189, 189);
}
.payslip-address-container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(221, 220, 220);
  padding: 2%;
  margin: 2% 0;
  justify-content: center;
  align-items: center;
}
.payslip-address-container h4 {
  margin: 0;
}
.payslip-address-container p {
  width: 30%;
  text-align: center;
  font-size: 0.8rem;
}
