.add-offer-container{
    width: 75%;
    padding: 2% 3%;
    background-color: #fff;
}
.add-offer-container-top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.add-offer-container-top .global-single-input{
    width: 45%;
}

.add-offer-product-details-ctn{
    width: 100%;
    border: 1px solid #eee;
    padding: 2% 3%;
    
}
.add-offer-product-details-body{
    display: flex;
    align-items: center;
}
.add-offer-product-details-body img{
    width: 30%;
}
.add-offer-product-details-body-left{
    font-weight: 600;
    width: 35%;
}
.add-offer-product-choose-offer{
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.add-offer-product-choose-offer .global-single-input{
    width: 45%;
}
.product-choose-offer-buy-and-get-ctn{
    margin:1%;
    margin-top: 2%;
    width: 70%;
}
.product-choose-offer-buy-and-get{
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
}
.product-choose-offer-buy-and-get .global-single-input{
    width: 30%;
}
.product-choose-offer-buy-and-get-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
    border: 1px solid green;
    cursor: pointer;
}

.add-offer-product-conditions-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3% 0;
}
.add-offer-product-conditions-body .global-single-input{
    width: 45%;
    margin: 2% 1%;
}
.add-offer-product-submit-button{
    width: 100%;
    display: flex;
    justify-content: center;
}
.add-offer-bottom-section-top{
    display: flex;
    align-items: center;
}
.add-offer-bottom-section-top h3{ 
    margin-left: 2%;
}

.add-offer-bottom-section-bottom .global-single-input{
    width: 45%;
}
.add-offer-bottom-section-bottom{
    display: flex;
    /* width: 75%; */
    justify-content: space-between;
    align-items: flex-start;
}
.add-offer-bottom-section-bottom .global-single-input a.designation-footer{
    color: blue;
    font-size: 0.7rem;
    text-align: right;
    margin: 1% 0;
    text-decoration: none;
    cursor: pointer;
}
.add-offer-bottom-section-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5% 0;
}

.disable{
    opacity: 0.4;
}
.disable .global-single-input input, .disable a.designation-footer{
    pointer-events: none;
}

.validation-input-offer{
    position: relative;
}

.add-condition-btn{
    position: absolute;
    left: 90%;
    top: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 20px;
    color: #4cac00;
    font-size: 1.5rem;
    height: 20px;
    border-radius: 50%;
    align-items: center;
    background-color: rgb(250, 250, 250);
}
.add-condition-btn:hover{
    background-color: rgb(135, 168, 104);
    color: rgb(250, 250, 250);
}
.add-offer-conditions-list{
    background: #eee;
    margin-top: 2%;
}
.add-offer-conditions-list ul{
    padding-inline-start: 25px;

}
.add-offer-condition-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
}

.showConfirmBtn{
    display: flex;
    width: 100%;
    align-items: center;
}

.Rfqstaus{
 
    width: 100%;
    display: flex;
    /* align-items: end; */
    justify-content: end;
}