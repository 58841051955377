.sales-return-button-date-container{
    width: 37%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1%;
}
.sales-return-button-date-container button{
    width: 47% !important;
} 
  /* add return dialog */
.sales-add-return-container{
   width: 65vw;
   padding: 4%;
}
.sales-add-return-first-container{
    padding: 2%;
    box-shadow:0 0 8px 2px #eee ;
}
.sales-add-return-first-heading-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sales-add-return-first-heading-container h3{
    margin: 1% 0;
}
.sales-add-return-first-heading-radio-container{
    display: flex;
    /* width: 55%; */
    width: 65%;
}
.sales-add-return-first-heading-radio-container .add-sales-return-single-radio{
    width: 21%;
}
.sales-add-return-first-heading-radio-container .add-sales-return-single-radio input{
    accent-color: #000;
    cursor: pointer;
}
.sales-add-return-first-heading-radio-container .add-sales-return-single-radio label{
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}
.sales-add-return-first-heading-checkbox-container{
    display: flex;
    align-items: center;
    width: 11%;
    justify-content: space-between;
}
.sales-add-return-first-heading-checkbox-container input{
 accent-color: #000;
 width: 15px;
 height: 15px;
 cursor: pointer;
}
.sales-add-return-first-heading-checkbox-container label{
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
}
.sales-add-return-first-input-container{
    display: flex;
   
}

.sales-add-return-second-container{
    position: relative;
    padding: 2%;
    box-shadow:0 0 8px 2px #eee ;
    display: flex;
    margin: 2% 0;
    flex-wrap: wrap;
}
.sales-return-invoice-id{
    width: 15% !important;
}
.sales-return-unit{
    width: 6% !important;
}
.sales-return-rate{
    width: 10% !important;
}
.sales-return-product{
    width: 16% !important;
}
.sales-return-amount{
    width: 16% !important;
}
.sales-return-reason{
    width: 18% !important;
}

.sales-add-return-second-radio-container{
    margin: 1% 1% 1% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2% 0 0 0;
    width: 18%;
}
.sales-add-return-second-radio-single-container{
    display: flex;
    align-items: center;
    /* width: 70%;
    justify-content: space-between; */
}
.sales-add-return-second-radio-single-container input{
    margin: 0;
    accent-color: #000;
    cursor: pointer;
}
.sales-add-return-second-radio-single-container label{
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 8px;
    cursor: pointer;
}
.sales-add-return-second-radio-red-single-container{
    display: flex;
    align-items: center;
}
.sales-add-return-second-radio-red-single-container input{
    margin: 0;
    accent-color: red;
    cursor: pointer;
    border-color: red;
}
.sales-add-return-second-radio-red-single-container label{
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 8px;
    cursor: pointer;
    color: red;

}
.add-sales-return-plus-button{
    background-color: #000;
    color: #fff;
    font-size: 1.7rem;
    height:45px;
    width: 8%;
    margin: 3% 1%;
    cursor: pointer;
    
}
.sales-return-expand-auto-complete{
    width: 30% ;
}
.sales-return-expand-auto-complete:focus-within{
  width: 40%;
}
/* .sales-return-expand-auto-completediv .MuiOutlinedInput-root {
  width: 100%;
  height: 40px;
  margin: 8px 0;
} */