.in-or-out-container {
  margin: 1em;
}
.in-or-out-report-wrapper {
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 1em;
  flex-direction: column;
}
.in-or-out-report-wrapper .pagination-wrapper {
  display: flex;
  justify-content: flex-end;
}
.in-or-out-report-wrapper .sub-nav-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.in-or-out-report-wrapper .sub-nav-head > div .path-name-wrapper {
  background-color: #6073a7;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0.5em;
  color: white;
  max-width: 200px;
  font-size: medium;
  text-align: center;
}
.in-or-out-report-wrapper .path-name {
  padding-right: 15px;
  font-size: 14px;
}

.transaction-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  max-height: 5em;
  top: 0;
}
.transaction-table tbody {
  /* max-height: 50vh; */
  overflow-x: scroll;
}
.table-wrapper-container {
  overflow: hidden;
  position: relative;
  padding: 0 !important;
}

.table-wrapper-container .dynamic-table-body {
  height: calc(100vh - 280px);
  overflow-y: scroll;
}
.total-table .head {
  background-color: transparent;
  margin: 0;
}
.total-table .head td {
  color: transparent;
  border-bottom: 1px solid #d2deff;
}
.total-table tr td {
  width: calc(100% - 2em) !important;
}
.transaction-table .no-data-td {
  text-align: center;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.transaction-table th,
.transaction-table td {
  padding: 5px;
  border: 1px solid #ffffff;
  padding-left: 1em;
  /* padding: 5px 0 0 5px  */
}
.transaction-table .total-amount-cr-dr td {
  border: 1px solid #d2deff;
  background-color: #ffffff;
  color: black;
  font-weight: 700;
}
.transaction-table th,
.transaction-table .input-and-output-total td {
  background-color: #002995;
  color: white;
}
.transaction-table .input-and-output-total td {
  text-align: end;
  font-weight: 600;
}
.transaction-table .amount-td {
  text-align: end;
}

.transaction-table .even-row {
  background-color: #d2deff;
}

.in-or-out-container ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.in-or-out-container ::-webkit-scrollbar-track {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
}
.in-or-out-container ::-webkit-scrollbar-thumb {
  background: #002995;
  border-radius: 10px;
}
.in-or-out-container ::-webkit-scrollbar-thumb:hover {
  background: #002995;
}
