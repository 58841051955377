.dates-group-container{
    display: flex;
    align-items: center;
    margin-left: 1%;
    border-radius: 4px;
    box-shadow: 0 0 4px rgb(124, 124, 124);
}
.dates-group-container .date-input-group{
    position: relative;
    height: 32px;
}
.dates-group-container .date-input-group .custom-calendar-icon{
    position: absolute;
    top: 5px;
    left: 10px;
}
.dates-group-container .date-input-group input.date-input{
    display: flex;
    /* flex-direction: row-reverse; */
    height: 100%;
    padding: 0 4px;
    border: none;
    padding-left: 35px;
    border-radius: 4px;
    cursor: pointer;
}
.dates-group-container .date-input-group input.date-input:focus{
    outline: none;
}
.dates-group-container .date-input-group input.date-input::-webkit-calendar-picker-indicator{
    z-index: 2;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0%;
    height: 100%;
    /* transform: translateY(-50%); */
    /* margin:0 8px; */
    width: 100%;
    cursor: pointer;
}
.dates-group-container .vertical-line{
    width: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dates-group-container .vertical-line hr{
    border: none;
    border-bottom: 1px solid #000;
    transform: rotate(90deg);
    width: 24px;

}
