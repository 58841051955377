/**Page Container **/
.global-page-parent-container {
  margin: 1%;
  /* added on 5-12-2023 */
  /* display: flex;
  flex-direction: column;
  height: 90vh */
}
.parent-container-log {
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.global-white-bg-container {
  background-color: #fff;
  padding: 1%;
  margin: 4px 0;
  /* margin: 1% 0; */
  position: relative;
}
/** breadcrumb**/
.breadcrumb {
  font-weight: 600;
  font-size: 0.8rem;
}
/** hr global**/
.global-hr {
  border: none;
  border-bottom: 1px solid rgb(182, 182, 182);
  width: 100%;
}
.global-hr-dotted {
  border: none;
  border-bottom: 1px dotted rgb(138, 137, 137);
  width: 100%;
}
/*** Buttons ****/
.btn {
  width: 16%;
  height: 40px;
  cursor: pointer;
  /* font-size: 1rem; */
  font-size: 0.8rem;
  margin: 1%;
  border-radius: 4px;
  border: none;
  color: #fff;
}
.btn-primary {
  background-color: #1100ff;
}
.btn-primary:hover {
  background-color: rgb(4, 68, 207);
}
.btn-primary-disabled {
  /* background-color: rgb(204, 207, 238); */
  background-color: rgb(225, 225, 225);
  cursor: no-drop;
}
.btn-primary:disabled {
  background-color: rgb(225, 225, 225);
  cursor: no-drop;
}
.btn-secondary {
  background-color: #000;
}
.btn-secondary:hover {
  background-color: #323639;
}
.btn-red {
  background-color: red;
}
.btn-red:hover {
  background-color: rgb(209, 34, 34);
}
.btn-draft {
  background-color: #ff8000;
}
.btn-draft:hover {
  background-color: #a75e16;
}
.btn-secondary-outlined {
  border: 1px solid #323639;
  color: #323639;
  font-weight: 600;
  font-size: 0.95rem;
  background-color: transparent;
}
.btn-primary-outlined {
  border: 1px solid #0045da;
  color: #0045da;
  font-weight: 600;
  font-size: 0.95rem;
  background-color: transparent;
}
.btn-red-outlined {
  border: 1px solid red;
  color: red;
  font-weight: 600;
  font-size: 0.95rem;
  background-color: transparent;
}

.btn-secondary-outlined:hover {
  color: #000;
  border-color: #000;
}

.disabled {
  background-color: rgb(197, 197, 197) !important;
  cursor: not-allowed !important;
}
.disabled-outline {
  border: none !important;
  background-color: #eee !important;
  cursor: not-allowed !important;
  color: #fff;
}
.disabled-outline:hover {
  color: #fff;
}
.disabled-icon-button i {
  color: rgb(153, 153, 153) !important;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-field {
  height: 40px;
  background: #e7e7e7;
  width: 100px;
  text-align: center;
  padding: 1%;
  border-radius: 4px;
}

.primary-text {
  color: #0a33fe;
  font-weight: 600;
  margin: 0;
}
.create-button-blue-container {
  display: flex;
  /* margin: 2% 1% 0 1%; */
  margin: 1%;
  justify-content: space-between;
  align-items: center;
}
.create-button-blue-container h3 {
  margin: 0;
}
.create-button-blue {
  background-color: #8400b2;
  color: #fff;
  border: none;
  border-radius: 4px;
  height: 35px;
  width: 120px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  /* height: 45px; */
}
.create-button-blue:hover {
  background-color: #af0ce7;
}

/*** Table ***/
.global-table-container {
  padding: 1%;
  /* margin: 1%; */
  background-color: #fff;
  max-height: 75vh;
  /* overflow-y: scroll; */
  overflow-y: auto;
}
.global-table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
}
.global-table thead {
  position: sticky;
  top: -6px;
  background-color: #fff;
  height: 42px;
  z-index: 2;
}
.global-table thead tr th {
  background-color: #fff;
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}
.global-table tbody {
  min-height: 36px;
}
.global-table tbody tr {
  cursor: pointer;
}
.global-table tbody tr.edit-clicked-row {
  color: #0051ff;
}
.global-table tbody tr.edit-clicked-row td {
  height: 50px;
  font-size: 1rem;
}
.global-table tbody tr td {
  background-color: #eee;
  border-bottom: 8px solid #fff;
  height: 100%;
  text-align: center;
  min-height: 40px;
  height: 40px;
  font-size: 0.8rem;
}

tbody tr td.table-data-box {
  text-align: end;
  white-space: nowrap;
  gap: 10px;
  padding: 15px;
  min-height: 55px !important;
  max-width: 180px;
}
tbody tr td.table-data-box div.round-border {
  border: 0.9px solid #000;
  padding: 1px 7px;
  border-radius: 30px;
  background-color: #ffffff;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
tbody tr td.table-data-box div.table-data-round-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 0px;
  justify-content: center;
}
.global-table tbody tr td.table-data-box div.selected {
  background-color: #1e2eb6;
  color: #fff;
}

.global-table tbody tr td.table-data-box div:hover {
  background-color: #e7e7e7;
  color: #000;
}
.global-table td span.pending,
.table-data-box-pending {
  background-color: #ff8000 !important;
  color: #fff !important;
  padding: 5px 12px;
  border-radius: 4px;
  margin-left: 20%;
  text-align: center;
}
.global-table td span.drafted,
.table-data-box-drafted {
  background-color: #93a1e7 !important;
  color: #fff !important;
  padding: 5px 12px;
  border-radius: 4px;
  margin-left: 20%;
  text-align: center;
}

.global-table td span.purchaseOrder {
  background-color: #f8a69a !important;
  color: #fff !important;

  padding: 5px 12px;
  border-radius: 4px;
  margin: 2% auto;
  text-align: center;
}
.global-table td span.PAID {
  background-color: #5d91f3 !important;
  color: #fff !important;

  padding: 5px 12px;
  border-radius: 4px;
  margin: 2% auto;
  text-align: center;
}

.global-table td span.Cancel {
  background-color: #9c4f61 !important;
  color: #fff !important;

  padding: 5px 12px;
  border-radius: 4px;
  margin: 2% auto;
  text-align: center;
}

.global-table td span.rfq {
  background-color: #9c8654 !important;
  color: #fff !important;

  padding: 5px 12px;
  border-radius: 4px;
  margin: 2% auto;
  text-align: center;
}

.global-table td span.Done {
  background-color: #119118 !important;
  color: #fff !important;

  padding: 5px 12px;
  border-radius: 4px;
  margin: 2% auto;
  text-align: center;
}

.table-data-box input {
  background-color: transparent;
  border: 0.5px solid #000;
  border-radius: 5px;
  width: 60%;
  padding: 4px;
  text-align: center;
}

.global-input-field-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  padding: 2% 0;
}
.plus-btn {
  height: 30px;
  margin: 1%;
  width: 40px;
  border: none;
  background-color: #00b900;
  color: #fff;
  border-radius: 3px;
  font-size: 1.4rem;
}
.plus-btn:hover {
  cursor: pointer;
}
.plus-btn:disabled {
  cursor: not-allowed;
  background-color: #e7e7e7;
}
.edit-icon1 {
  color: #0045da;
}
.save-icon {
  color: #000;
}
.save-icon:disabled {
  color: #606060;
  cursor: not-allowed;
}
.clear-btn {
  height: 40px;
  margin: 1%;
  width: 50px;
  border: none;
  background-color: red;
  color: #fff;
  border-radius: 3px;
  font-size: 1.2rem;
  cursor: pointer;
}
.global-table td span {
  display: flex;
  width: 60%;
  justify-content: center;
}
.global-table-container::-webkit-scrollbar {
  width: 4px;
  /* background-color: #d3e3ec; */
  background-color: #f1f1f1;
}
.global-table-container::-webkit-scrollbar-thumb {
  /* background-color: #a7c6d8; */
  background-color: #c1c1c1;
}
.table-total {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.table-total div {
  width: 12%;
  display: flex;
  align-items: center;
  gap: 6px;
}
.table-total input {
  width: 60%;
  height: 40px;
  text-align: end;
  padding: 0 5px;
}
/*** Date Related ***/
.global-date-input-container {
  height: 40px;
  position: relative;
}
.global-date-input-container .arrow-down {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  z-index: 0 !important;
  color: #0045da;
}
.global-date-input-container .calendar-icon {
  position: absolute;
  /* left: 8px;
  top: 4px; */
  left: 4px;
  top: 8px;
  color: #1100ff;
  /* width: 21px;
  height: 40px; */
}
.global-date-picker {
  height: 100%;
  border: none;
  color: #1100ff;
  font-weight: 600;
  cursor: pointer;
  padding: 0 8px;
  padding-left: 35px;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.global-date-picker::-webkit-calendar-picker-indicator {
  opacity: 0;
  appearance: none;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0%;
  height: 100%;
  width: 100%;
  /* font-size: 22px; */
}
.global-date-picker:focus {
  outline: none;
}

/* date picker container style */
.date-picker-container {
  background-color: #f1f2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border-radius: 4px;
  height: 40px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2% 1%;
}

/** Status Classes (paid,pending,cancelled) **/
.status {
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: 600;
}
.paid {
  background-color: #cae6dc;
  color: #01b768;
  padding: 8px 28px;
}
.pending {
  background-color: #efdecd;
  color: #ff8000;
  padding: 8px 17px;
}
.cancelled {
  background-color: #eacbcd;
  color: #db0000;
  padding: 8px 12px;
}
.delivered {
  background-color: #cfd4e6;
  color: #0a33fe;
  padding: 8px 20px;
}
/**Action Icons Common classes **/
.visibility-icon {
  color: #5ba329;
  font-size: 1.3rem;
}
.edit-icon {
  color: #0045da;
  font-size: 18px;
}
.delete-icon {
  color: #db0000;
  font-size: 20px;
}
.printer-icon {
  color: #01b768;
  font-size: 1.4rem;
}
.download-icon {
  color: #f19800;
  font-size: 1.4rem;
}
.print-icon-container {
  background-color: #c4e4de !important;
  /* padding: 1% 2% 1% 2% !important; */
  border-radius: 4px !important;
  width: 91px;
  margin: 1% !important;
  cursor: pointer;
  height: 37px;
}
.download-icon-container {
  background-color: #f3ddcc !important;
  /* padding: 1% 2% 1% 2% !important; */
  border-radius: 4px !important;
  width: 91px;
  margin: 1% !important;
  cursor: pointer;
  height: 37px;
}
.print-icon-container:hover {
  background-color: #b3ddd5 !important;
}
.download-icon-container:hover {
  background-color: #eec6a9 !important;
}
.edit-icon-disabled {
  font-size: 18px;
}

/** View Icon  */
/* <IconButton>
     <i class="bi bi-eye visibility-icon"></i>
   </IconButton> */

/** Edit Icon  */
/* <IconButton>
  <i class="bi bi-pencil-square edit-icon"></i>
</IconButton> */

/** Delete Icon */
/* <IconButton>
<i class="bi bi-trash3 delete-icon"></i>
</IconButton> */

/**Print Icon **/
/* <IconButton>
<i class="bi bi-printer printer-icon"></i>
</IconButton> */

/** download icon **/
/* <IconButton>
<i class="bi bi-arrow-down-circle download-icon"></i>
</IconButton> */

/*** Image inside Table **/
.table-img {
  width: 16px;
}

/** global radio button **/
.global-radio-button {
  width: auto;
  display: flex;
}
.global-radio-button input[type="radio"] {
  accent-color: #000;
  margin: 0 4%;
  cursor: pointer;
}
.global-radio-button label {
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  /* margin: 0 4%; */
}

/**Global checkbox **/
.global-checkbox {
  accent-color: #000;
  cursor: pointer;
  transform: scale(1.3);
}

/*** Inputs ****/
input:focus {
  outline: none;
}
.global-single-input {
  position: relative;
  width: 23%;
  margin: 1% 2px;
  display: flex;
  flex-direction: column;
}
.global-single-input input::placeholder {
  font-size: 0.9rem;
}
.disabled-input {
  caret-color: transparent;
  cursor: not-allowed;
}
.global-single-input div.MuiOutlinedInput-root   {
  width: 100%;
  border: none;
  background-color: #eee;
  height: 30px;
}
.auto-complete div.MuiOutlinedInput-root,.new-auto-complete div.MuiOutlinedInput-root {
  /* height: 30px !important; */
  height: 30px;
  padding-right: 30px !important;
}
.auto-complete .MuiAutocomplete-input {
  height: 0px;
}
.auto-complete .MuiAutocomplete-root {
  background-color: #eeeeee;
  border-radius: 4px;
}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}
.auto-complete .MuiInput-root {
  padding-bottom: 1px;
  min-height: 30px;
}
.global-single-input
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 8px !important;
}
/* .global-single-input div.MuiOutlinedInput-root .css-qiwgdb{
  padding:1px 14px !important; 
} */
.global-single-input div fieldset {
  border: none;
  border-radius: 0;
}
.global-single-input p {
  margin: 0 0 6px 0;
  font-size: 0.8rem;
  font-weight: 600;
}
.global-single-input input {
  width: 100%;
  border: none;
  background-color: #eee;
  /* font-size: 1rem; */
  font-size: 0.8rem;
  height: 30px;
  padding: 8px;
  border-radius: 4px;
}
.global-single-input input:disabled {
  cursor: no-drop;
  color: rgb(156, 156, 156);
}
.global-single-input input:focus,
.global-single-input textarea {
  outline: none;
  resize: none;
}
.global-single-input textarea {
  width: 100%;
  padding: 8px;
  /* height: 12vh; */
  background-color: #eee;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
}

.suffix-button {
  color: red;
  font-size: 0.7rem !important;
  cursor: pointer;
  width: fit-content;
  margin-top: 6px !important;
  margin-left: auto !important;
  padding: 2px;
  border-radius: 2px;
}
.suffix-button:hover {
  background-color: #ffe6e6;
}
.suffix-text {
  color: red;
  font-size: 0.7rem !important;
}
.suffix-text-blue {
  color: #0011f8;
  font-size: 0.7rem !important;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
  margin-top: 4px !important;
  padding: 0 1%;
}
.suffix-text-blue:hover {
  background-color: #e7e9fd;
  border-radius: 4px;
}
.suffix-text-red {
  color: red;
  font-size: 0.7rem !important;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
  margin-top: 4px !important;
  padding: 0 1%;
}
.suffix-text-red:hover {
  background-color: rgb(241, 213, 213);
  border-radius: 4px;
}
.disabled-input-container p {
  color: #e3e0e0;
}
.disabled-input-container p.suffix-button {
  color: #eee;
  cursor: default;
}
.disabled-input-container p.suffix-text-blue:hover {
  background-color: #fff !important;
  cursor: auto;
}

/**Arabic Name **/
.arabic-name p {
  width: fit-content;
  margin-left: auto;
}
.arabic-name input {
  text-align: right;
}

/* status */
.status-completed {
  background-color: #008453;
  color: #fff;
  border-radius: 16px;
  font-size: 0.75rem !important;
  padding: 2%;
}
.status-pending {
  background-color: #ec8712;
  color: #fff;
  border-radius: 16px;
  font-size: 0.75rem !important;
  padding: 2%;
}
.status-cancelled {
  background-color: #f10000;
  color: #fff;
  border-radius: 16px;
  font-size: 0.75rem !important;
  padding: 2%;
}

.logout-btn-dashboard-mob,
.logout-btn-dashboard-chip-mob,
.logout-mob {
  display: none;
}
.desktop-logout {
  display: block;
}

.accounts-name-list {
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
}
.accounts-name-list-value {
  font-weight: 500;
  font-size: 1rem;
  color: #949494;
}
/**** Media Queries */
@media only screen and (max-width: 450px) {
  .logout-btn-dashboard-mob {
    /* position: absolute;
    right: 0;
    top: 125%;
    z-index: 4; */
    display: block;
  }
  .desktop-logout {
    display: none !important;
  }
  .logout-btn-dashboard-chip-mob {
    display: block;
  }

  .logout-mob {
    display: block;
    margin-left: 8%;
  }
}

/* alertmessage  */
.success-alert-message-submit-btn {
  background-color: #0011f8 !important;
}
.success-alert-message-cancel-btn {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  color: #000 !important;
}

/* delete alert style */
.icon-color {
  color: #f80000 !important;
  border-color: #f80000 !important;
}

/* .icon-color .swal2-icon.swal2-warning{

} */
/* no records found  */
.no-data {
  width: fit-content;
  margin: 4px auto;
}

/* report print styling */
.report-print-view-table-with-clr tbody tr:nth-child(odd) td {
  background-color: #fff !important;
}
.report-print-view-table-with-clr tbody tr:nth-child(even) td {
  background-color: #eee !important;
}
.report-print-view-table-with-clr tr td {
  border-bottom: none !important;
}
.report-print-view-table-with-clr thead tr th {
  text-align: left !important;
}
.report-print-view-table-with-clr tbody tr td {
  text-align: left !important;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.global-single-input1 {
  display: flex;

  margin: 1px;
  margin-bottom: 21px;
  align-items: self-end;
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 20px;
}
.global-single-input1 .TaxCreateCheckBox {
  width: 1.2rem;
  height: 1.2rem;
  margin: 10px;
}

.global-items-per-page-container {
  display: flex;
  align-items: center;
  width: 20%;
  margin: 0 1%;
  /* height: 20px; */
}
.global-items-per-page-container p {
  margin: 0;
  font-size: 0.8rem;
  width: 100%;
}
.global-items-per-page-container div.MuiOutlinedInput-root {
  height: auto !important;
  padding-right: 30px !important;
}
.global-items-per-page-container .MuiAutocomplete-input {
  height: 7px;
}
.gray-status {
  width: 120px;
  margin: 0 1%;
  border: 1px solid #eee;
  background-color: #eee;
  color: rgb(128, 126, 126);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5%;
  height: 36px;
  border-radius: 4px;
}

/* table new design  */
.new-global-table-container {
  padding: 1%;
  background-color: #fff;
  max-height: 75vh;
  overflow-y: auto;
}
.new-global-table-container::-webkit-scrollbar {
  width: 4px;
  /* background-color: #d3e3ec; */
  background-color: #f1f1f1;
}
.new-global-table-container::-webkit-scrollbar-thumb {
  /* background-color: #a7c6d8; */
  background-color: #c1c1c1;
}
.new-global-table-container table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
}
.new-global-table-container table thead {
  position: sticky;
  top: -6px;
  background-color: #edf2f9;
  height: 50px;
  z-index: 2;
}
.new-global-table-container table thead tr th {
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}
.new-global-table-container table tbody {
  min-height: 36px;
}

.new-global-table-container table tbody tr td {
  border-bottom: 4px solid #edf2f9;
  height: 100%;
  text-align: center;
  min-height: 40px;
  height: 50px;
  font-size: 0.8rem;
  cursor: pointer;
}

.new-global-white-bg-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 1% 2%;
  margin: 1% 0;
}
.new-global-white-bg-icon-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.new-global-white-bg-icon-container-left {
  width: 40%;
}
.table-no-data {
  /* border: 3px solid #fff;
  background-color: #f1f1f1; */
  height: 30px !important;
}
.btn-post {
  width: 80px;
  background-color: #1100ff;
  margin: 0 1%;
  height: 30px;
}
.status-container {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: rotate(0deg);
  right: -2%;
  top: 0%;
  /* border: 2px solid orange; */
}
.status-container img {
  /* border: 2px solid red; */
  width: 100%;
}
.status-container::after {
  /* content: "";
  position:absolute;
  margin:-20px;
  width:40px;
  height:40px;
  transform:rotate(45deg);
  background-color:#edf2f9; */
}

.status-container p {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.global-name {
  margin: 0;
  font-size: 1.2rem;
  color: #868484;
}
.new-global-input-container {
  display: flex;
  flex-wrap: wrap;
}
.new-global-single-input {
  width: 22%;
  margin: 8px;
  position: relative;
}
.new-global-single-input div.MuiOutlinedInput-root {
  width: 100%;
  border: none;
  height: 40px;
}
.new-global-single-input .MuiAutocomplete-root {
  width: 100%;
}
.new-global-single-input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.new-global-single-input
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: 10px;
}
/* .new-global-single-input .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
color: #000;
font-size: 1rem;
} */
.new-global-single-input .MuiFormLabel-root.Mui-focused {
  color: #000;
  font-size: 1rem;
}

.new-global-single-input
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #bebebe !important;
}

/* global product table */
.global-product-table {
  /* padding: 1%; */
  background-color: #fff;
  max-height: 26vh;
  overflow-y: auto;
  /* min-height: 30vh; */
  
}
.global-product-table table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
}
.global-product-table table thead {
  position: sticky;
  top: -6px;
  background-color: #dee3f8;
  height: 30px;
  z-index: 2;
}
.global-product-table table thead tr th {
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}
.global-product-table table tbody {
  min-height: 36px;
}
.global-product-table table tbody tr td {
  border: 1px solid #000;
  height: 100%;
  text-align: center;
  min-height: 40px;
  /* height: 30px; */
  font-size: 0.8rem;
}
.global-product-table table tbody tr td input {
  height: 100%;
  width: 100%;
  border: none;
}
.global-product-table::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1;
}
.global-product-table::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.global-product-table-input-icon{
  display: flex;
 
}
.global-product-table-input-icon img{
  width: 20px;
  cursor: pointer;
}
/* .tooltip-container {
 
  display: inline-block;
}

.tooltip-text {
  visibility: visible;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  
 
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: hidden;
  opacity: 1;
} */

.table-data-box span {
  padding: 5px 12px;
  border-radius: 4px;
  /* margin-left: 20%; */
  text-align: center;
}
.table-data-box span.draft {
  background: #9dabf0;
  color: #fbfbfe;
}
.table-data-box span.available {
  background: #8edbe1;
  color: #fbfbfe;
}
.table-data-box span.return {
  background: #a40052;
  color: #fbfbfe;
}
.table-data-box span.paid {
  background: #3fa3dd;
  color: #fbfbfe;
  padding: 5px 26px;
}
.table-data-box span.post {
  background: #9ee694;
  color: #fbfbfe;
}
.table-data-box span.inprogress {
  background: #78a9c9;
  color: #fbfbfe;
}

.table-data-box span.rfq {
  background: #e694e1;
  color: #fbfbfe;
}

.table-data-box span.cancel {
  background: #e69494;
  color: #fbfbfe;
}

.table-data-box span.po {
  background: #ffbb6d;
  color: #fbfbfe;
}

.table-data-box span.sales-order {
  background: #ffbb6d;
  color: #fbfbfe;
}
.table-data-box span.done {
  background: #e69494;
  color: #fbfbfe;
}
.table-data-box span.requested {
  background: #ffbb6d;
  color: #fbfbfe;
}
.table-data-box span.granted {
  background: #00a410;
  color: #fbfbfe;
}

.add-row-btn-container {
  display: flex;
  width: 17%;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}
.add-row-btn {
  background-color: #00b900;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}
/* .product-table-auto-complete .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  height: 0px;
  width: 200px;
} */
.product-table-auto-complete
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: 0px;
  width: 5px;
  display: flex;
  min-width: 50px;
}
.product-table-auto-complete .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.add-line-autocomplete .MuiAutocomplete-clearIndicator {
  display: none;
}

.new-global-single-input {
 
  display: flex;
  flex-direction: column;
}
.new-global-single-input p {
  margin: 0 0 6px 0;
  font-size: 0.8rem;
  font-weight: 600;
}
.new-global-single-input textarea {
  width: 100%;
  border: 3px solid #bbbaba;
}
.new-global-single-input input:focus,
.new-global-single-input textarea {
  outline: none;
}
.new-global-single-input textarea::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1;
}
.new-global-single-input textarea::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.file-picker-new {
  width: 200px;
  height: 40px;
  border: 2.5px dashed #a7a2a2;
  margin: 30px 0 0 22px;
  border-radius: 4px;
}
.file-picker-new input {
  display: none;
}
.file-picker-new label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}
.file-picker-new label img {
  width: 9%;
}
.uploaded-image-list {
  /* margin:1% 0 1% 4%; ; */
  position: relative;
  width: 60px;
  height: 60px;
  border: 1px solid #c7c4c4;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploaded-image-list img {
  width: 50px;
  height: 50px;
  object-fit: fill;
}
.image-remove-icon-new {
  position: absolute;
  bottom: 77%;
  left: 77%;
  z-index: 2;
}
.single-log-container {
  max-width: 100%;
  /* height: 200px; */
  overflow-x: auto;
  padding: 3%;
  display: flex;
  scrollbar-width: thin;
}
.single-log-container::-webkit-scrollbar {
  height: 10px;
  background-color: transparent;
  width: 20px;
}
.single-log-container::-webkit-scrollbar-track {
  width: 20px;
  border-radius: 8px;
}
.single-log-container::-webkit-scrollbar-thumb {
  background: #cecdcd;
  border-radius: 8px;
  height: 20px;
  width: 8px;
  z-index: 2;
}
.single-log-container-scroll-line {
  border-top: 1px solid #000;
  padding: 1%;
  margin-top: -5px;
}

.single-log-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.single-log-item-line-image {
  display: flex;
  align-items: center;
}
.single-log-item-line {
  width: 100px;
  border-bottom: 1px solid #c1ccfa;
  height: 0px;
}
.single-log-item-image {
  width: 28px;
  height: 28px;
  background-color: #acbbfc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-log-item-image img {
  width: 15px;
  height: 15px;
}
.single-log-item .heading {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 2% 0 0 0;
}
.single-log-item .name {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
.single-log-item .date-time {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
.new-log-container {
  position: relative;
  margin: auto 0 0 0;
}
.pointer {
  position: absolute;
  top: -10px;
  left: 4%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
}
.pointer-up {
  position: absolute;
  bottom: -8px;
  left: 4%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
}

.global-product-table .MuiInputBase-root {
  padding: 0 !important;

  /* flex-wrap: nowrap; */
}
/* .global-product-table .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0;

  
} */
.actions-select-dropdown {
  width: 16%;
  max-width: 115px;
}
.actions-select-dropdown .MuiSelect-select p {
  margin: 0;
}
.actions-select-dropdown .MuiSvgIcon-root {
  color: #fff;
}
.actions-select-dropdown .MuiOutlinedInput-notchedOutline {
  border: none;
}
.actions-select-dropdown .MuiSelect-select {
  padding: 5px 10px !important;
  display: flex;
  gap: 6px;
}
.actions-select-dropdown-dialog {
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 3% 5%;
}
.actions-select-dropdown-dialog button {
  width: 70px;
  height: 25px;
}

.auto-shift-outlet-input .MuiOutlinedInput-root {
  height: auto !important;
}
.po-debit-button {
  display: flex;
  height: 28px;
  width: 44%;
  justify-content: space-around;
  align-items: center;
  /* padding: 10px; */
  background-color: #d7bff9;
  border-radius: 3px;
  margin-top: 12px;
  cursor: pointer;
}
.po-debit-button p {
  margin: 0;
  font-size: 8px;
}
.po-btn-container {
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  margin-right: 61px;
}

.export-actions-ctn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
  max-width: 220px;
  background-color: #eeeeee;
  padding: 6px 0;
}
.export-actions-section {
  display: flex;
  gap: 6px;
  align-items: center;
}
.export-actions-section p {
  margin: 0;
  cursor: pointer;
}
.export-actions-section div {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
}
.export-actions-section div img {
  height: 20px;
}
.export-actions-section div.import {
  border: 1px solid #ec8712;
}
.export-actions-section div.export {
  border: 1px solid #00c9a8;
}

/* report table design */
.global-report-table-container{
  max-height: 65vh;
  overflow-y: auto;
}
.global-report-table-container table {
  width: 100%;
  background-color: #fff;
  /* border-collapse: collapse; */
}
.global-report-table-container table tfoot {
  position: sticky;
  bottom: 0;
  background-color: #1d1da7;
  height: 40px;
  z-index: 2;
  color: #fff;
}
.global-report-table-container table tfoot tr th {
  height: 100%;
  text-align: end;
  font-size: 0.8rem;
  padding-right: 10px;
}
.global-report-table-container table thead {
  position: sticky;
  top: -6px;
  background-color: #1d1da7;
  height: 32px;
  z-index: 2;
  color: #fff;
}
.global-report-table-container table thead tr th {
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}
.global-report-table-container table tbody {
  min-height: 36px;
}
.global-report-table-container table tbody tr td {
  text-align: center;
  height: 32px;
  font-size: 0.8rem;
  background-color: #c3d4f7;
}

.global-table-row-tab {
  display: flex;
  justify-content: space-between;
  padding-inline: 29px;
  background-color: #e3e8f5;
  margin: 7px 14px;
  border-radius: 9px;
  color: #06066f;
  font-weight: 500;
  font-size: 17px;
  height: 47px;
  align-items: center;
}
.sharp-arrow {
  position: relative;
  display: inline-block;
  width: 166px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height as needed */
  background-color: #c3d4f7; /* Change the color as needed */
  cursor: pointer;
}

.sharp-arrow::before {
  content: "";
  position: absolute;
  top: 0;
  right: -15px; /* Adjust the distance between the arrow and cone */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 15px; /* Adjust the size of the cone */
  border-color: transparent transparent transparent #c3d4f7; /* Match the arrow color */
}

.sharp-arrow p {
  font-size: smaller;
  margin: 2px;
  padding-left: 10px;
}
.filter-container-values {
  width: 75%;
  cursor: pointer;
}

.filter-container-values div {
  border: 1px solid;
  border-radius: 50px;
  border-width: 2px;
  margin-top: 6px;
  padding: 0px 4px;
  height: 26px;
  overflow: hidden;
  white-space: nowrap;
}

.filter-container-values div p {
  margin: 0;
  font-size: 0.8rem;
}

.bi-funnel {
  font-size: 1.2rem;
  margin-right: 5px;
  margin-top: 10px;
}

.search-head {
  margin-bottom: 5px;
}
.filter-ctn-dropdown {
  position: absolute;
  width: 185px;
  left: -27%;
  background-color: #fff;
  top: 79%;
  box-shadow: -5px 2px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.filter-ctn-dropdown2 {
  position: absolute;
  width: 185px;
  background-color: #fff;
  top: 10%;
  box-shadow: -5px 2px 5px 0px rgba(0, 0, 0, 0.2);
  margin-left: 187px;
  padding-left: 15px;
}
.filter-ctn-dropdowndate {
  position: absolute;
  width: 140px;
  background-color: #fff;
  top: 10%;
  box-shadow: -5px 2px 5px 0px rgba(0, 0, 0, 0.2);
  margin-left: 187px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.filter-ctn-dropdowndate input {
  border: none;
  height: 31px;
  font-size: 0.8rem;
  padding: 0 4%;
  width: 100%;
}
.filter-ctn-dropdowndate button {
  width: 100px;
  margin-left: 15px;
  height: 26px;
  background-color: #02157d;
  color: white;
  cursor: pointer;
}
.filter-dropdown-menu p {
  margin: 7px 0;
}
.option-check {
  display: flex;
  align-items: center;
}
.option-check input {
  width: 10% !important;
  color: #06066f;
}
.option-check label {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 10px;
}
.selected {
  background-color: #dee3f8;
  color: #000000;
}
.filter-dropdown-menu {
  cursor: pointer;
}
.filter-ctn-dropdowndate input {
  width: 78% !important;
  cursor: pointer;
}
.filter-ctn-dropdowndate {
  display: flex;
  flex-direction: column;
}

.check-box-table th input[type="checkbox"],
.check-box-table td input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #000000;
  border-radius: 3px;
}

.check-box-table th input[type="checkbox"]:checked,
.check-box-table td input[type="checkbox"]:checked {
  background-color: #ffffff;
  color: #000000;
}
.check-box-table th input[type="checkbox"],
.check-box-table td input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 3px;
  position: relative;
}

.check-box-table th input[type="checkbox"]:checked::after,
.check-box-table td input[type="checkbox"]:checked::after {
  content: "\2713"; /* Unicode checkmark character */
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .pagination-container{
  display: inline-block;
}
.pagination-container a {
  color: black;
  float: left;
  padding: 8px 7px;
  text-decoration: none;
}
.pagination-container a.actives {
  color: rgb(18, 0, 179);
}
.pagArrrows{
  float: left;
  padding: 8px 10px;
  cursor: pointer; 
}
.currentPage{
  float: left;
  padding: 0px 9px;
  border: 1px solid;
  border-radius: 30%;
}
.pagnation-p{
  float: left;
  padding: 1px 10px;
} */
.custom-paginations-ctn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 56%;
  place-content: end;
}

.custom-paginations-ctn span {
  margin-right: 10px;
}

.custom-paginations-ctn a {
  color: black;
  text-decoration: none;
  padding: 0px 7px;
  /* margin-right: 5px; */
  cursor: pointer;
  /* border: 1px solid #ccc;
  border-radius: 3px; */
}

.custom-paginations-ctn a.actives {
  text-align: center;
  background-color: rgb(5, 0, 164);
  color: rgb(255, 255, 255);
  border-radius: 50%;
}

.custom-paginations-ctn .current-page {
  /* margin: 0 10px; */
  font-weight: bold;
  border: 1px solid;
  border-radius: 10px;
  padding: 3px 0px;
  margin-left: 2px;
  width: 39px;
  text-align: center;
}

.custom-paginations-ctn .pagination-info {
  margin-left: 10px;
}
.custom-paginations-ctn span svg {
  vertical-align: middle;
  cursor: pointer;
}
.custom-paginations-ctn span {
  margin-right: 5px;
}

.global-div-table-total{
  background-color: #dee3f8;
  margin: 25px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.global-div-table-total p{
  margin: 4px;
    font-size: small;
    font-weight: 700;
}

.category-filter-parent{
  width: 563px;
}

.filter-apply-btn {
  border: 1px solid #154b74;
  color: #154b74;
  font-weight: 600;
  font-size: 0.95rem;
  background-color: transparent;
  width: 36%;
  height: 28px;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 2%;
  border-radius: 4px;
}