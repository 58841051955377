.PhysicalStockVerification .voucher-add {
  display: flex;
  align-self: flex-end;
  gap: 3%;
  width: 26%;
  justify-content: flex-start;
}

.PhysicalStockVerification-input-box-container {
  padding: 2em;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.PhysicalStockVerification-input-box-container > div {
  display: flex;
  align-items: center;
}

.manufacturing-voucher-dashboard {
  padding: 2em;
}

.manufacturing-voucher-dashboard > div {
  display: flex;
  margin-block-end: 2em;
}

.manufacturing-voucher-dashboard > :nth-child(1) {
  margin-left: -1%;
}

.manufacturing-voucher-dashboard
  .PhysicalStockVerification-input-box-container {
  margin-top: 2em;
  width: 100%;
}

.manufacturing-journal .PhysicalStockVerification-input-box-container {
  margin-top: 2em;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
}

.set-profit-input-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.set-profit-input-box > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5%;
}
.set-profit-input-box > div input {
  height: 2em;
  border: none;
  font-size: 20px;
  font-weight: 600;
  background-color: white !important;
  width: 8%;
}
.set-profit-input-box > div .profit-input {
  border: 1px solid black;
}
/* .PhysicalStockVerification .purchase-return-voucher .gst-input-field > div h5,
h4 {
  width: 10%;
} */

