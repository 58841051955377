.recurring-posting-list .input-check-box{
    display: flex ;
    justify-content: flex-end;
    align-items: center;
    margin: 2% 0;
}
.recurring-posting-list .input-check-box >div{
    display: inherit;
}
#recurring-posting-list .accounting-voucher-menu-sections{
    padding: 0;
}
.recurring-post .accounting-voucher-menu-sections >div{
    padding: 5px 0;
    margin-block-start: 0em;
    width: 100%;
}
.recurring-post .accounting-voucher-menu-sections >div .global-table-container{
    margin: 0;
}
.recurring-posting-list .input-check-box h5 {
    margin: 0;
}

#recurring-posting-list .button-container button{
    border: none;
}
#recurring-posting-list .button-container :nth-child(2) .stop-execute{
    background-color: red ;
    color: white;
}
#recurring-posting-list .button-container :nth-child(2) .cancel{
    background-color: transparent ;
    color: black;
    border: 1px solid black;
}
#recurring-posting-list .button-container :nth-child(2) .send{
    background-color: black ;
    color: white;
    
}
#recurring-posting-list .button-container :nth-child(1) .edit-btn{
 background-color: white;
}
#recurring-posting-list .button-container :nth-child(1) .print{
 color: red ;
}
#recurring-posting-list .button-container > div :nth-child(2) {
    background-color: transparent;
}
#recurring-posting-list .button-container > div :nth-child(1) {
    border: none;
    background-color: transparent;
    
}
#recurring-posting-list .button-container > :nth-child(1) button{
 width: 35%;
}
#recurring-posting-list .button-container > :nth-child(1)  .printer-icon{
 color: red;
}

#recurring-posting-list .css-ahj2mt-MuiTypography-root {
    font-weight: 500;
}