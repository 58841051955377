.supplier-ledger-container{
    background-color: #fff;
    padding:1% 3%;
    display: flex;
    flex-direction: column;
    margin: 1% 0;

}
.supplier-ledger-container h3{
    margin: 0;
}
.suppler-ledger-input-container{
    display: flex;
    justify-content: space-between;
}

.suppler-ledger-input-container1{
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
}
.supplier-ledger{
    width: 27% !important;
    margin: 1% 0 !important;
}
.supplier-ledger-button{
    margin:3% auto;
}
.supplier-ledger-second-container{
    background-color: #fff;
    padding:1% 2%;
    margin: 1% 0;
}
.supplier-ledger-second-top-container{
    padding:1%;
    background-color:#f4efff;
    display: flex;
    justify-content: space-between;
    margin: 0 1%;
}
 .supplier-ledger-second-top-left-container{
    width: 50%;
}
.supplier-ledger-second-top-right-container{
    width:50%;
} 
.supplier-ledger-icon-container{
   display: flex;
   justify-content: flex-end;
}
.supplier-ledger-second-top-right-container P{
    display: flex;
   justify-content: flex-end;
   margin:2% 2% 1% 0;
   font-size:1rem;
   font-weight: 500;
}
.supplier-ledger-name-container{
    display: flex;
    margin: 2% 0;
    width: 100%;
}
.supplier-ledger-name{
    margin: 0;
    width:36%;
    padding: 0 2%;
    font-size: 1rem;
    font-weight: 500;
}
.supplier-ledger-content{
    margin: 0;
    width:61%;
    padding: 0 1%;
    font-size: 1rem;
    font-weight: 500;
}