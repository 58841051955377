.work-order-admin-top-container {
  background-color: #fff;
  padding: 2%;
  border-radius: 4px;
}
.work-order-admin-top-input-container {
  display: flex;
}
.work-order-admin-top-input-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 14.5%;
  margin: 1%;
  position: relative;
}
.work-order-admin-top-input-btn input {
  background-color: #eee;
  border: none;
  height: 40px;
  border-radius: 4px;
  width: 100%;
  padding: 0 4%;
  font-size: 1rem;
}
.work-order-admin-top-input-btn input::placeholder {
  font-size: 0.8rem;
}
.rack-input-container{
  flex-direction: column;
  position: relative;
}
.rack-input-container p{
  margin: 0;
  font-size: 0.7rem;
  position: absolute;
  bottom: -24px;
  right: 2px;
  color: #1739f9;
  font-weight: 600;
  padding: 1px 4px;
  border-radius: 4px;
  cursor: pointer;
}
.rack-input-container p:hover{
  background-color: #e6e9fc;
}
.admin-work-order-click-input {
  cursor: pointer;
  caret-color: transparent;
}
.admin-work-order-click-input::placeholder {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}

.work-order-admin-top-input-btn button {
  background-color: #000;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 4px;
  width: 20%;
  height: 38px;
  cursor: pointer;
}
.work-order-admin-top-input-btn button:hover {
  background-color: rgb(41, 40, 40);
}
.work-order-admin-radio-btn-container {
  display: flex;
  align-items: center;
  width: 20%;
  margin: 1%;
}
.work-order-admin-radio-btn-container .work-order-admin-radio-btn {
  width: 100% !important;
}
.work-order-admin-radio-btn-container .work-order-admin-radio-btn label {
  margin: 0 6% 0 0;
}
.work-order-admin-top-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 3% 0 0 0;
}
.work-order-admin-top-btn-container button {
  width: 12% !important;
}
.work-order-admin-bottom-first-container .work-order-admin-select {
  margin: 1% 1% 1% 0 !important;
  width: 17% !important;
}
.work-order-admin-bottom-first-container
  .work-order-admin-select
  div.MuiOutlinedInput-root {
  background-color: #fff !important;
}
.work-order-admin-bottom-cancel-submit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% auto;
}


/* Add Rack Dialog  */
.add-rack-dialog-parent {
  width: 30vw;
  padding: 2% 4%;
}

.add-rack-dialog-parent  .add-rack-form-container{
  display: flex;
  align-items: center;
}
.add-rack-form-container .rack-description-input{
  width: 60%;
  position: relative;
}
.add-rack-form-container .add-rack-btn{
  position: relative;
  top: 13px;
  margin-left: 15%;
  font-size: 1.6rem;
}
.add-rack-dialog-parent .rack-table-container{
  margin-top: 4%;
}
.add-rack-dialog-parent .rack-table-container table thead tr {
  border-bottom: 1px solid #eee;
}
.add-rack-dialog-parent .rack-table-container table tbody tr td{
  background-color: #fff;
}
.add-rack-dialog-parent .btn-parent
{
  display: flex;
  align-items: center;
}
.btn-parent .rack-close-btn{
  margin: 3% auto 1%;
  width: 28%;
}


.work-order-validation{
    color: red !important;
    font-size: 0.7rem !important;
    text-align: end;
    position: absolute !important;
    top: -40% !important;
    font-weight: 600!important;
    left: 0%!important;
}