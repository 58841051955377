.received-stock-container{
    background-color: #fff;
    padding: 1%;
}
.received-stock-container h3{
    margin: 0;
    padding: 0 2%;
}
.received-stock-dialog-container{
    padding: 3% 4%;
    width:55vw;
}
.received-stock-dialog-container h3{
    margin: 0;
}
.received-stock-dialog-top-container{
    display: flex;
    margin: 2% 0;
}
.received-stock-input{
    width: 30% !important;
}
.received-stock-dialog-table-container{
    background-color: #fff;
    margin: 6% 0;
}
.received-stock-table{
    padding: 0 !important;
    margin:2% 0 !important;
}
.received-stock-single-view-button-container{
    display: flex;
    background-color: #eee;
    justify-content: flex-end;
    padding: 2%;
    margin: 2% 0;
}
.received-stock-edit-table-container{
    display: flex;
    margin: 2% 0;
}
.edit-table-input{
    margin: 0 !important;
   
}
.edit-table-input p{
    text-align: center;
}
.edit-table-input input{
    border-radius: 0 !important;
    text-align: center !important;
}
.adj-qty input{
    border:1px solid #000;
    border-radius: 4px !important;
}
.adj-qty{
    width: 15% !important;
}

.stock-adj-remarks{
    text-align: left !important;
    padding-left: 2% !important;
}