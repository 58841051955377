.add-tailor-pop-up-container {
  width: 30vw;
  padding: 6%;
}
.add-tailor-pop-up-container h3 {
  margin: 0 0 4% 1%;
}
.add-tailor-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4% 0 0 0;
}
.add-tailor-popup-btn {
  width: 30% !important;
}
