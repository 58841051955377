/* style={{backgroundImage:{uploadImage}}} */

.custom-image-parent-container{
    display: flex;
    width: 80px;
    height: 80px;
}
.file-picker-container{
    width: 80px;
    height: 80px;
    /* flex: 50; */
    /* background-color: #3355ff; */
}
.file-picker-container input{
    display: none;
}
.file-picker-container label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-picker-container input:disabled + label{
    cursor: not-allowed
}
.file-picker-container label img{
    width:100%;
    height: 100%;
    /* margin-left: 12px; */
    /* padding: 24%; */
}
.image-preview-container{
/* flex: 50; */
width: 100%;
height: 100%;
position: relative;
}
.image-preview-container img{
    width:80px;
    height: 80px;
    margin-left: 12%;
    object-fit: fill;
}

.image-preview-container .closeIcon-image{
    position: absolute;
    right: -22px;
    top: -10px;
    background-color: #fff;
}