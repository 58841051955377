.churidhar-cutting-slip-input-container{
    width: 66% !important;
    display: flex;
    flex-wrap: wrap;
}
.large-input-churidhar-cutting-slip{
    width: 30% !important;
    background-color: #fff !important;
    margin: 5% 0 1% 3% !important;
    justify-content: space-between; 
}