.catelog-header-container{
    background-color: #fff;
    margin: 1%;
    padding: 1% 2%;
    display: flex;

}
.catelog-header-container p{
    margin: 0 8px;
    font-size: 0.9rem;
    font-weight: 600;
}
.catelog-heading-button{
    display: flex;
    justify-content: space-between;
    margin: 1%;
    align-items: center;
}
.catelog-heading-button p{
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
.catelog-buttom-container{
    display: flex;
    width: 40%;
    justify-content:end;
    height: 44px;

}
.catelog-buttom-container .MuiOutlinedInput-root{
    height: 40px;
}
.create-category-container{
    width: 40vw;
   
}
.create-category-icon-container{
    padding: 1%;
}
.save-iocn{
    color: blue;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 2%;
    cursor: pointer;
}
.close-icon{
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 2%;
}
.create-category-name{
    margin: 1% 0;
    font-size: 0.8rem;
    font-weight: 600;
}
.create-category-input-field{
    display: flex;
    /* justify-content: space-between; */
    margin: 4% 0;
    flex-wrap: wrap;
}
.new-global-single-input .MuiFormLabel-root.Mui-focused {
    color: #000;
    font-size: 1rem;
    
  }
  .new-global-single-input
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #bebebe !important;
  
}
.new-global-single-input div.MuiOutlinedInput-root {
    width: 100%;
    border: none;
    height: 40px;
  }
  .new-global-single-input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
  }
  .new-global-single-input {
    width: 22%;
    margin: 8px;
   
  }
  .new-global-single-input .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
     height: 0px;
  }
  .catelog-category-list{
    display: flex;
    flex-wrap: wrap;

  }
.catelog-category-list-single{
    width: 125px;
    margin: 1%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
}
.catelog-category-list-single img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #000;
}
.catelog-category-list-single p{
    margin: 1% ;
    font-size: 0.9rem;
    font-weight: 600;
}

.catalog-button{
    width: 25%;
    height: 40px;
    margin: 0 1%;
    cursor: pointer;
    color: #fff;
    font-size: 0.9rem;
    border: none;
}
.export{
    background-color: #f02100;
}
.import{
    background-color:  #f06800 ;
}
.create{
    background-color: blue;
}
.catalog-table-container{
    max-height: 65vh;
    overflow-y: auto;
    margin: 1%;
}
.catalog-table-container table{
  width: 100%;
  border-collapse: collapse;
}
.catalog-table-container table thead tr th{
    position: sticky;
    bottom: 0;
    background-color: #fff;
    height: 50px;
    z-index: 2;
    color: #000;
  }
 .catalog-table-container table tbody  {
    min-height: 50px;
  }
  .catalog-table-container table tbody tr td {
   
 
    height: 100%;
    text-align: center;
    min-height: 40px;
    height: 40px;
    font-size: 0.8rem;
  }
.add-model{
    font-size: 0.8rem;
    font-weight: 600;
    color: blue;
    text-align: end;
    margin: 8px ;
    cursor: pointer;

}
.catelog-search-container{
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    margin: 1%;
    padding: 2%;
    align-items: center;
}

.add-to-product-pop-up{
    background-color: #fff;
    z-index: 2;
}

/* Popup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1215;
  }
  
  .popup-catalog-addtoproduct {
    background-color: white;
    padding: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 0 0 0 auto;
    height: 100vh;
    z-index: 1215;
    width: 550px 
  }
  .popup-catalog-addtoproduct-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .popup-catalog-addtoproduct-top p{
    font-size: 1rem;
    font-weight: 600;
  }
  .popup-catalog-addtoproduct-top button{
    width: 18%;
    height: 30px;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .popup-catalog-addtoproduct-second{
    display: flex;
    flex-direction: column;
  }
  .popup-catalog-addtoproduct-second-single{
    display: flex;
    background-color: #eee;
    padding: 4px;
    justify-content: space-between;
    margin: 1%;
  }
  .popup-catalog-addtoproduct-second-single-img{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #bebebe; */
  }
  .popup-catalog-addtoproduct-second-single-content{
    width: 45%;
    display: flex;
    flex-wrap: wrap;
  }
  .popup-add-to-cart-name{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 4px;
    width: 46%;
  }
  .popup-add-to-cart-name p{
    font-size: 0.7rem;
    font-weight: 600;
    color: #949393;
    margin: 0;
  }
  .popup-catalog-addtoproduct-second-single-drpdown{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .add-to-cart-drpdown div.MuiOutlinedInput-root{
    height: 30px !important;
  }
  .vertical-hr {
    border-left: 1px dotted black; /* Adjust the style of the vertical line */
    /* height: 100px; Adjust the height of the vertical line */
    margin: 0 4px; /* Adjust margin if needed */
  }
  
 