.job-completion-top-container {
  background-color: #fff;
  padding:1%;
}
.job-completion-top-container h3 {
  margin: 1%;
}
.job-completion-top-input-container {
  display: flex;
}
.job-completion-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:1% 0;
}
.job-completion-btn-container button {
  width: 12% !important;
}
.job-completion-table-container {
  background-color: #fff;
}
.job-completion-table-top-container{
  display: flex;
  justify-content: space-between;
  padding:1% 1% 0 1%;
}
.job-completion-table-top-left-container{
  width: 30%;
  margin: 1% 0 0 1%;
}
.job-completion-table-top-left-single-container{
  display: flex;
  /* margin: 3%; */
}
.job-completion-table-top-left-single-container p{
  margin: 0;
  font-size:1rem;
  font-weight: 500;
}
.job-completion-table-top-right-container{
  display: flex;
  justify-content: space-between;
  width: 45%;
}
.job-completion-table-top-right-single-container{
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
}
.job-completion-table-top-right-single-container p{
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
}
.job-completion-table-top-right-single-container div.MuiOutlinedInput-root{
  width: 98%;
  height: 40px;
  margin-left: 1%;
}
.job-completion-table {
  /* margin: 1% 0 !important;*/
  padding: 8px !important;
} 
.job-completion-table table thead tr th{
  font-size: 0.7rem !important;
}
.job-completion-table table tbody tr td{
  font-size: 0.7rem;
}
.job-completion-checkbox {
  /* accent-color: #fff; */
}
.job-completion-table-select {
  width: 98%;
}
/* .job-completion-table-select:focus-within{
  width: 30%;
} */
.job-completion-table-select div.MuiOutlinedInput-root {
  width: 80%;
  height: 40px;
  margin: 8px 0;
}
.job-completion-table-used-qty {
  width: 7%;
}
.job-completion-table-used-qty input {
  width: 90%;
  height: 37px;
  background-color: transparent;
  border: 1px solid rgb(179, 177, 177);
  text-align: center;
  border-radius: 4px;
}
.job-completion-final-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2% 0; */
  padding: 1% 0;
}
.job-completion-final-btn-container button {
  width: 12% !important;
}
.job-completion-order-list-table{
  max-height: 30vh !important;
}

.job-completion-dialog-new{
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
}


/* .job-completeion-table-auto-complete .MuiAutocomplete-root{
  margin-left: 0%;
} */
.job-completeion-table-auto-complete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  height: 0px;
  width: 5px;
  display: flex;
  min-width: 50px;
 
} 

.job-completeion-table-auto-complete .MuiAutocomplete-root{
  margin-left: 0%;
}

.job-completeion-cutter-auto-complete{
  display: flex;
  width: 47%;
  align-items: center;
}
.job-completeion-cutter-auto-complete p{
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 8px 0 0;
}
.job-completeion-cutter-auto-complete .MuiAutocomplete-root{
  background-color: #eee;
}
.job-completeion-cutter-auto-complete div.MuiOutlinedInput-root {
  width: 100%;
  border: none;
  height: 45px;
}
.job-completeion-cutter-auto-complete .MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 0;
}

.job-completeion-cutter-new-auto-complete{
  display: flex;
  width: 47%;
  align-items: center;
}
.job-completeion-cutter-new-auto-complete p{
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 8px 0 0;
}
.job-completeion-cutter-new-auto-complete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  height: 0px;
  width: 5px;
  display: flex;
  min-width: 50px;
}
.job-completeion-cutter-new-auto-complete .MuiAutocomplete-root{
  background-color: #eee;
}
.job-completeion-cutter-new-auto-complete .MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 4px;
  min-height: 45px;
}

