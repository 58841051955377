
.login-forgot-pass-ctn{
    background-color: #fbfbfb;
    padding: 2% 0;
    height: auto !important;
    align-self: baseline;
    margin-top: 20% !important;
}
.login-forgot-pass-body-section{
    display: flex;
    flex-direction: column;
    margin: 3% 0;
    padding: 3% 0;
    align-items: center;
}
.login-forgot-pass-body-section p{
    margin: 0;
    color: #7d7d7e;
}
.login-forgot-pass-body-section h3{
    margin: 2%;
    font-weight: 700;
}
.loginforgot-pass-input{
    width: 75% !important;
    margin: 7% 0;
}
.loginforgot-pass-input p{
    color: black;
    margin: 2% 0;
    font-weight: 600;
}
.login-forgot-pass-button-ctn{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}
.loginforgot-pass-btn{
    background-color: black;
    font-size: 0.8rem !important;
    width: 25% !important;
    margin-bottom: 5%;
}
.loginforgot-pass-btn:hover{
    background-color: #29292b;
    color: #eae8e8;
}
.login-forgot-pass-otp-ctn{
    flex-direction: row !important;
    width: 80%!important;
    justify-content: space-evenly;
    margin: 7% 0;
}
.login-forgot-pass-otp-ctn input{
    width: 10%;
    font-size: 1.5rem;
    text-align: center;
}
.login-forgot-pass-otp-button-ctn{
    display: flex;
    align-self: center;
    margin-bottom: 5%;
    width: 60%;
    justify-content: center;
}
.login-forgot-pass-otp-button-ctn button{
    font-size: 0.8rem !important;
    width: 25% !important;
}
.login-forgot-pass-otp-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 5%;
}
.login-forgot-pass-otp-footer p{
    font-size: 0.7rem;
    color: #7d7d7e;
    font-weight: 600;
    cursor: pointer;
}
.login-double-input{
    flex-direction: row !important;
    width: 100% !important;
}

/* css for updated password */
.login-update-pass-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5% 0;
}
.login-update-pass-input-ctn{
    width: 90%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin: 1% 0;
}
.login-update-pass-input-ctn p{
    font-size: 0.9rem;
    font-weight: 600;
}
.login-update-pass-input-ctn input{
    width: 55%;
    background-color: transparent;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    height: 35px;
    padding-left: 15px;
}
.login-update-pass-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}
.login-update-pass-button button{
    width: 40% !important;
}
.login-update-pass-footer{
    width: 80%;
    align-self:center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}
.login-update-pass-footer p{
    font-size: 0.6rem;
    font-weight: 700;
}