.dashboard-employee-leave-details-container {
  background-color: #fff;
  padding: 2%;
  border-radius: 4px;
}
.dashboard-employee-leave-details-top-container {
  display: flex;
  justify-content: space-between;
  margin: 1% 0 2% 0;
}
.dashboard-employee-leave-details-top-btn-grp-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-employee-leave-details-top-btn-grp-container button {
  width: 30% !important;
  font-size: 0.8rem !important;
}
.dashboard-employee-leave-details-search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 28%;
  /* margin: 0 1%; */
}
.dashboard-employee-leave-details-search-container input {
  background-color: #eee;
  border: none;
  height: 40px;
  padding: 0 2%;
  width: 68%;
  font-size: 0.9rem;
}
.dashboard-employee-leave-details-search-container input::placeholder {
  font-size: 0.8rem;
}
.dashboard-employee-leave-details-search-container button {
  background-color: #000;
  color: #fff;
  height: 40px;
  width: 30%;
  cursor: pointer;
  border: none;
}
.dashboard-leave-details-table-container {
  padding: 0 !important;
  margin: 1% 0 !important;
}
.dashboard-employee-table-cell-approved {
  background-color: #00c234;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 8%;
  border-radius: 2px;
}
/* dialog */
.dashboard-employee-leave-details-dialog-container {
  width: 40vw;
  padding: 2% 8%;
}
.dashboard-employee-leave-details-dialog-top-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.dashboard-employee-leave-details-dialog-top-container p {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-employee-leave-details-dialog-top-container p.alert-leave-limit {
  position: absolute;
  top: 100%;
  right: 0%;
  font-size: 0.7rem;
  color: red;
}
.dashboard-employee-leave-details-dialog-top-container span {
  color: red;
}
.dashboard-emp-apply-leave-input {
  width: 98% !important;
  margin: 5% 0 !important;
}
.dashboard-employee-leave-details-dialog-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-employee-leave-details-dialog-button-container button {
  width: 20% !important;
}
.status-text-stock-out-approve{
    background-color:#b8deb1;
    color: #00bb0f;
    width: fit-content;
    margin: 0 auto;
    border-radius:16px;
    padding:1% 10%;
    font-size: 0.8rem;
    font-weight: 500;
}
.show-leave-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%!important;
}