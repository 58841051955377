.billing-main-container {
  display: flex;
  position: relative;
}
.billing-parent-container {
  position: relative;
}
.billing-parent-container .billing-bottom-strip {
  position: sticky;
  bottom: 16px;
  left: 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px rgb(196, 196, 196);
  padding: 4px;
  height: 42px;
}
.billing-bottom-strip .cart-strip-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  /* margin: 4px 0; */
}
.cart-strip-child img {
  width: 22px;
}
.cart-strip-child div.cart-strip-head {
  font-size: 0.75rem;
}
.cart-strip-child div.cart-strip-value {
  font-size: 0.9rem;
  font-weight: 600;
}
.cart-strip-child div.price {
  color: #f13c3c;
}
.billing-left-container {
  display: flex;
  flex: 65%;
  flex-direction: column;

  /* width: 65%;
  height: 100vh;
  margin-right: 35%; */
  /* overflow-y: scroll; */
}
.billing-right-container {
  display: flex;
  flex: 35%;
  flex-direction: column;

  /* width: 28%;
  height: 100vh;
  position: fixed;
  top: 10%;
  right: 2%; */
}
.billing-left-first-container {
  background-color: #fff;
  padding: 1%;
  margin: 0 1% 1% 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.billing-left-first-container h4 {
  margin: 1% 0;
}
.billing-order-type-radio-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20%;
}
.billing-order-type-radio-btn input {
  margin: 0 8% 0 0 !important;
  accent-color: #07a707;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.billing-order-type-radio-btn label {
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}
.billing-left-second-container {
  display: flex;
  justify-content: space-between;
}
.billing-left-second-dorpdown-container {
  display: flex;
  width: 55%;
}
.billing-category-dropdown {
  width: 42%;
  margin: 1% 3% 1% 0 !important;
}
.billing-category-dropdown div.MuiOutlinedInput-root {
  background-color: #fff !important;
  font-size: 0.9rem;
}
.billing-category-dropdown input {
  background-color: #fff !important;
}
/* .billing-category-dropdown div svg{
    background-color: #0011f8;
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  cursor: pointer;
} */
.billing-left-second-search-container {
  position: relative;
  width: 40%;
}
.billing-left-second-search-container input {
  border: none;
  height: 40px;
  width: 95%;
  margin: 2%;
  border-radius: 4px;
  padding: 0 4%;
  font-size: 0.9rem;
}
.billing-left-second-search-container input::placeholder {
  color: rgb(204, 200, 200);
  font-size: 0.8rem;
}
.billing-left-second-search-container .billing-search-icon {
  position: absolute;
  right: 6%;
  top: 27%;
  color: #d1d1d1;
}
.billing-left-third-container {
  background-color: #fff;
  margin: 1% 1% 1% 0;
  padding: 1%;
}
.billing-left-third-top-container {
  background-color: #f6f5f5;
  padding: 1% 1% 2% 1%;
  margin: 0 0 1% 0;
}
.billing-left-third-top-container h4 {
  margin: 1%;
}
.billing-sub-category-radio-button {
  display: flex;
  flex-wrap: wrap;
}
.custom-radio-input {
  display: none;
}

.custom-radio-label {
  width: fit-content;
  padding: 4px 16px;
  height: 34px;
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 4px rgb(219, 219, 219);
}
.custom-radio-label p {
  font-size: 0.9rem;
  font-weight: 600;
}
input[type="radio"].custom-radio-input:checked + label.custom-radio-label {
  background-color: #ffb984;
}
.billing-product-list-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 46vh;
  overflow-y: scroll;
}
.billing-product-list-container::-webkit-scrollbar {
  width: 4px;
  background-color: #ffffff;
}
.billing-product-list-container::-webkit-scrollbar-thumb {
  background-color: rgb(43, 39, 39);
}
.billing-single-product-container {
  background-color: #fff;
  padding: 1%;
  width: 23%;
  border-radius: 4px;
  margin: 2% 1%;
  position: relative;
}
.billing-single-product-container .product-offer-tag {
  position: absolute;
  width: 25%;
  height: 25%;
  top: -6px;
  right: 12px;
}
.billing-single-product-container .product-offer-tag img.food-offer-tag {
  height: 80%;
  object-fit: fill;
}
.billing-single-product-container .product-offer-tag p.offer-value {
  position: relative;
  width: 75%;
  text-align: center;
  margin: 0 auto;
  bottom: 65%;
  color: #fff;
  font-size: 0.7rem;
}

.billing-single-product-container img {
  width: 100%;
  height: 10vh;
  object-fit: cover;
}
.billing-single-product-container p {
  margin: 2% 0;
  font-size: 0.8rem;
  font-weight: 600;
}
.billing-product-list-select-container {
  display: flex;
  justify-content: space-between;
}
.billing-product-list-select-container div.MuiOutlinedInput-root {
  height: 30px;
  width: 62%;
}
.billing-product-list-select-container
  div.MuiOutlinedInput-root
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
}
.billing-product-list-select-container button {
  background-color: #3ca620;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 32%;
  font-size: 1.3rem;
}
.billing-product-list-select-container button:hover {
  background-color: #39991f;
  cursor: pointer;
}
.billing-left-bottom-button-select-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
}
.billing-left-bottom-button-select-container .billing-select {
  width: 28%;
}
.billing-left-bottom-button-select-container
  .billing-select
  div.MuiOutlinedInput-root {
  background-color: #fff;
  font-size: 0.8rem;
}
.billing-left-bottom-button-select-right-container {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.billing-left-bottom-button-select-right-container .billing-select {
  width: 60%;
}
.billing-left-bottom-button-select-right-container button.billing-add-cus-btn {
  background-color: #26c4a9;
  border: none;
  border-radius: 4px;
  height: 37px;
  color: #fff;
  margin: 1%;
  padding: 3%;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
}
.billing-left-bottom-button-select-right-container
  button.billing-add-cus-btn:hover {
  background-color: rgb(50, 224, 195);
}
.billing-left-bottom-button-container {
  display: flex;
  padding: 2%;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.billing-left-bottom-button-container button {
  width: 28%;
  margin: 1%;
  height: 40px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.billing-view-order-btn {
  background-color: #0050d9;
}
.billing-view-order-btn:hover {
  background-color: rgb(7, 69, 175);
}
.billing-new-order-btn {
  background-color: #01bf34;
}
.billing-new-order-btn:hover {
  background-color: #02ac2f;
}
.billing-held-product-btn {
  background-color: #ffb300;
}
.billing-held-product-btn:hover {
  background-color: #e9a301;
}
.billing-wallet-btn {
  background-color: #d95000;
}
.billing-wallet-btn:hover {
  background-color: #c74b03;
}
.billing-add-tips-btn {
  background-color: #0b394a;
}
.billing-add-tips-btn:hover {
  background-color: rgb(3, 74, 100);
}

/* right container  */
.billing-right-top-container {
  background-color: #ffff;
  padding: 1%;
  display: flex;
  border-radius: 4px;
  /* height:4%; */
  align-items: center;
  padding: 6.5% 4%;
  margin: 0 0 1% 0;
  position: relative;
}
.billing-right-top-container p {
  /* margin: 1%; */
  font-size: 1rem;
  font-weight: 600;
}
.billing-right-second-container {
  background-color: #fff;
  padding: 1%;
  /* margin: 2% 0; */
  position: relative;
}
.billing-right-second-container .cart-section {
  position: relative;
  height: 25vh;
  max-height: 25vh;
  overflow-y: scroll;
  border-bottom: 2px solid #fff;
  background-color: #eef1f9
}
.billing-right-empty-cart-container{
  display: flex;
  justify-content: center;
  /* margin-top: 5%; */
  margin-top: 18%;
}
.billing-right-empty-cart-container img{
  width: 50%;
  height: 180px;
  position: relative;
}
.billing-right-empty-cart-container p{
  position: absolute;
  bottom: -10px;
  color: #00c635;
  font-size: 0.9rem;
  font-weight: 500;
}
.billing-right-second-container .cart-section::-webkit-scrollbar {
  background-color: rgb(153, 170, 184);
  width: 4px;
}
.billing-right-second-container .cart-section::-webkit-scrollbar-thumb {
  background-color: #0b394a;
}
.billing-right-second-container p.cart-total-no {
  position: absolute;
  right: 16px;
  top: -8px;
  margin: 0;
  z-index: 4;
  font-weight: 600;
}

.billing-right-cart-container {
  background-color: #eef1f9;
  padding: 0 1%;
  display: flex;
  align-items: center;
  margin: 1% 0;
}
.billing-cart-left {
  width: 24%;
  height: 4vh;
  display: flex;
  align-items: center;
  margin: 0 0 0 2%;
}
.billing-cart-left img {
  width: 60%;
  height: 100%;
  object-fit: cover;
}
.billing-cart-middle {
  width: 50%;
  margin: 0 0 0 3%;
}

.billing-cart-middle .cart-main-head {
  margin: 1% 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.billing-cart-middle .cart-sub-head {
  margin: 1% 0;
  font-size: 0.7rem;
  font-weight: 600;
  color: rgb(201, 199, 199);
}
.cart-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 4% 0 0 0;
}
.cart-button-container button {
  width: 20px;
  height: 20px;
  background-color: #0011f8;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}
.cart-button-container input {
  width: 24px;
  height: 24px;
  background-color: #0011f8;
  border: none;
  color: #fff;
  text-align: center;
  border-radius: 4px;
}
.cart-button-container input.billing-cart-unit-price-input {
  background-color: #fff;
  width: 42%;
  color: #000;
}
.cart-button-container p {
  margin: 0;
  font-size: 0.8rem;
}
.billing-cart-right {
  width: 26%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 77px;
  margin: 0 2% 0 3%;
  position: relative;
}
.billing-cart-right i {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.billing-cart-total-amt-container {
  border: 2px solid #fff;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2% 0 0 0;
}
.billing-cart-total-amt-container p {
  margin: 0;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
}
.billing-right-payment-container {
  background-color: #eef1f9;
  padding: 1%;
}
.billing-input {
  margin: 0.5% 0;
}
.billing-input input {
  background-color: #fff !important;
  direction: rtl;
  font-size: 1rem;
  height: 28px !important;
  font-weight: 600;
}

.billing-right-payment-container div.btn-inputs input {
  direction: ltr !important;
  text-align: left !important;
}

.paymentSection{
  /* border: 2px solid orange; */
}