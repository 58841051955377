.add-stock-adjustment-container{
    display: flex;
}
.add-stock-adjustment-left-main-container{
    width: 50%;
}
.add-stock-adjustment-left-container{
    background-color: #fff;
    padding: 2%;
    margin:0 1%;
   
   
}
.add-stock-adjustment-left-top-container{
    display: flex;
    flex-wrap: wrap;
    margin: 6% 0;
    padding: 0 2%;
}
.add-stock-adjustment-left-bottom-container{
    display: flex;
    flex-wrap: wrap;
    margin: 6% 0;
    padding: 0 2%;
}
.add-stock-adj-input{
    width:48% !important;
    position: relative;
}
.stock-size-container{
    display: flex !important;
    width: 48%;
    margin-left: 0.5%;
    margin-top: 0.5%;
}
.stock-adj-size-parent{
    width: 25%
}


.add-stock-adj-right{
    padding: 2% 2% 4% 2%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
}
.add-stock-adjustment-right-container{
    width: 50%;
}
.add-stock-adj-btn{
    width: 25% !important;
    margin: 2% 4% 2% 0;
}
.add-stock-adj-radio-btn{
    width: 72% !important;
    margin: 2% 0 4% 0
}
.add-stock-adj-table-container{
    background-color: #fff;
    margin:2% 0.5%;
    padding: 1%;
}
.add-stock-adj-table-container h3{
    padding: 0 2%;
}
.add-stock-adj-draft-btn{
    background-color: #ff9900 !important;
    margin: 1% 6% !important;
}
.add-stock-adj-draft-btn:disabled,.add-stock-adj-draft-btn:disabled:hover{
    background-color: #d4b689 !important;
}
.add-stock-adj-draft-btn:hover{
    background-color: #d17e02 !important;
    
}
.add-stock-adj-checkbox{
    display: flex;
    justify-content: flex-end;
}
.add-stock-adj-checkbox input[type='checkbox']{
    width: 16px;
    height: 12px;
    
}
.add-stock-adj-checkbox label{
  font-size: 0.7rem;
  font-weight: 600;
}
.same-location-text{
    color: #0502ff;
}
.negative-text{
    color: red;
}
.same-location-input{
    accent-color: #0502ff;
}
.negative-input{
    accent-color: red;
}