.rewards-and-points-main-container{
    background-color: #fff;
    margin: 2%;
}

.rewards-points-filter-container{
    box-shadow:5px 2px 6px 5px #eeee;
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
}

/* edit reward */
.edit-reward-and-points-container{
    padding: 2% 3%;
    width: 38vw;
}
.edit-reward-and-points-bottom-container{
    display: flex;
    flex-direction: column;
}
.edit-reward-input-container{
    display: flex;
    flex-wrap: wrap;
}
.edit-reward-input{
    width: 48% !important;
    margin: 2% 1% !important;
}
.edit-reward-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
}