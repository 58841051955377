.add-employee-menu-container {
  margin: 0 4% !important;
}

/* personal info css */
.personal-info-container {
  background-color: #fff;
  padding: 1% 4%;
  margin: 1% 0;
}
.personal-info-input-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.add-employee-input {
  position: relative;
  width: 37% !important;
  margin: 2% 5% 1% 1%;
}
.add-employee-input .MuiAutocomplete-inputRoot{

  height: auto !important;
}
.profile-info-account-login {
  width: 45%;
  height: 32px;
  border-radius: 4px;
  border: none;
  background-color: #ff4e00;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}
.profile-info-account-login:hover {
  background-color: #db4505;
}
.add-employee-input-button {
  width: 37% !important;
  margin: 2% 5% 1% 1%;
  justify-content: flex-end;
}
.add-employee-button-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4% 0;
}

/* profile info account login */
.profile-info-account-login-container {
  width: 35vw;
  padding: 2% 4%;
}
.profile-info-account-login-container h3 {
  margin: 1% 0;
}
.profile-info-account-login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8% 0;
}
.profile-info-account-login-content p {
  margin: 0;
}
.profile-info-account-login-input-content {
  width: 82%;
  margin: 16% 0;
}
.profile-info-account-login-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.profile-info-account-login-input div.MuiOutlinedInput-root {
  width: 20%;
  background-color: #eee;
  height: 50px;
  border: none !important;
}
.profile-info-account-login-input input {
  width: 100%;
  background-color: #eee;
  height: 50px;
  border: none;
  padding: 0 2%;
}
.profile-info-account-login-button-container {
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
  margin: 4% 0;
}
.profile-info-account-login-button-container button {
  width: 38%;
}
.acoount-login-password-confirm-password-container {
  margin: 3% 0;
  width: 82%;
}
.account-login-password-container {
  width: 100% !important;
  margin: 8% 0 !important;
}

/* company info */
.company-info-status-container {
  display: flex;
  width: 26%;
  justify-content: space-between;
}
.company-info-status-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
.company-info-status-switch-container p {
  font-size: 0.9rem;
  color: blue;
}

.company-info-status-switch-container
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: blue;
}


.status-qr-container{
  display: flex;
  align-items: center;
}
.qr-code-container{
  display: flex;
  align-items: center;
  margin-left: 4%;
  width: 69%;
}
.btn-qr{
  width: 26% !important;
}
.qr-image{
  width: 80px;
  height: 80px;
}
.qr-image img{
  width: 100%;
  height: 100%;
}
.bank-info-validation{
  margin-bottom: 0;
  color: red;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 5%;
}