.add-customer-heading-radio-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1%;
}
.add-customer-radio-btn-container{
    display: flex;
    width: 20%;
    justify-content: space-between;
}
.add-customer-radio-btn-single-container{
    width: 51%;
    display: flex;
    align-items: center;
}
.add-customer-radio-btn-single-container input{
    margin: 0;
    accent-color: #000;
    cursor: pointer;
}
.add-customer-radio-btn-single-container label{
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 12px;
    cursor: pointer;
}
.add-customer-input-container{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.add-customer-button-container{
    display: flex;
    justify-content: center;
    margin:2% 0;
}

/* all customer */

.all-customer-top-container{
    padding:1% 2% 0 2%;
}
.all-customer-top-container h3{
    margin: 0;
}
.all-customer-table-top-container{
    display: flex;
    justify-content: space-between;
}
.all-customer-table-top-container h4{
    margin-left:2%;
}