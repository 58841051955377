.offer-listed-container{
    background-color: #fff;
    width: 100%;
    padding: 2% 3%;
}
.offer-listed-container-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.offer-listed-view-container{
    width: 35vw;
    background-color: #fff;
    padding:2% 4%;
}
.view-dialog .global-single-input{
    width: 100%;
}
.offer-listed-view-container .global-single-input{
    width: 80%;
    margin: 5% 0%;
}
.view-dialog .global-single-input{
    width: 100%;
}
.offer-view-qmark-ctn{
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 87, 0);
    top: 52%;
    right: -9%;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
    border: 2px solid rgb(0, 87, 0);
    border-radius: 50%;
}
.offer-view-single-input{
    position: relative;
}
.offer-view-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.offerlist-table-image img{
    width: 50px;
    height: 60px;
    border-radius: 6px;
}