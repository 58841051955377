.expense-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.expense-top-container h4{
    margin:2%;
}
.expense-report-print-download-icon-container{
    display: flex;
    width: 22%;
}
.expense-report-print-download-icon-container .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    margin: 2% !important;
}
.expense-report-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.expense-top-main-container{
    justify-content: space-between;
}
