.commission-report-status-delivered{
  background-color: #3daf5f;
  width: 80%;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commission-report-status-pending{
  background-color: #fb932b;
  width: 80%;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commission-report-status-return{
    background-color: #2b69fb;
    width: 80%;
    height: 20px;
    border-radius: 4px;
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 10% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.commission-report-status-printing{
 
    background-color: #2ab5cc;
    width: 80%;
    height: 20px;
    border-radius: 4px;
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 10% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.commission-report-status-booking{
  background-color:#fd4818;
  width: 80%;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commission-report-status-del-return{
  background-color:#ff0000;
  width: 80%;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commission-report-status-order-return{
  background-color:#ff9822;
  width: 80%;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 10% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.total-commission-container{
  display: flex;
  align-items: center;
  background-color:rgb(235 227 247);
  color:rgb(109 32 221);
  font-size:0.9rem;
  font-weight: 600;
  margin: 1%;
  padding: 1%;
  height: 38px;
}
.total-commission-container p{
  margin: 0;
}

.commission-report-summary{
  position: absolute;
  top: 79%;
    right: 14%;
    display: flex;
    align-items: center;
}
.commission-report-summary input{
  cursor: pointer;
  accent-color: #04ab04;
 
}
.commission-report-summary input:hover{
  accent-color: #04ab04;
}
.commission-report-summary label{
  cursor: pointer;
  color: #3daf5f;
  font-size: 0.7rem;
  font-weight: 600;
}
.commission-report-summary-container{
  margin: 1% 6%;
}
.commission-report-summary-top-container{
  /* background-color: rgb(235 227 247); */
  display: flex;
  padding: 1% 2%;
  border-bottom: 1px solid #000;

}
.commission-report-summary-top-container h3{
  margin: 0;
}
.commission-report-summary-total-container{
  display: flex;
  justify-content: flex-end;
}
.commission-report-summary-total-container h4{
  width: 36%;
  text-align: center;
  margin: 1% 0;
}
.commission-report-summary-grand-total-container{
  display: flex;
  justify-content: flex-end;
  margin: 1% 6%;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}
.commission-report-summary-grand-total-container h4{
  width: 36%;
  text-align: center;
  margin: 1% 0;
}