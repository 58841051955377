.device-settings-container{
    display: flex;
}



.device-settings-container-left{
    /* flex: 20%; */
    width: 20%;
    /* border: 2px solid rgb(85, 0, 255); */
    margin-top: 1%;
    margin-left: 1%;

}

/* .device-settings-container-left1{
  border: 2px solid orange;
}  */

.device-settings-container-left-section{
    margin-right: 1%;
    background-color: #fff;
    border-radius: 5px;
    padding: 3% 0;
    height: 40vh;
}
.device-settings-container-right{
    /* flex: 55%; */
    /* border: 2px solid rgb(85, 0, 255); */
    margin-top: 3.8%;
    margin-left: 1%;
    width: 40%;
}
.device-settings-container-left-list{
    width: 100%;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 6% 8px 6% 6%;
    margin: 12px 0;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}
.device-settings-container-left-list:hover{
    background-color: #f2f3f9;
}
.device-settings-container-left-list p{
    font-size: 0.9rem;
    padding-left: 15px;
}
.device-settings-container-right-section{
    
    padding:20px;
    padding-bottom: 40px;
    background-color: #fff;
    margin: 0 1%;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 5px;

}
.device-settings-container-right-printer p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.device-settings-container-right-printer input{
    /* width: 98%; */
    /* height: 60px; */
    margin: 2% 0;
    background-color: #f6f6f6;
    border: none;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0 16px;
}

.device-settings-accordian-section{
    font-weight: 400;
    font-size:0.8rem;
    padding: 2%;
}
.device-settings-right-container-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.device-settings-right-container-button button{
    width: 20%;
}

.device-settings-container-right-attendance{
    padding: 40px 20px;
    background-color: #fff;
    margin: 0 1%;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 4px;
}
.device-settings-container-right-attendance p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.device-settings-container-right-attendance input{
    width: 98%;
    height: 40px;
    margin: 2% 0;
    background-color:#f6f6f6;
    border: none;
    font-size: 0.7rem;
    padding: 0 16px;
}
.device-settings-container-right-printer .global-single-input{
    width: 95%;
}

.device-settings-container-right-printer-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.device-settings-container-right-printer-top-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.device-settings-container-right-printer-top-right p{
    margin:0 6px;
}

.device-settings-container-right-printer-top-right input[type="radio"]{
  accent-color: #000;
  /* margin: 0 4%; */
  cursor: pointer;
}

.device-settings-container-left-top{
    margin-right: 1%;
    background-color: #fff;
    border-radius: 5px;
    padding: 1%;
    margin-bottom: 5%; 
}

/* .device-settings-autocomplete input::placeholder{
    
} */
.device-settings-autocomplete .MuiOutlinedInput-notchedOutline{
    border: none;
}
.settings-radio-group{
    display: flex;
    align-items: center;
}
.device-settings-radio-group{
    display: flex;
    align-items: center;
    width: auto !important;
}