.employee-personal-info-main-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 1% 0 4% 0;
}
.employee-personal-info-container {
  padding: 1% 4%;
  display: flex;
  justify-content: space-between;
}
.employee-personal-info-single-input-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}
.employee-personal-info-container .employee-upadte-btn {
  margin: 3.5% 1% 1% 1% !important;
}
.employee-personal-info-account-login-container {
  padding: 1% 4%;
}
.employee-personal-info-account-login-input-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 3% 0;
}
.employee-personal-info-account-login-input-container .account-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75% !important;
  margin: 1% 0;
}
.employee-personal-info-account-login-input-container .account-login p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
}
.employee-personal-info-account-login-input-container .account-login input {
  background-color: #eee;
  border: none;
  width: 55%;
  height: 40px;
  border-radius: 4px;
  padding-left: 2%;
}
.user-login-update-btn{
  margin:6% 0 1% 45%;
  width: 55%;
  height: 30px;
  background-color: #ff9933;
}
.user-login-update-btn:hover{
  background-color: #f3871c;
}
.emp-personal-login-cred-parent{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.emp-personal-info-left-container{
  width: 69%;
}
.emp-personal-info-right-container{
  width: 30%;
}

.edit-login-info-parent{
  width: 28vw;
  padding: 3%;
}
.login-cred-single-input{
  width: 98%;
  margin-top: 4%;
  position: relative;
}
.login-cred-single-input input{
  background-color: rgb(243, 243, 243);
  border: 1px solid #000;
}
.login-cred-cancel-submit-btn-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.login-cred-cancel-submit-btn-container button{
  width: 46%;
}


/* company info */
.employee-dashbord-company-info-container {
  margin: 0 4%;
}
