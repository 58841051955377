.quick-access{
    height: 32px;
    margin: 2% 2% ;
    padding: 8px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.quick-access-content{ 
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

/* quick access edit dialog  */
.quick-access-dialog-container{
    width: 30vw;
    height:auto;
    padding: 4%;
}
.quick-access-dialog-select{
    margin: 2% 0;
    display: flex;
    flex-direction: column;
}
.quick-access-dialog-select input{
    background-color: transparent !important;
    font-size: 0.9rem !important;
}
.quick-access-dialog-container h3{
    margin: 1%;
}
.quick-access-edit{
    width: 98% !important;
    height: 47px !important;
    /* margin:2% 0 !important;
    padding: 2%; */
}
.quick-access-edit div.MuiOutlinedInput-root{
    height: 40px !important;
    background-color:#d0ebef !important;
    color: #01819b;
    font-size: 0.8rem;
    font-weight: 600;
}
.clr-input-second div.MuiOutlinedInput-root{
    background-color:#dbf0e2 !important;
}
.clr-input-third div.MuiOutlinedInput-root{
    background-color: #ffddc3 !important;
}
.clr-input-four div.MuiOutlinedInput-root{
    background-color:#d3e3ec !important;
}
.clr-input-five div.MuiOutlinedInput-root{
    background-color: #ebebe0 !important;
}
.clr-input-six div.MuiOutlinedInput-root{
    background-color: #ecd3d3 !important;
}
.clr-input-seven div.MuiOutlinedInput-root{
    background-color: #cfc3ee !important;
}
.quick-access-edit-button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 0;
}
.quick-access-edit-cancel-button{
    width: 27%;
    height:37px;
    border-radius: 4px;
    margin-right:8px;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
}
.quick-access-edit-submit-button{
    width: 27%;
    height:37px;
    background-color: #0011f8;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 4px;
    font-size: 1rem;

} 
.quick-access-edit-submit-button:hover{
    cursor: pointer;
    background-color: #0210d1;
}