.checkbox-and-dropdown-container{
    display: flex;
    flex-direction: column;
    margin: 2% 1%;
}
.pos-settings-checkbox-container{
    display: flex;
    align-items: center;
    margin: 1% 0;
}
.pos-settings-checkbox-container input[type="checkbox"]{
  accent-color: #000;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.pos-settings-checkbox-container label{
    font-size: 0.9rem;
    font-weight: 700;
    margin: 2px 0 0 4px;
    cursor: pointer;
}
.pos-settings-dropdown-container{
    display: flex;
    width: 100%;
    align-items: center;
}
.pos-settings-dropdown-container p{
    width: 30%;
    font-size: 0.8rem;
    font-weight: 600;
}

.pos-settings-dropdown-container div.MuiOutlinedInput-root {
    width: 100%;
    border: 1px solid #9c79fc;
    height: 40px;
  }
  .auto-complete-new div.MuiOutlinedInput-root{
    height: auto !important;
    padding-right: 30px !important;
  }
  .auto-complete-new .MuiAutocomplete-input{
    height: 0px;
  }
  .auto-complete-new .MuiAutocomplete-root {
    width: 100%;
    border-radius: 0px;
    /* height: 40px !important; */
  }
  .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none!important;
  }
  .auto-complete-new .MuiInput-root {
    padding-bottom: 1px;
    min-height: 30px;
  }
  .pos-settings-dropdown-container
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 8px !important;
  }
  .pos-settings-dropdown-container div fieldset {
    border: none;
    border-radius: 0;
  }
  .pos-settings-dropdown-container .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator{
    background-color: #E3E2FF;
    border-radius: 0px;
    height: 22px;
    width: 23px
  }




