.account-settings-container{
    padding:2% 5%;
    background-color: #fff;
    margin: 1% 0;
    border-radius: 8px;
    position: relative;
    /* height:73vh */
}
.account-settings-container h4{
    margin:2%;
}
.company-setting-gst-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.account-settings-input-container{
    padding: 1% 0 2% 0;
}
.account-settings-account-info{
    display: flex;
    width: fit-content;
    margin: 2% 0 0 35%;
    width: 50%;
    justify-content: space-between
}
.account-settings-account-info p{
    margin: 0;
    color: #1b32ff;
    font-size: 1rem;
    font-weight: 600;
    
}
.account-settings-account-info-radio-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width:37%;   
}
.account-info-radio-yes input[type="radio"]{
    accent-color:#1b32ff;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.account-info-radio-yes label{
    font-size:1rem;
    cursor: pointer;
    margin-left:2px;
}
.account-settings-ifsc-container{
    position: relative;
}
.account-settings-ifsc-container button{
    position: absolute;
    right:1%;
    height: 28px;
    width:14%;
    border-radius: 0px;
    font-size: 0.75rem;
    font-weight: 600;
}
.account-settings-upi-details-container{
  display: flex;
  margin: 0 2%;
}
.account-settings-upi-details-input-container{
    display: flex;
    height: fit-content;
    width: 100%;
}
.upi-details-input{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:46%;
  margin: 4% 8% 2% 0;
}
.upi-details-input p{
    font-size: 0.8rem;
    font-weight: 500;
}
.upi-details-input input{
    width: 100%;
    padding: 0 3%;
}
.upi-details-input div.MuiOutlinedInput-root{
    width: 100% !important;
}
.qr-code-upload-container{
    width: 30%;
    margin: 2% 2% 2% 4%;
}
.qr-code-upload-container p{
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
}
.account-settings-upi-details-update-btn{
   display: flex;
   justify-content: center;
   margin: 2% 0;
}
.account-settings-upi-details-update-btn button{
    width:28%;
}


