.gstr-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0;
}
.gstr-1 .input-and-submit-wrapper {
  width: 100%;
  background-color: white;
  padding: 5% 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  margin-block-end: 0.5em;
  flex-wrap: wrap;
}
.gstr-1 .submit-btn {
  margin-top: 1.4em;
}
.gstr-1 .submit-btn button {
  width: 10em;
  height: 3em;
  background-color: #005dff;
  color: white;
  border: none;
  border-radius: 5px;
}
.input-and-submit-wrapper .global-single-input {
  width: 25%;
}
.input-and-submit-wrapper .global-table-container {
  width: 100%;
}
.input-and-submit-wrapper table tbody tr .description-data {
  text-align: start;
  padding: 0 20px;
  width: 40%;
}
.global-table-container .total-table thead tr th {
  color: transparent;
}
.global-table-container .total-table tbody tr td {
  font-weight: 600;
}
.detail-dialog-view .close-btn {
  display: flex;
  justify-content: center;
  padding: 2em;
}
.detail-dialog-view .close-btn {
  display: flex;
  justify-content: center;
  padding: 2em;
}
.detail-dialog-view .close-btn button {
  border: 1px solid #277ed5;
  width: 10em;
}
.input-and-submit-wrapper .action-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.input-and-submit-wrapper .action-btn-wrapper.dialog-action{
  justify-content: space-between;
  align-items: center;
}

@media print {
 
  .global-table-wrapper .total-table.global-table thead tr th{
    border: 1px solid rgb(255, 255, 255);
  }
  .gst-details-wrapper {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .gst-details-wrapper h4 {
    width: 100%;
    text-align: end;
  }
  .gst-details-wrapper .gst-details >div{
    display: flex;
    gap:0 5px;
  }

  .gst-details-wrapper .gst-details >div p{
    margin: 0;
  }
  .gst-details-wrapper .gst-details >div .gst-registration{
    width: 10em;
  }
  .gst-details-wrapper .gst-details >div .details-description{
    font-weight: 600;
   white-space: nowrap;
  }
  
}