#currentAsset-report .accounting-voucher-menu-sections > div {
  margin-block-start: 0em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 9%;
}
#currentAsset-report .global-single-input {
  width: 25%;
}
#currentAsset-report .table-container {
  width: 100%;
}
#currentAsset-report .global-table-container {
  margin: 0;
  width: 100%;
}
.action-btn-container {
  display: flex;
  justify-content: flex-end;
}
.action-btn-container > div {
  display: flex;
}
.action-btn-container > div button {
  width: 60%;
}
.action-btn-container > div .download-btn {
  border: none;
  background-color: transparent;
  color: red;
}
.action-btn-container > div .download-btn:hover {
  background-color: #f5f5f5;
}

#currentAsset-report .data-table-row .visibility-icon {
  color: #c9c9c9;
}
#currentAsset-report .data-table-row td {
  background-color: white;
  border-bottom: 1px solid #c9c9c9;
}
#currentAsset-report .bottom-line td {
  border-bottom: none;
}
#dropdown-table td {
  background-color: white;
}

#currentAsset-report .total-container {
  background-color: #a6a7af;
  color: white;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap !important;
  width: 100%;
}
#currentAsset-report .total-container .total-head {
  flex: 1% 0;
  margin-top: 1.2em;
}
#currentAsset-report .total-container > div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5%;
  padding: 0% 3%;
}

#currentAsset-report .cash-or-bank {
  flex-wrap: wrap !important;
}
#currentAsset-report .cash-or-bank > div {
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
#currentAsset-report .cash-or-bank > div .global-single-input input {
  width: 55%;
}

/* print preview  */
#print-view {
  padding: 2%;
}
#print-view h2,
h3 {
  margin: 0;
}

#print-view .print-head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#print-view .print-head-container .brand-name {
  flex: 1 0 100%;
  text-align: center;
  margin-top: 2em;
}
#print-view .print-head-container .select-table-head {
  flex: 1 0 100%;
  text-align: center;
}
#print-view .print-head-container .select-table-head h3 {
  text-transform: uppercase;
  margin: 1% 0;
}

#print-view .print-head-container .duration-date {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

#print-view .print-table-container thead tr th {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

#print-view .trail-balance-total > div {
  display: flex;
  width: 100%;
  background-color: #ebeefe;
  padding: 1%;
}

#print-view .trail-balance-total > div .trail-total-headers {
  width: 25%;
  text-align: center;
}
#print-view .trail-balance-total > div .header-name {
  width: 56%;
}

#currentAsset-report .opening-balance-head th {
  font-size: 14px;
  background-color: #ebeefe;
}
#currentAsset-report .global-table tbody:before {
  content: "@";
  display: block;
  line-height: 7px;
  text-indent: -99999px;
}

#currentAsset-report .ledger-report-total {
  display: flex;
  justify-content: flex-end;
}

#currentAsset-report .ledger-report-total .ledger-total-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35%;
}

.ledger-total-container div {
  display: flex;
  margin-top: 10px;
  background-color: #ebeefe;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
}
.ledger-total-container div .closing-balance {
  color: red;
}
.ledger-total-container div h5 {
  margin: 0;
  width: 33%;
}
.ledger-total-container div .total-amount {
  text-align: center;
}

#trail-balance .data-table-row td {
  border-bottom: none;
}
#trail-balance .trail-balance-total {
  width: 100%;
  padding: 1%;
}
#trail-balance .trail-balance-total > div {
  display: flex;
  width: 100%;
  background-color: #ebeefe;
  padding: 1%;
}

#trail-balance .trail-balance-total > div .trail-total-headers {
  width: 17.5%;
  text-align: center;
}
#trail-balance .trail-balance-total > div .header-name {
  width: 42%;
}
#final-report {
  gap: 0 1%;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

#cashflow-statement-direct .action-btn-container {
  justify-content: flex-end;
  width: 100%;
  margin-block-start: 0;
}

#cashflow-statement-direct .global-table-wrapper {
  width: 100%;
}
#cashflow-statement-direct .global-table-container {
  width: 100%;
}

#cashflow-statement-direct .data-table-row-head td .main-head {
  text-align: start;
  width: 100%;
  margin: 1%;
}
#cashflow-statement-direct table tbody td {
  border: 1px solid #e3e3e3;
}
#cashflow-statement-direct .data-row-list {
  text-align: start;
}

#cashflow-statement-direct table tbody .data-row {
  background-color: #fff !important ;
  border: 1px solid #e3e3e3;
}
#cashflow-statement-direct table tbody .data-row .data-row-amount {
  list-style: none;
  padding: 0;
  text-align: start;
}
#final-report .accounting-side-menu {
  flex: 0 0 auto;
}

#cashflow-statement-direct table tbody td .net-cash-flow {
  text-align: end;
  color: red;
  padding-right: 5%;
 
}

.net-cashflow-amount-wrapper .data-row,
.total-amount {
  font-weight: 600;
  text-align: start;
  color: black;

}
.net-cashflow-amount-wrapper .data-row,
.profit {
  font-weight: 600;
  color: green;
  text-align: end;
}
.net-cashflow-amount-wrapper .data-row,
.profit-amount {
  font-weight: 600;
  color: green;
  text-align: start;
}
.net-cashflow-amount-wrapper .data-row,
.important {
  font-weight: 600;
  color: red;
  text-align: start;
  padding-left: 0;
}

#total-box-input tr td {
  background-color: #a6a7af !important;
}

#total-box-input .list-total {
  list-style-type: none;
  padding-right: 5%;
}

#total-box-input .list-total li {
  text-align: end;
  width: 100%;
}

#total-box-input .list-total li:nth-child(1) {
  color: red;
}

#total-box-input .list-total li:nth-child(3) {
  color: blue;
}

.list-total-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.list-total-wrapper > div {
  margin: 1% 0%;
}
.list-total-wrapper > div input {
  padding: 8px;
  border: none;
  width: 80%;
}
#cashflow-statement-direct .amount-column {
  width: 15%;
}

.global-wrapper .accounting-voucher-menu-sections > div{
 display: flex;
 width: 100%;
 margin-block-start: 0em;
}

.global-wrapper{
  width: 100%;
}

.global-wrapper .global-table-container{
  width: 100%;
}
/* print - 2 */

@media print {
 #cashflow-statement-indirect {
  padding: 3.2%;
 }
  .print-preview-container {
    transform: scale(.9); 
 }
  .print-preview-container {
    display: flex;
    flex-wrap: wrap;
    padding: 3%;
    width: 100%;
    margin: 0 auto;
  }
  .print-preview-container table tbody .data-row .data-row-amount {
    list-style: none;
    padding: 0;
    text-align: start;
  }

  .print-preview-container .print-head-container {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    border-collapse: collapse;
  }

  .print-preview-container .print-head-container .duration-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
  }
  .global-table-wrapper{
    width:100%
  }
  .global-table-wrapper .global-table thead{
    position: static;
  }
  .global-table-wrapper .global-table thead tr th{
    border: 1px solid black;
  }
  .account-print-table{
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
  }
  .account-print-table thead{
    border: 1px solid black;
  }

  .print-preview-container .global-table-wrapper table tbody td {
    border: 1px solid black;
    text-align: start;
    padding: 1%;
    background-color: white ;
  }
  .print-preview-container .global-table-wrapper table tbody .data-row {
    background-color: white !important;
  }
  .print-preview-container
    .global-table-wrapper
    table
    tbody
    .data-row
    .net-cash-flow {
    text-align: end;
    color: red;
  }
  .print-preview-container
    .global-table-wrapper
    table
    tbody
    .net-cashflow-amount-wrapper {
    position: relative;
  }
  .print-preview-container
    .global-table-wrapper
    table
    tbody
    .net-cashflow-amount-wrapper
    .net-cashflow-amount {
    position: absolute;
    list-style: none;
    left: 0;
    padding-left: 0;
  }
  .print-preview-container
    .global-table-wrapper
    table
    tbody
    .net-cashflow-amount-wrapper
    .total-amount {
    bottom: 1%;
    padding-right: 5%;
  }
  .print-view-table-container .global-table-wrapper .global-table {
    border: 1px solid black;
  }
  
}
