.grn-draft-container{
    background-color: #fff;
    padding: 1%;
}
.grn-draft-container h3{
    margin: 1% 0;
    padding: 0 1.5%;
}
.grn-draft-post-btn{
    background-color: #00a759;
    color: #fff;
    padding:1% 2%;
    font-size: 0.8rem;
    font-weight: 500;
    width: 50px;
    cursor: pointer;
    border-radius: 2px;
    margin: auto 0;
    border: none;
    height: 20px;
}
