.forgot-password-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 0 10% 0;
}
.forgot-password-container h3{
    margin: 2% 0 1% 0;
}
.forgot-password-container p{
    margin: 0;
    color: rgb(165, 163, 163);
}
.forgot-password-number-input-container{
   width:58%;   
   margin: 6% 0;
}
.forgot-password-number-input-container p{
    color: #000;
    font-size:1rem;
    font-weight: 500;
    margin-left: 4px;
}
.forgot-password-number-input-container .forgot-password-input{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
}
.forgot-password-number-input-container .forgot-password-input input{
    width:79%;
    height: 50px;
}
.forgot-password-number-input-container .forgot-password-input div.MuiOutlinedInput-root{
    width: 20%;
    height: 50px;
}
.forgot-password-send-otp-btn{
    width:20%;
    margin: 4% 1% 1% 1% !important;
}
.forgot-password-otp-button-container{
    display: flex;
    width:45%;
    margin: 2% 0;
}
.verify-btn{
    width: 45% !important;
}
.otp-input-container{
    margin: 8% 0;
    width: 45%;
    display: flex;
    justify-content: center;
    margin-left: 16px;
}
.otp-input-container div.otp-input-root{
    width: 100% !important;
}
.otp-input-container input[type="tel"]{
    background-color: #edeef0;
    border: none;
    width: 52px !important;
    height: 52px !important;
    /* margin: 1% 1% !important; */
}
.account-settings-reset-password-btn-container{
  display: flex;
  justify-content: center;
  margin: 8% 0;
}
.account-settings-reset-password-btn-container button{
    width: 30%;
}
