.payments-parent-overlay{
  width: 101%;
  height: 100%;
  background-color: #b8b8b8;
  position: absolute;
  top: 0;
  z-index: 1215;
  background-color: rgba(255, 255, 255, 0.3);
}


.work-order-payments {
  background-color: #fff;
  position: absolute;
  top: 0;
  /* left: 58%; */
  bottom: 0;
  right: 0;
  width: 450px;
  padding: 2%;
  z-index:1215;
  box-shadow: 0 0 10px 8px #eee;
  /* max-height: 125vh;  */
  overflow-y: scroll;
  height: 91vh;
}
.work-order-payments::-webkit-scrollbar {
  width: 4px;
  background-color: #d3e3ec;
}
.work-order-payments::-webkit-scrollbar-thumb {
  background-color: #849ba8;
}
.work-order-payment-top-container {
  display: flex;
  justify-content: space-between;
}
.work-order-payment-top-container h3 {
  margin: 0;
}
.work-order-payments-close-icon {
  border: 1px solid red;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  cursor: pointer;
}
.work-order-payment-second-container {
  border: 3px solid #eee;
  padding: 2%;
  margin: 2% 0 1% 0;
}
.work-order-payments-input-container {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  align-items: center;
  margin: 1% 0;
}
.sub-total-container{
  position: relative;
}
.delivery-total-wo-amount{
  width: fit-content;
  font-size: 0.9rem;
  margin: 0;
  position: absolute;
  right: 6px;
  bottom: -10px;
  font-size: 0.7rem !important; 
  font-weight: 600 !important;
}
.work-order-payments-input-container p {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.work-order-payments-input-container input {
  background-color: #eee;
  border: none;
  height: 40px;
  border-radius: 4px;
  width: 55%;
  padding: 1% 2%;
  font-size: 1rem;
}
.inner-btn {
  position: relative;
}
.payment-coupon-btn {
  position: absolute;
  right: 12px;
  width: 13%;
  height: 26px;
  font-size: 0.7rem;
  font-weight: 600;
}
.payment-coupon-btn:disabled{
  background-color: rgb(175, 178, 201);
  cursor: not-allowed;
}
.payment-reward-btn {
  position: absolute;
  right: 12px;
  width: 13%;
  height: 26px;
  font-size: 0.7rem;
  background-color: #0da462;
  font-weight: 600;
}
.payment-reward-btn:disabled{
  background-color: rgb(205, 236, 217);
  cursor: not-allowed;
}
.payment-reward-btn:disabled:hover{
  background-color: rgb(205, 236, 217);
}
.payment-reward-btn:hover {
  background-color: #22b978;
}
.payment-credit-btn {
  position: absolute;
  right: 12px;
  width: 13%;
  height: 26px;
  font-size: 0.7rem;
  background-color: #ff0000;
  font-weight: 600;
}
.payment-wallet-btn {
  position: absolute;
  right: 12px;
  width: 13%;
  height: 26px;
  font-size: 0.7rem;
  background-color: #53d3c2;
  font-weight: 600;
}
.payment-wallet-btn:hover{
  background-color: #3bb1a1;
}
.payment-wallet-btn:disabled,.payment-credit-btn:disabled{
  background-color: #a4acab;
  cursor: not-allowed;
}
.payment-credit-btn:hover {
  background-color: #ee2b2b;
}
.credit-amount-container p {
  color: #ff0000;
}
.credit-amount-container input {
  color: #ff0000;
}
.work-order-payments-total-amount-container {
  display: flex;
  background-color: #eee;
  padding: 1% 6%;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  height: 60px;
}
.work-order-payments-change-amount-container{
  display: flex;
  background-color: #eee;
  justify-content: space-between;
  padding: 0 6.5% 1%;
}
.work-order-payments-change-amount-container p{
  margin: 0;
  font-size: 0.9rem;
}
.work-order-payments-total-amount-container p {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
}
.work-order-payments-total-amount-container .payment-total-amount {
  font-size: 1.1rem;
}
.payment-os-balance {
  display: flex;
  justify-content: flex-end;
}
.payment-os-balance p {
  margin: 8px 0;
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}
.payment-method-container p {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 2px 0;
}
.payment-method-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-method-checkbox-container input[type="checkbox"] {
  accent-color: #000;
}
.payment-method-checkbox-container label {
  font-size: 0.9rem;
}
.payment-method-type-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 2% 0;
}
.payment-method-type-input-container {
  width: 40%;
}
.payment-method-type-input-container div.MuiOutlinedInput-root {
  background-color: #639e16;
  width: 100%;
  border: none;
  height: 40px;
  border-radius: 0px;
  color: #fff;
}
.payment-method-type-input-container .MuiSelect-icon {
  color: #fff;
}
.payment-method-card-type-container {
  width: 57%;
}
.payment-method-card-type-container div.MuiOutlinedInput-root {
  width: 100%;
  height: 40px;
  border: 0.5px solid #000;
  border-radius: 0px;
  background-color: transparent;
}
.payment-method-card-type-container input {
  width: 100%;
  height: 40px;
  border: 0.5px solid #000;
  border-radius: 0px;
  background-color: transparent;
}
.payment-method-bottom-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3% 0;
}
.payment-method-bottom-container .clear-list-button {
  width: 50%;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: #e40707;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
}
.payment-method-bottom-container .hold-button {
  width: 50%;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: rgb(235, 160, 10);
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
}
.payment-place-order-btn {
  width: 100%;
  height: 40px;
  background-color: #00a44c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.payment-place-order-btn:hover{
  background-color: #039647;
}
.payment-place-order-btn:disabled{
  background-color: #6f9e8a;
  cursor: not-allowed;
}
.held-products {
  background-color: #2603d7 !important;
}

.held-product-dialog {
  width: 60vw;
  padding: 2%;
}
.held-products-table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
}
.held-products-table thead {
  height: 40px;
  border-bottom: 1px solid #000;
}
.held-products-table thead tr th {
  border-bottom: 1px solid #000;
  text-align: center;
  font-size: 0.9rem;
}
.held-products-table tbody tr td {
  text-align: center;
  font-size: 0.9rem;
  height: 50px;
}
.held-products-pay {
  background-color: #2f0ae9;
  color: #fff;
  padding: 5% 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.held-products-pay:hover {
  background-color: #421ef7;
}
.held-product-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payments-section-current-wallet-p{
  position: absolute;
  top: 87%;
  left: 45%;
  font-size: 0.8rem !important;
  color: blue;
}
/* .pymtcontainer{
  border: 2px solid orange;
} */

/* .pymtcontainer1{
  border: 2px solid green;
} */