.ot-details{
    width: 65%;
    display: flex;
    justify-content: space-between;
}
.ot-details h4 {
    color:#361ecf;
    cursor: pointer;
}
.ot-detail-cards{
    width: 65%;
}
.ot-card-container {
  gap: 9px;
    display: flex;
    flex-wrap: wrap;
    margin: 6px;
    margin-bottom: auto;
    overflow: auto;
    height: 211px;
}
.ot-card {
  width: 170px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
    background-color: #fff;
gap:3px;
 
border: 1px dashed #a6a6a6;

    cursor: pointer;

}
.ot-card h5{
  margin:0;
  white-space: nowrap;
}
.ot-card-text-container {
    width: 100%;
    margin-top: -5px;
}
.ot-hr{
    border-top: 1px dotted #a6a6a6;
}
.overType-table-container {
    width: 100%;
    margin-top: 20px;
  }
  
  .overType-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .overType-table th,
  .overType-table td {
    border: none;
    padding: 8px;
    text-align: left;
  }
  
  .overType-table th {
    font-weight: bold;
  }
  
  .overType-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .delete-icon {
    cursor: pointer;
    color: #ff0000; /* Red color for delete icon */
  }
  
 