.clickable-card-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card-icon {
  width: 2rem;
  color: #fff;
}
.recent-sales-and-quickAccess-row {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}
.recent-sales-container {
  /* background-color: #fff;
  padding: 1%;
  width: 68%;
  max-height: 57vh;
  min-width: 480px;
  margin: 1%; */
  width: 40%;
  background-color: #fff;
  
}
.recent-sales-container h4{
  margin: 4% 0 1% 3%
}
.dashboard-recent-sales-table {
  max-height: 40vh !important;
}
.quick-access-container {
  /* width: 30%;
  background-color: #fff;
  padding: 1%; */
  width: 20%;
  background-color: #fff;
  
}
.quick-access-container h4{
  margin: 4% 0 3% 3%
}
.recent-sales-container table img.product-image {
  width: 50px;
  height: 50px;
  margin-top:6px;
}
.dashboard-row-group-three {
  display: flex;
  justify-content: space-between;
  margin: 1% 0;
}
.dashboard-row-group-three .row-group-three-left {
  width: 38%;
}
.dashboard-row-group-three .row-group-three-right {
  width: 60%;
}
.row-group-three-left .employee-chart {
  background-color: #fff;
  padding: 4%;
}
.employee-chart .top-heading-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.employee-chart .top-heading-row p.name {
  margin-right: auto;
  font-weight: 600;
  font-size: 1rem;
}
.employee-chart .top-heading-row p {
  margin: 0 1%;
  font-size: 0.8rem;
  font-weight: 600;
}
.employee-chart .top-heading-row p.count {
  font-weight: 600;
  font-size: 1.1rem;
}
.employee-chart .progress-bars {
  margin: 4% 0;
}
.employee-chart .progress-bars progress {
  width: 100%;
  height: 32px;
  border-radius: 0;
  background-color: #fff;
  border: none;
}
.progress-bars progress::-webkit-progress-bar {
  background: #ececec;
}
.progress-bars progress::-moz-progress-bar {
  background: #ececec;
}
.progress-bars progress.male::-webkit-progress-value {
  background: #d40024;
}
.progress-bars progress.female::-webkit-progress-value {
  background: #00a864;
}
.employee-chart .progress-legend-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.progress-legend-row .legend-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100px;
  margin: 0 4%;
}
.legend-group div.color-indicator-male {
  width: 24px;
  height: 24px;
  background-color: #d40024;
}
.legend-group p.male {
  color: #d40024;
  font-size: 0.9rem;
  font-weight: 600;
}
.legend-group div.color-indicator-female {
  width: 24px;
  height: 24px;
  background-color: #00a864;
}
.legend-group p.female {
  color: #00a864;
  margin-left: 1%;
  font-size: 0.9rem;
  font-weight: 600;
}
.row-group-three-left .expired-documents-container {
  width: 100%;
  background-color: #fff;
  margin: 1% 0;
  padding: 2% 3%;
  max-height:68vh;
  min-height: 70%;
  /* border: 2px solid blue; */
}
.expired-documents-container p.heading {
  font-weight: 600;
  font-size: 1rem;
}
.dash-expired-docs-table-container {
  max-height: 53vh;
}
.row-group-three-right .total-revenue-container {
  background-color: #fff;
  padding: 1%;
}
.total-revenue-container .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-revenue-container .top-bar p {
  font-weight: 600;
}
.row-group-three-right .payments-container {
  background-color: #fff;
  padding: 1%;
  margin: 1% 0;
 
}
.payments-container .top-bar {
  display: flex;
  justify-content: space-between;
}
.payments-data .payments-container {
  max-height: 28vh;
}
.payments-container .top-bar p {
  font-size: 1rem;
  font-weight: 600;
}
.payments-data-table td div.doc-image-container {
  background-color: #ede8cb;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
}
.payments-data-table td div.doc-image-container img {
  width: 16px;
}
.payments-data-table td.align-left {
  text-align: left;
}
.payments-data-table .main-data {
  font-weight: 600;
}
.payments-data-table .label {
  font-size: 0.7rem;
}
.payments-data-table tr td {
  padding: 4px;
  font-weight: 600;
  line-height: 1;
}
.dashboard-row-group-four {
  width: 100%;
  background-color: #fff;
  padding: 1%;
}
.dashboard-row-group-four .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2%;
}

.dashboard-row-group-four .top-bar p{
    font-weight: 600;
}
.dashboard-row-group-four .top-bar .top-bar-left{
  display: flex;
  width: 35%;
  justify-content: space-between;

}
.product-list-table-container table tbody td img{
    width: 50px;
    height: 50px;
    margin-top:6px;
}
.product-list-table-container table .closing-stock-column{
    color: #ff2400;
}

/* bar chart */
.bar-chart-arrow-icon{
  margin: 2px 0 0 2px;
}
.bar-chart-arrow-down-icon{
  color: red;
  font-size: 0.9rem !important;
  font-weight: 600;
  cursor: pointer;
}
.product-outlet-radio[type="radio"]:checked + label {
  background-color: #f55e27 !important;
  font-weight: 700;
}
.fast-selling-next-btn-ctn{
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
  align-items: flex-end;
}
.fast-selling-forward-icons-ctn{
  display: flex;
  align-items: baseline;
}
.fast-selling-forward-date-ctn{
  display: flex;
  width: 70%;
}
.home-dashboard-chart-no-data-alert{
  position: absolute;
  top: 40%;
  left: 40%;
  color: red;
  font-weight: 700;
}
.home-dashboard-print-fast-sell{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 25%;
  flex-direction: column;
}
.home-dashboard-print-fast-sell-bar{
  width: 80%!important;
  height: auto!important;
}