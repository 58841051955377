.alteration-container{
    background-color: #fff;
    padding: 1%;
    z-index: 0;
}
.alteration-top-container{
    display: flex;
    justify-content: space-between;
    margin: 0 1% 0 2%;
    align-items: center;
}
.alteration-top-right-container{
    display: flex;
    width: 35%;
    justify-content: flex-end;
    /* align-items: center;
    justify-content: center; */
}
.alteration-top-right-container button{
    width: 50% !important;
    
}
.alteration-top-right-print-container{
    width: 40%;
    
}
.alteration-top-right-print-container .alteration-print{
  width: 80% !important;
  background-color: #ff6f00 !important;
  color: #fff !important;
  margin: 8% 8% 2% 8%!important;
}
.alteration-top-right-print-container .alteration-print i{
    color: #fff !important;
}
.alteration-print-checkbox-container{
    display: flex;
    align-items: center;
    margin: 0 8%;
}
.alteration-print-checkbox-container input{
  cursor: pointer;
  accent-color:#ef6902  !important;
}
.alteration-print-checkbox-container label{
    color:#ff6f00;
    font-size: 0.7rem;
    font-weight: 600;
}
.add-alteration-table thead{
    z-index: 0 !important;
}
.add-alteration-dialog-container{
    width: 41vw;
    z-index: 3;
    /* padding: 2%; */
}
.add-alteration-dialog-container h4{
    margin:4%;
}
.add-alteration-dialog-input-container{
    display: flex;
    flex-wrap: wrap;
    padding:0 3%;
}
.add-alteration-input{
    width:48%;
    position: relative;
}
.add-alteration-dialog-checkbox{
    margin: 2% 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-alteration-readyMade-workOrder-radio{
    display: flex;
    width: 76%;
    justify-content: space-between;
    align-items: center;
}
.add-alteration-readyMade-workOrder-radio input{
    accent-color: #000;
    cursor: pointer;
}
.add-alteration-readyMade-workOrder-radio label{
    color: #000 !important;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
}
.add-alteration-dialog-checkbox input{
    width:15px;
    height:15px;
    margin-right: 12px;
}
.add-alteration-dialog-checkbox label{
    color: blue;
    font-size:0.9rem;
}
.add-alteration-dialog-input-container .add-alteration-radio-button{
  flex-direction: row !important;
  width: 48% !important;
  justify-content: flex-start;
  align-items: center;
  
}
.add-alteration-radio-button-free-container{
    margin-right:16px;
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-alteration-radio-button-free-container input{
    width: 13px;
    height: 13px;
    accent-color: #0800ff;
    cursor: pointer;
}
.add-alteration-radio-button-free-container label{
    color: #0800ff;
    font-size:0.9rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 4px;
}
.add-alteration-radio-button-paid-container{
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} 
.add-alteration-radio-button-paid-container input{
    width: 13px;
    height: 13px;
    accent-color:rgb(6 171 6);
    /* accent-color: #4bd037; */
    cursor: pointer;
}

.add-alteration-radio-button-paid-container label{
    color: #4bd037;
    font-size:0.9rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 4px;
}

.add-alteration-hr{
    border: none;
    border-bottom: 1px dashed rgb(182, 178, 178);
}
.add-alteration-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1% 0;
}

.add-alteration-input-checkbox-container{
    display: flex;
    justify-content: space-between;
}
.add-alteration-input-checkbox-container input{
    width: 13px;
    height: 13px;
    accent-color:#0800ff;
    cursor: pointer;
}
.add-alteration-input-checkbox-container label{
    font-size: 0.9rem;
    color: #0800ff;
    cursor: pointer;
}

/* material checkbox */
.alteration-material-checkbox-main-container{
    padding: 1% 4%;
    position: relative;
    margin: 4% 0 0 0;
}
.alteration-material-checkbox{
    display: flex;
    position: absolute;
    left: 34%;
    top: 2%;
}
.alteration-material-checkbox input[type="checkbox"]{
    width: 13px;
    height: 13px;
    accent-color:#0800ff;
    cursor: pointer;
}
.alteration-material-checkbox label{
    font-size: 0.9rem;
    color: #0800ff;
    cursor: pointer;
    font-weight: 600;
}

.alteration-table-print table thead tr th{
   text-align: left !important;
}
.alteration-table-print table tbody tr td{
    text-align: left !important;
}