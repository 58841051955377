.stock-adjustment-list-container{
    background-color: #fff;
    padding: 2%;
}
.stock-adjustment-list-container h3{
    margin: 0;
    padding: 0 1%;
}
.stock-adjustment-list-table{
    margin: 1% 0 !important;
} 
.status-confirm{
    background-color: #f0b71a;
    color: #fff;
    border-radius:32px;
    margin: 0 auto;
    /* font-size: 0.7rem; */
    padding: 4% 14%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.status-pending{
    background-color: #f0190a;
    color: #fff;
    border-radius:32px;
    margin: 0 auto;
    /* font-size: 0.7rem; */
    padding: 4% 14%;
    text-align: center;
}
.status-received{
    background-color: #278b9b;
    color: #fff;
    border-radius:32px;
    margin: 0 auto;
    /* font-size: 0.7rem; */
    padding: 4% 14%;
    text-align: center;
}
.status-completed{
    background-color:#008453;
    color: #fff;
    border-radius:32px;
    margin: 0 auto;
    font-size: 0.7rem;
    padding: 4% 14%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status-drafted{
    background-color: #0019ff;
    color: #fff;
    border-radius:32px;
    margin: 0 auto;
    font-size: 0.7rem;
    padding: 4% 14%;
    text-align: center;
}

/* view dialog */

.stock-adjustment-list-view-container{
    width:62vw;
    padding:0 3%;
}
.stock-adjustment-list-view-container h3{
    padding: 0 1%;
}
.stock-adjustment-list-view-input-container{
    display: flex;
    flex-wrap: wrap;
    margin: 1% 0 4% 0;
}
.stock-adjustment-list-view-gl-code{
    display: flex;
    
}
.gl-link-code-first{
    width: 50% !important;
    margin-right: 2%;
}
.stock-adjustment-list-view-table{
    padding: 0 !important;
    margin: 4% 0 !important;
}
.stock-adjustment-list-view-button-container{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}


/* draft */
.draft-edit-icon{
    color: #fff;
}
