

.daily-cash-card-summary-table{
    max-height: 75vh;
    overflow-y: scroll;
    padding: 1%;
}
.daily-cash-card-summary-table table{
    width: 100%;
    border-collapse: collapse;
}
.daily-cash-card-summary-table table thead{
    position: sticky;
    top: -6;
    height: 40px;
}
.daily-cash-card-summary-table table thead tr th{
    height: 100%;
    text-align: center;
    font-size: 0.8rem;
}
.daily-cash-card-summary-table table tbody{
    background-color:#eee ;
}
.daily-cash-card-summary-table table tbody tr td{
    height: 40px;
    text-align: center;
    font-size: 0.8rem;
    border-bottom: 1px solid rgb(190, 190, 190);
}
.daily-cash-card-summary-table::-webkit-scrollbar{
  width: 4px;
  background-color: #eee;
}
.daily-cash-card-summary-table::-webkit-scrollbar-thumb{
    background-color: rgb(192, 189, 189);
}
.daily-cash-card-expense-table-container h4{
    margin: 2% 0 0 1%;
}
.daily-cash-card-category-container{
    display: flex;
}
.daily-cash-card-table-container{
    margin: 1%;
    width: 33%;
    /* border: 1px solid #000; */
}
.daily-cash-card-table-container p{
  text-align: center;
  margin:0;
  font-size: 0.8rem;
  border-top: 1px solid #000;
  border-left:1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding:2% 0 ;
}
.daily-cash-card-table{
 max-height: 75vh;
 overflow-y: scroll;
 cursor: n-resize;
}
.daily-cash-card-table table{
    width: 100%;
    /* border-top: 1px solid #000; */
    border-collapse: collapse;
    /* border-bottom: 1px solid #000; */
}
.daily-cash-card-table table thead{
    position: sticky;
    top: -6px;
    background-color: #fff;
    height: 30px;
    border-left:1px solid #000;
    border-bottom: 1px solid #000;

}
.daily-cash-card-table table thead tr th{
    background-color: #fff;
    text-align: center;
    font-size: 0.6rem;
    border-right: 1px solid #000;
    padding: 2% 0;
}
.daily-cash-card-table table tbody tr{
    border-left:1px solid #000;
    border-bottom: 1px solid #000;
    height: 32px;
}
.daily-cash-card-table table tbody tr td{
    background-color: #fff;
    text-align: center;
    font-size: 0.71rem;
    border-right: 1px solid #000;
}
.daily-cash-card-table::-webkit-scrollbar{
    width: 0px;
    background-color: #d3e3ec;
}
.daily-cash-card-table::-webkit-scrollbar-thumb{
    background-color: #000;
}
.daily-cash-card-table-bottom-cell{
    background-color: #eee !important;
    border-right: 1px solid #000;
    height: 40px !important;
}
.daily-cash-card-table-bottom-cell td{
    background-color: #eee !important;
    cursor: context-menu !important;
    border-right: none !important;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 0.71rem !important;
    font-weight: 600 !important;

}
.daily-cash-card-summary-bottom-container{
    margin:2% 1%;
}

.daily-cash-card-summary-bottom-single-container{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin:0;
}
.daily-cash-card-summary-bottom-single-container p{
    margin: 1% 0;
    font-weight: 600;
    font-size: 0.9rem;
}
.daily-cash-card-summary-bottom-single-container .first-text{
    color:  #ff0802;
}
.daily-cash-card-summary-bottom-single-container .second-text{
    color:  #2603d7;
}
.daily-cash-session-total-container{
    display: flex;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    justify-content: space-around;
}
.daily-cash-session-total-container p{
    margin:0;
    font-size: 1rem;
    font-weight: 600;
}
.daily-cash-expand-table table tbody tr td{
    padding: 1% 0 !important;
    font-weight: 500 !important;
}

/* daily cash card date filtering */

.daily-cash-card-date-filter-top-container{
    display: flex;
    justify-content: space-between;
}

.daily-cash-card-category-table {
    /* border: 1px solid #000; */
    width: 32%;
}
.daily-cash-card-category-table table thead tr th{
   font-size: 0.7rem;
}
.daily-cash-card-category-table table tbody tr td {
    border-bottom: 1px solid #000 !important;
    background-color: #fff !important;
}
.daily-cash-card-category-table p{
  margin: 1% 0 2% 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
}


.daily-report-bottom-container-denomination-table{
 width: 40%;
 margin: 4% 0;  
}
.daily-report-bottom-container-denomination-table table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #c7c2c2;
}
.daily-report-bottom-container-denomination-table table th{
    border: 1px solid #c7c2c2;
    text-align: center;
    padding: 2% 0;
    font-weight: 600;
}
.daily-report-bottom-container-denomination-table table td{
    border-left:1px solid #c7c2c2;
    border-right:1px solid #c7c2c2;
    text-align: center;
    font-size: 0.9rem;
    padding: 2% 0;
    font-weight: 500;
}
.daily-report-bottom-container-denomination-table table td.table-grand-total-bottom{
    border-top:1px solid #c7c2c2;
    background-color: rgb(238 238 238);
    text-align: right;
    padding-right: 15px;
    padding: 2% 3%;
    font-size: 0.9rem;
    font-weight: 700;
}