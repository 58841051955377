.thob-cutting-slip-container {
  background-color: #fff;
  margin: 1% 0;
  padding: 2% 4%;
 /* display: flex;
 flex-direction: column; */

}
.thob-cutting-slip-top-container {
  display: flex;
  flex-direction: column;
}
.thob-cutting-slip-top-first-content {
  display: flex;
  justify-content: space-between;
  
}
.thob-cutting-slip-top-first-content h3 {
  margin: 0;
}
.thob-cutting-slip-top-first-content p {
  margin: 0;
}
.main-heading-thob-cutting-slip {
  border-bottom: 1px solid rgb(145, 143, 143);
  font-style: italic;
  font-size: 1.1rem;
  font-weight: 500;
}
.thob-cutting-slip-top-second-content {
  display: flex;
  justify-content: space-between;
}
.thob-cutting-slip-top-second-content h3 {
  border-bottom: 1px solid rgb(145, 143, 143);
  margin: 1% 0 0 0;
}
.thob-cutting-slip-top-second-content p {
  margin: 1% 0 0 0;
  font-size: 1rem;
  font-weight: 600;
}
.thob-cutting-slip-top-third-content {
  display: flex;
  justify-content: space-between;
}
.thob-cutting-slip-top-third-content h2 {
  margin: 1% 0;
}
.thob-cutting-slip-top-third-content p {
  margin:0;
  font-size: 1.2rem;
  font-weight: 600;
}
.thob-cutting-slip-middle-top-container {
  display: flex;
  width: 100%;
}
.thob-cutting-slip-single-container {
  border: 1px solid rgb(145, 144, 144);
  height: 32px;
  width: 15%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 6px;
  margin: 1% 1% 1% 0;
  justify-content: space-between;
  padding: 1%;
}
.thob-cutting-slip-single-container p{
  font-size:0.6rem;
  font-weight:600
}
.thob-cutting-slip-single-container .cutting-slip-input-number-text{
  font-size: 0.9rem;
}
.thob-cutting-slip-middle-top-container .single-item-input{
  width: 12% !important;
  background-color: #eee;
}
.large-container {
  width: 20% !important;
}
.last-input-container {
  margin: 1% 0 1% 0 !important;
  height: 70px !important;
  align-items: flex-start !important;
}
.last-input-container p {
  margin: 8px 1px;
}
.thob-cutting-slip-middle-bottom-container {
  display: flex;
  justify-content: space-between;
}
.middle-input {
  margin: 1% 0 1% 0 !important;
}
.thob-cutting-slip-middle-bottom-content {
  display: flex;
  width: 54%;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.thob-cutting-slip-middle-bottom-content p {
  font-size: 0.9rem;
  font-weight: 500;
}
.thob-cutting-slip-middle-bottom-content span {
  font-size: 0.9rem;
  font-weight: 600;
}
.thob-cutting-slip-middle-botton-notes-cotainer {
  display: flex;
  justify-content: space-between;
  padding: 1% 0;
  /* margin: 2% 0; */
}
.thob-cutting-slip-middle-botton-notes-left-cotainer {
  display: flex;
  width: 50%;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.thob-cutting-slip-middle-botton-notes-left-cotainer input {
  width: 80%;
  height: 32px;
}
.thob-cutting-slip-middle-botton-notes-right-cotainer {
  display: flex;
  width: 45%;
}
.right-large-container {
  width: 70% !important;
  display: flex;
  justify-content: space-between;
  padding: 1%;
}

.thob-cutting-slip-middle-table-container {
  /* max-height: 65vh; */
  /* overflow-y: scroll; */
  min-height: 70px;
}
.thob-cutting-slip-middle-table-container table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 1px solid rgb(146, 142, 142);
  margin: 1% 0;
}
.thob-cutting-slip-middle-table-container table thead {
  height: 36px;
}
.thob-cutting-slip-middle-table-container table thead tr th {
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid rgb(146, 142, 142);
}

.thob-cutting-slip-middle-table-container table tbody tr td {
  /* height: 40px; */
  font-size: 0.7rem;
  text-align: center;
  font-weight: 600;
}
.thob-cutting-slip-middle-table-container table tbody tr {
  /* height: 26px; */
}

.thob-cutting-slip-table-qrCode-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* position: relative; */
  margin: 1% 0;
}
.thob-cutting-slip-table-qrCode-table {
  max-height: 45vh;
  width: 80%;
}
.thob-cutting-slip-table-qrCode-table table {
  width: 100%;
  border-collapse: collapse;
}
.thob-cutting-slip-table-qrCode-table table thead {
  height:26px;
}
.thob-cutting-slip-table-qrCode-table table thead tr th {
  text-align: center;
  font-size: 0.8rem;
  border-bottom: 1px solid rgb(146, 142, 142);
  border-top: 1px solid rgb(146, 142, 142);
}
.thob-cutting-slip-table-qrCode-table table tbody tr td {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 600;
  height: 23px;
}
.thob-cutting-slip-table-qrCode-qrcode {
  border: 1px solid rgb(163, 159, 159);
  /* padding: 1%; */
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:130px;
  /* position: absolute;
  right: 0%; */
}
.thob-cutting-slip-table-qrCode-qrcode img {
  width:90%;
 height: 80%;
  
}
.thob-cutting-slip-bottom-container {
  margin: 1% 0;
}
.thob-cutting-slip-bottom-first-container {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.thob-cutting-slip-bottom-first-left-container {
  border: 1px solid rgb(146, 142, 142);
  height: 32px;
  padding: 1px;
  width: 65%;
  display: flex;
}
.thob-cutting-slip-bottom-first-left-container p {
  border-right: 1px solid rgb(146, 142, 142);
  width: 32%;
  height: 100%;
  margin: 0;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}
.IQBAL-P {
  border-left: 1px solid rgb(146, 142, 142);
  margin: 0 0 0 18px !important;
}
.empty-p {
  border-left: 1px solid rgb(146, 142, 142);
  margin: 0 0 0 18px !important;
  border-right: none !important ;
}
.thob-cutting-slip-bottom-first-right-container {
  display: flex;
  width: 30%;
  justify-content: space-between;
  height: 32px;
  align-items: center;
}
.thob-cutting-slip-bottom-first-right-container p {
  font-size: 0.9rem;
  font-weight: 600;
}
.thob-cutting-slip-bottom-first-right-container input {
  height: 32px;
  width: 70%;
}
.thob-cutting-slip-bottom-second-container {
  display: flex;
  border: 1px solid rgb(146, 142, 142);
  /* margin-top: 1%; */
  height:32px;
}
.thob-cutting-slip-bottom-second-container P {
  border-right: 1px solid rgb(146, 142, 142);
  height: 100%;
  width: 25%;
  margin: 0;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}
.left-border-p-thob-cutting-slip {
  border-left: 1px solid rgb(146, 142, 142);
  margin: 0 0 0 18px !important;
}
.left-border-p-thob-cutting-slip-last {
  border-left: 1px solid rgb(146, 142, 142);
  margin: 0 0 0 18px !important;
  border-right: none !important ;
}


/***** thob cutting slip 1 css *****/
.main-heading-thob-cutting-slip1{
  font-weight: 800;
  font-size: 1.2rem;
}
.thob1-cutting-slip-top-second-content-left{
  width: 60%;
  /* margin-top: 1%; */
}
.thob1-cutting-slip-top-second-content-left img{
  width:21%;
  height:21%;
}
.thob1-cutting-slip-top-second-content-left p{
  font-weight: 800;
  font-size: 0.9rem;
  margin:0;
}
.thob1-cutting-slip-top-second-content-right{
  width: 35%;
  /* margin-top: 1%; */
}
.thob1-cutting-slip-top-second-content-right div{
  height:26%;
}
.thob1-cutting-slip-top-second-content-right p{
  font-weight: 800;
  font-size: 0.9rem;
  margin:1% 0;
}
.thob1-cutting-slip-border{
  border: 2px solid #b5b5b5;
  margin: 0;
}
.thob1-cutting-slip-middle-top-container{
  display: flex;
  width:40%;
  justify-content: space-between;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin: 0;
}
.thob1-cutting-slip-middle-top-second-container{
  display: flex;
  width: 95%;
  justify-content: space-evenly;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-second-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0;
}
.thob1-cutting-slip-middle-top-third-container{
  display:flex;
  width: 98%;
  justify-content: space-between;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-third-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0;
}
.thob1-cutting-slip-middle-top-four-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-four-container P{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:1% 0; 
}
.thob1-cutting-slip-middle-top-five-container{
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-five-left-container{
  display: flex;
  width: 56%;
  justify-content: space-between;
 
}

.thob1-cutting-slip-middle-top-five-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0; 
}
.thob1-cutting-slip-middle-top-six-container{
  display: flex;
  width: 45%;
  justify-content: space-between;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-six-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0; 
}
.thob1-cutting-slip-middle-top-seven-container{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.thob1-cutting-slip-middle-top-seven-container p{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0; 
}
.thob1-cutting-slip-middle-top-eight-container{
  display: flex;
  width:100%;
  justify-content: space-between;
  margin-top:4px;
}
.thob1-cutting-slip-middle-top-eight-container P{
  font-size: 0.8rem;
  /* font-weight: 700; */
  margin:0; 
}
.thob1-cutting-slip-middle-top-nine-container p{
  font-size: 0.9rem;
  font-weight: 700;
  margin:0;
}
.thob1-cutting-slip-middle-top-nine-container span{
  border-bottom: 1px solid #a3a0a0;
  
}

.thob1-cuttinG-slip-single-border-div{
    border: 1px solid #000;
    display: flex;
    /* justify-content: space-between; */
    width: 38%;
    border-radius: 5px;
    height:100%;
    align-items: center;
    padding: 4px;
}
.thob1-cutting-slip-single-left-p{
  width: 66px;
}
.thob1-marsize{
  width: 70px !important;
}
.thob1-patcanvas{
  width:72px !important;
}
.thob1-pc{
  width: 40px !important;
}
.button-type{
  width:84px !important;
}