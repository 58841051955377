.add-shift-container{
    background-color: #fff;
    padding:6% 1% 1% 1%;
    border-radius: 8px;
    margin: 1% 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.add-shift-radio-btn-container{
    display: flex;
    width: 60%;
    justify-content: space-between;
}
.add-shift-single-radio-btn{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-shift-single-radio-btn input{
   accent-color: #000;
   cursor: pointer;
   margin: 0;
}
.add-shift-single-radio-btn label{
    font-size:1rem;
    margin-left: 6%;
    cursor: pointer;
}
.add-shift-container .add-shift-button-container{
    margin: 6% 0 1% 0;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-shift-container .add-shift-button-container button{
    width: 15%;

}
.add-shift-denomination-checkbox{
    position: absolute;
    right: 3%;
    bottom:6%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-shift-denomination-checkbox input{
    width: 16px;
    height: 16px;
    margin: 0;
    accent-color: #000;
    cursor: pointer;
}
.add-shift-denomination-checkbox label{
    font-size: 1rem;
    font-weight: 600;
    margin-left:4%;
    cursor: pointer;
}

/* auto shift container */
.auto-shift-dialog-container{
    width: 38vw;
    padding: 6%;
}
.auto-shift-dialog-container h3{
    margin: 0;
}
.auto-shift-outlet-input{
    width: 98%;
    margin: 4% 0 !important;
}
.auto-shift-input-container{
    display: flex;
    flex-wrap: wrap;
}
.auto-shift-input-container .date-time-input{
    width: 48%;
}
.auto-shift-input-container .auto-shift-suffix-text{
    color: red;
    font-size: 0.7rem;
    text-align: right;
    margin: 1% 0;
}
.auto-shift-working-days-container{
    display: flex;
    flex-direction: column;
    margin: 1% 0;
}
.auto-shift-single-working-days-container{
   display: flex;
   align-items: center;
   margin: 1%;
}
.auto-shift-single-working-days-container input{
    margin: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: blue;
}
.auto-shift-single-working-days-container label{
    margin-left: 3%;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}
.auto-shift-single-working-days-container input[type="checkbox"]:checked+label{
    color: blue;
}
.auto-shift-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% 0;
}
.auto-shift-button-container button{
    width: 23%;
}


.manual-shift-container{
    width: 35vw;
}
.manual-shift-btn-container{
    margin: 4% 0 0 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.manual-shift-btn-container button{
    width:30% !important;
}
.manual-shift-top-container{
    display: flex;
    justify-content: space-between;
}
.manual-shift-top-container .close-icon-manual-shift{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 1px solid red;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.manual-shift-top-container .close-icon-manual-shift .close-icon{
    font-size: 1.1rem !important;
}

/* manual shift add employee css */
.manual-shift-select-employee-container{
    width: 30vw;
    padding:4% 6%;
}
.manual-shift-select-employee-container .label-search-box-container{
    display: flex;
    justify-content: space-between;
    margin: 2% 0 8% 0;
    position: relative;
}
.manual-shift-select-employee-container .label-search-box-container h3{
    margin: 0;
}
.manual-shift-select-employee-container .label-search-box-container input{
    border-radius: 4px;
    border:1px solid rgb(167, 164, 164);
    padding: 1%;
}
.search-icon-manual-shift{
    position: absolute;
    right: 1%;
    top: 4px;
    font-size: 1.3rem !important;
    cursor: pointer;
    color: rgb(173, 171, 171);

}

.manual-shift-select-employee-container .employee-checkbox-container{
    display: flex;
    margin: 4% 4%;
    align-items: center;
}
.manual-shift-select-employee-container .employee-checkbox-container input[type="checkbox"]{
    accent-color: black;
    width: 18px;
    height:18px;
    cursor: pointer;
}
.manual-shift-select-employee-container .employee-checkbox-container label{
    font-size:1rem;
    margin-left: 4%;
    cursor: pointer;
}
.manual-shift-select-employee-container .manual-shift-select-employee-dialog{
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 10% 0 4% 0;
}
.manual-shift-select-employee-container .manual-shift-select-employee-dialog button{
    width: 23%;
}
