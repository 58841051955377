.purchase-wo-po-parent-container .first-row {
  display: flex;
}
.purchase-wo-po-parent-container .first-row .fr-left-container {
  background-color: #fff;
  padding: 1%;
  width: 49%;
  margin: 1% 1% 1% 0;
}
.purchase-wo-po-parent-container .first-row .fr-left-form-container {
  background-color: #fff;
  padding: 1%;
  display: flex;
  flex-wrap: wrap;
}
.fr-left-form-container .pWopO-input,
.fr-right-form-container .pWopO-input {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.pWopO-input{
  position:relative;
}
.fr-left-form-container .pWopO-input .add-supplier-btn {
  background-color: transparent;
  color: #1100ff;
  border: none;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-end;
  margin: 8px 0;
}

.purchase-wo-po-parent-container .first-row .fr-right-container {
  width: 48%;
  margin: 1% 0 1% 1%;
}
.fr-right-container .fr-right-main-container{
  background-color: #fff;
}
.fr-right-container .fr-right-main-container .fr-right-radio-container{
  display: flex;
  padding: 2% 4% 1% 2%;
  
}
.fr-right-container .fr-right-main-container .fr-right-radio-container .fr-right-radio-single-container{
  margin: 0 2%;
}
.fr-right-container .fr-right-main-container .fr-right-radio-container .fr-right-radio-single-container input{
  accent-color: black;
  cursor: pointer;
}
.fr-right-container .fr-right-main-container .fr-right-radio-container .fr-right-radio-single-container label{
  font-size: 0.85rem;
  font-weight: 600;
  margin-left: 4px;
  cursor: pointer;
}
.purchase-wo-po-parent-container
  .first-row
  .fr-right-container
  .fr-right-form-container {
  background-color: #fff;
  padding: 1% 4% 10% 4%;
  display: flex;
  flex-wrap: wrap;
}
.purchase-wo-po-parent-container
  .first-row
  .fr-right-container
  .cancel-submit-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
}
.purchase-wo-po-parent-container
  .first-row
  .fr-right-container
  .cancel-submit-container
  .cancel-btn {
  margin-left: 0;
}
.purchase-wo-po-parent-container .second-row {
  background-color: #fff;
  padding: 1%;
}
.purchase-wo-po-parent-container .second-row .bottom-net-amount-container {
  background-color: #a6a7af;
  display: flex;
  align-items: center;
  padding: 1%;
}
.bottom-net-amount-container .percentage {
  width: 8%;
}
.bottom-net-amount-container .net p {
  color: #fff;
}
.bottom-net-amount-container .net input {
  background-color: #fff;
}
.bottom-net-amount-container .amount {
  width: 12%;
}
.bottom-net-amount-container .net-amount {
  width: 15%;
  margin-left: auto;
}
.purchase-wo-po-parent-container .cancel-finish-container {
  display: flex;
  margin-top: 3%;
  padding: 1%;
}

.purchase-wo-po-parent-container .cancel-finish-container .btn-voucher {
  background-color: #f88d00;
  margin-left: auto;
  position: relative;
}
.purchase-wopo-add-product-validation{
    padding: 2% 5%;
    padding-bottom: 0;
    margin: 0%;
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
}