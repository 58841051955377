.purchase-order-main-container {
  background-color: #fff;
  padding-bottom: 1%;
}
.purchase-order-main-container .top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1%;
}
.purchase-order-main-container .top-bar h3 {
  margin-right: auto;
}
.purchase-order-main-container .top-bar .search-box-container {
  background-color: #f2f2f2;
  height: 32px;
  width: 22%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2px 8px;
  position: relative;
  border-radius: 4px;
}
.purchase-order-main-container .top-bar .search-box-container input {
  height: inherit;
  background-color: #f2f2f2;
  border: none;
  font-size: 0.85rem;
  border-radius: 4px;
  padding:4px 0 4px 6px;
  
}
.purchase-order-main-container .top-bar .search-box-container input::placeholder{
  font-size: 0.8rem;
}
.purchase-order-main-container .top-bar .search-box-container .icon-btn {
  border-radius: 4px;
  height: inherit;
}
.purchase-order-main-container .top-bar .search-box-container .icon-btn .search-icon{
    font-size: 18px;
}
.purchase-order-main-container .top-bar .date-container {
  background-color: #f1f2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border-radius: 4px;
  height: 40px;
  width: fit-content;
}
.grn-status-completed{
  background-color:#00a759;
  color: #fff;
  box-shadow: 0 0 4px #93f0c4;
  margin: auto 0;
  padding:6px 8px;
  border-radius: 4px;
}
.grn-status-draft{
  background-color:#ecc442;
  color: #fff;
  box-shadow: 0 0 4px #eed37c;
  margin: auto 0;
  padding:6px 26px;
  border-radius:4px;
}



/** Single View Dialog  */
.po-single-view-parent-container{
  width: 60vw;
  padding: 2% 4%;
}
.po-single-view-parent-container .top-bar p{
  font-weight: 600;
  font-size: 1.2rem;
}
.po-single-view-parent-container hr{
  border: none;
  border-bottom: 1px solid #000;
}

.po-single-view-parent-container .form-1-container{
  margin: 3% 0;
  display: flex;
  flex-wrap: wrap;
}
.form-1-container .po-input{
  width: 30%;
}
.po-single-view-table-container table tbody tr td {
  height: 36px;
}

.po-single-view-parent-container .cancel-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
}