.rewards-add-rewards-ctn{
    width: 65%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2% 3%;
}
.rewards-add-rewards-ctn-top-menu{
    display: flex;
    width: 100%;
    box-shadow: 0 0 4px #eee;
}

.rewards-add-rewards-ctn-body{
    padding: 2% 0;
}
.rewards-add-rewards-ctn-body-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.rewards-add-rewards-ctn-body-section-ctn{
    display: flex;
    flex-direction: column;
    width: 47%;
}
.add-reward-product-input{
    margin-top: 4px !important;
}
.add-reward-type-radio-container{
    display: flex;
    align-items: center;
}
.add-reward-type-radio-container .radio{

}

.rewards-add-rewards-ctn-body-section-ctn .global-single-input{
    width: 98%;
    /* margin: 6% 0;
    margin-top: 9%; */
}
.rewards-add-rewards-ctn-body-section-right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rewards-add-rewards-ctn-body-section-right .global-single-input{
    width: 48%;
}
.rewards-add-rewards-ctn-body-section-right .global-single-input input{
    border-radius: 0;
}
.rewards-add-rewards-ctn-body-button{
    width: 100%;
    text-align: center;
    margin: 1% 0;
}
.add-reward-amount-input{
    display: flex;
}