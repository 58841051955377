.add-outlet-container {
  width: 62vw;
  padding: 2% 4%;
}
.add-outlet-container h3 {
  margin: 0;
}
.add-outlet-input-container {
  display: flex;
  flex-wrap: wrap;
  margin: 3% 0;
}
.add-outlet-input {
  width: 23%;
  margin: 1% 2% 2% 0;
}
.add-outlet-input p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
}
.add-outlet-input input {
  width: 100%;
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0 2%;
  font-size: 0.9rem;
}
.add-outlet-input input:hover {
  border-color: #000;
}
.add-outlet-input div.MuiOutlinedInput-root {
  width: 100%;
  height: 40px;
  background-color: #fff !important;
}
.add-outlet-input
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 8px !important;
}
.add-outlet-input textarea {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 4px 2%;
  font-size: 0.9rem;
  width: 100%;
}
.add-outlet-input textarea:focus {
  outline: none;
}
.add-outlet-input .MuiAutocomplete-root .MuiAutocomplete-input{
  border: none;
}
.store-code {
  width: 10% !important;
}
.add-outlet-add-button {
  margin: 3% 2% 0 auto;
  height: 44px;
  width: 9%;
  background-color: #04af37;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.add-outlet-add-button:hover {
  background-color: #06cc41;
}
.add-outlet-table tbody tr td {
  background-color: #fff !important;
}
.add-outlet-button-container {
  display: flex;
  justify-content: center;
  margin: 4% 0;
}
.location-container {
  display: flex;
  flex-direction: column;
}
.add-location-text {
  display: flex;
  justify-content: flex-end;
  font-size: 0.7rem !important;
  cursor: pointer;
  color: #0900ff;
  margin: 2% 0 !important;
}
.address-arabic {
  margin: 0 0 0 auto !important;
}
.branch-image-cell img{
  width: 50px;
  border-radius: 4px;
}



/* add location css */
.add-outlet-add-location-container {
  width: 27vw;
  padding: 4%;
}
.add-outlet-add-location-container p {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}
.add-outlet-add-location-container .add-location-top-container {
  display: flex;
  justify-content: space-between;
  margin: 2% 0;
}
.add-outlet-add-location-container
  .add-location-top-container
  .add-location-close-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid red;
  color: red;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-location-content-top-container {
  background-color: #eee;
  padding: 2%;
  margin: 3% 0;
  display: flex;
  justify-content: space-between;
}
.add-location-content-top-container input {
  height: 40px;
  width: 84%;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 0 2%;
  font-size: 1rem;
}
.add-location-content-top-container button {
  background-color: #000;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.add-location-content-top-container button:hover {
  background-color: rgb(77, 76, 76);
}
.add-location-content-main-second-container {
  background-color: #eee;
  padding: 2%;
  margin: 6% 0;
}
.add-location-content-second-container {
  display: flex;
  position: relative;
}

.add-location-content-second-container input {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 0 2%;
  font-size: 1rem;
}
.add-location-content-second-container button {
  position: absolute;
  right: 4%;
  bottom: 11%;
  background-color: #000;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-location-list-items {
  display: flex;
  justify-content: space-between;
  margin: 4%;
  align-items: center;
}
.add-location-list-items p {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
