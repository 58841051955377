.purchase-return-container{
    background-color: #fff;
    padding:2% 3%;
    margin: 1% 0;
}
.purchase-return-container h3{
    margin: 0;
}
.purchase-return-top-container{
    display: flex;
    justify-content: space-between;
}
.purchase-return-btn{
    /* margin: auto 1%; */
    /* position: relative;
    top:1%; */
    margin: 4% 1% 0 1%;
}
.return-invoice-top-container{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-return-purchase{
    width: 47% !important;
    margin: 0 !important;
    height:32px;
}
.purchase-return-table{
    padding: 0 !important;
    margin:2% 0 !important;
}
  /* add return dialog  */
.purchase-add-return-dialog-container{
   width:62vw;
   padding:1% 3%;
}
.purchase-return-dialog-top-container{
    display: flex;
    
}
.return-dialog-text{
    color: rgb(0, 0, 255);
}
.purchase-return-dialog-middle-container{
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 4px rgb(197, 190, 190);
    padding: 1%;
    margin: 2% 0;
}
.purchase-return-unit{
   width: 8% !important;
}
.purchase-return-inv-id{
   width: 10% !important;
}
.purchase-return-date{
    width: 13% !important;
}
.purchase-return-prod-name{
    width: 18% !important;
}
.purchase-return-prod-code{
    width: 11% !important;
}
.purchase-return-add-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}
.purchase-return-add-button button{
    background-color: #000;
    color: #fff;
    height:39px;
    width:60%;
    cursor: pointer;
    font-size: 1.4rem;
}

.disabled-p{
    color: rgb(197, 194, 194);
}
.purchase-return-dialog-button-container{
    display: flex;
    justify-content: center;
    margin: 4% 0;
}
.purchase-return-add-product-validation{
    position: absolute;
    color: red;
    font-size: 0.8rem;
    top: -15%;
    font-weight: 600;
}

.purchase-return-status-printing{
    background-color: #cfe4e5 !important;
    color: #2ab5ca !important;
}
.purchase-return-status-booking{
    background-color: #edd6d1 !important;
    color: #fd4818 !important;
}
.purchase-return-status-delivered{
    background-color: #cfd4e6!important;
    color: #0a33fe!important;
}
.purchase-return-status-order-return{
    background-color: #eccaac !important;
    color: #e46c39 !important;
}
.purchase-return-status-delivery-return{
    background-color: #ecacae !important;
    color: #e4394d !important;
}