.grn-report-single-view-container{
    width: 40vw;
    padding: 2%;
}
.grn-report-single-view-container h3{
    margin: 2%;
}
.grn-report-view-input-container{
    display: flex;
    flex-wrap: wrap;
}
.grn-report-input{
    width: 44% !important;
    margin: 2% 3% !important;
}
.grn-report-view-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}
.grn-report-view-btn-container button{
    width: 25%;
}