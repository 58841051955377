.credit-content-container{
    background-color: #fff;
    padding: 1%;
    margin: 1% 0;
}
.credit-content-top-container{
    display: flex;
    justify-content: space-between;
    margin: 0 0 2% 0;
    align-items: center
}
.credit-content-top-container h3{
    margin-left: 3%;
}
.credit-content-top-right-container{
    /* display: flex; */
    width:30%;
    align-items: flex-end;
    
}
.credit-search-container{
    display: flex;
    position: relative;
    margin: 1%;
    width: 71%;
}
.credit-search-container input{
    border: 1px solid rgb(196, 194, 194);
    height: 40px;
    width: 100%;
    padding: 0 4%;
}
.credit-search-icon{
    background-color: rgb(233 230 230) !important;
    border-radius: 1px !important;
    height: 29px !important;
    width: 30px;
    position: absolute !important;
    right: 2%;
    top: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem !important;

}
.credit-print-icon{
    margin: 1% !important;
    height: 40px;
    width: 60px;
    background-color:#d9ffec !important ;
}
.credit-pay-button{
    background-color: #73d29f;
    border: none;
    cursor: pointer;
    padding: 8% 22%;
    width: fit-content;
    font-size: 0.8rem;
    font-weight: 600;

}

/* wallet  style */
.wallet-table-cell{
    display: flex;
    justify-content:center;
    align-items: center;
    color: #00c965;
}
.wallet-table-cell p{
    margin: 2% 0 2% 6%;
}

.credit-container-top{
    display: flex;
    background-color: #fff;
    padding: 1%;
    border-radius: 4px;
    width: 100%;
}
.credit-container-top-left{
    /* flex: 50; */
    width: 50%;
    border-right: 1px solid rgb(160, 160, 160);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
}
.credit-container-top-right{
    /* flex: 50; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 2% 0;
}
.credit-container-top-right h4{
    margin: 2% 14%;
}
.credit-container-top-right p{
    margin: 2% 14%;
    font-size: 2rem;
    font-weight: 600;
}
.credit-container-top-right .credit-container-top-right-amount-container{
    display: flex;
    margin: 3% 14%;
    justify-content: space-between;
    align-items: flex-end;
    height: 80px;
    width: 73%;
}
.credit-single-amount-container{
    display: flex;
    align-items: center;
    background-color: #fed8d8;
    justify-content: space-between;
    height: 40px;
    width: 47%;
    padding: 0 5%;
    border-radius: 4px;
    
}
.credit-single-amount-container p{
    font-size:1rem;
    font-weight:600;
    color:#eb0000 ;
    margin: 1%;
}
.credit-container-top-right-amount-container .second-amt-container{
    background-color: #d9ffec !important;
}
.credit-container-top-right-amount-container .second-amt-container p{
    color: #00c965;
}
.credit-container-top-left .carousel .slider-wrapper{
  width: 75% !important;
}
.credit-container-top-left .carousel .control-next.control-arrow:before{
    border-left: 8px solid #000;
}
.credit-container-top-left .carousel .control-prev.control-arrow:before{
    border-right: 8px solid #000;   
}
.credit-container-top-left .carousel-root{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.credit-container-top-left .carousel.carousel-slider{
    width: 500px !important;
}

/* wallet summary design */
.wallet-summary-container{
    display: flex;
}
.wallet-summary-container input{
    cursor: pointer;
    accent-color:#04ab04;
}
.wallet-summary-container label{
    font-size: 0.8rem;
    color: #00c965;
    cursor: pointer;
}

.wallet-summary-print-bottom-conntainer{
    display: flex;
    margin: 1% 6%;
}
