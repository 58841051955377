.employee-creation-input-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2em;
  margin-block-start: 5% !important;
}

.employee-creation .product-master-image {
  padding: 0 4.5% !important;
  margin-block-end: 2em;
}

.button-container {
  display: flex;
  justify-content: space-between;
  /* margin-block-end: 2em; */
}
.button-container .button-box {
  display: flex;
  gap: 10px;
}
.button-container .button-box .cancel-btn {
  background-color: #fff;
  border: 1px solid #0011f8;
  color: #0011f8;
}
.button-container .button-box .submit-btn {
  background-color: #0011f8;
  border: none;
  color: #fff;
}
-input-container :nth-child(7) {
  align-self: flex-start;
}

.tab-two.accounting-voucher-menu-sections {
  border-bottom: 2px solid #e6e6e6;
}
.accounting-voucher-menu-sections h3 {
  /* margin: "2% 0%" !important */
}
.employee-creation .employee-creation-input-container {
  margin-block-start: 0;
}

.employee-creation .tab-three .button-container {
  margin-block-start: 10em;
}

.employee-creation .employee-creation-input-container-tab-two {
  display: flex;
  justify-content: space-around;
  gap: 2em;
  margin-block-start: 0em;
}

.employee-group-creation {
  padding: 2em;
  width: 100%;
}
.add-employee-group-container {
  margin-block-start: 2em;
}

.add-employee-group-container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-employee-group-container .global-single-input {
  margin: 0;
}

.payroll-voucher-container .global-single-input {
  margin: 0;
}

.payroll-salary-information > div {
  width: 50%;
}

.payroll-salary-information .global-single-input {
  margin: 0;
}

.payroll-salary-information {
  gap: 2% !important;
  flex-wrap: nowrap !important;
}

.payroll-salary-information .headings {
  margin: 1% 2%;
}
.payroll-salary-information .salary-input-box {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
}

.payroll-salary-information .global-single-input {
  width: 100%;
}

.payroll-salary-information .accounting-voucher-menu > div {
  gap: 10%;
}

.payroll-salary-information .education-allowance {
  display: flex;
  align-items: center;
  margin: 2em 0;
}
.payroll-salary-information .education-allowance h3 {
  flex: 200% 1 !important;
}

.payroll-salary-information .total-earnings {
  display: flex;
  padding: 1% 5%;
  background-color: #edeef0;
  align-items: center;
}
.payroll-salary-information .global-white-bg-container {
  justify-content: space-between !important;
  display: flex;
  flex-direction: column;
}

.total-payable-salary {
  width: 100%;
}
.total-payable-salary .global-white-bg-container {
  padding: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.total-payable-salary .global-white-bg-container > div {
  background-color: #edeef0;
  padding: 1%;
  width: 80%;
}

.total-payable-salary
  .global-white-bg-container
  > div
  .total-payable-salary-input-container {
  width: 100%;
}

.total-payable-salary-input-container .global-single-input {
  width: 40%;
}
.total-payable-salary-input-container .global-single-input input {
  background-color: #fff;
}
.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-row h3,
.input-row h5 {
  margin: 0;
  padding-right: 30px;
  width: 100%;
  text-align: end;
}
.payroll-voucher-button .button-container {
  width: 100%;
}
.payroll-voucher-button .button-container > button {
  background-color: red;
  font-weight: 600;
  width: 15%;
}
.payroll-voucher-button .button-container > div {
  width: 50%;
}
.payroll-voucher-button .button-container > div :nth-child(1) {
  background-color: #039792;
  border: none;
  color: #fff;
  width: 50%;
}
.payroll-voucher-button .button-container > div :nth-child(2) {
  background-color: #0800ff;
  width: 60%;
}

.payment-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}
.payment-details-container h3 {
  align-self: flex-start;
}
.payment-details-input-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 25vh;
}

.payment-details-input-container .global-single-input {
  width: 48%;
  margin: 0;
}
