.sales-old-stock h3{
  margin: 1% 2%;
}
.sales-old-stock-view{
    padding: 4%;
    width: 70vw;
}
.sales-old-stock-view-top{
    box-shadow: 0 0 8px 2px #eee;
    padding: 2%;
}
.sales-old-stock-view-top h3{
    margin: 1% 0;
}
.sales-old-stock-view-top-input-container{
    display: flex;
}
.old-stock-table{
    padding: 0 !important;
    margin: 3% 0 !important;
}



/* quick bill css */
.quick-bill-input-container{
    display: flex;
    flex-wrap: wrap;
}
.quick-bill-input-checkbox{
    display: flex;
    justify-content: space-between;
}
.quick-bill-input-checkbox input{
    width: 15px;
    height: 15px;
    accent-color: #000;
}
.sales-quick-bill-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}
.sales-quick-bill-button{
    margin: 1%;
    width: 17%;
    height: 40px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}
.quick-bill-without-payment{
    background-color: transparent;
    border: 1px solid rgb(180, 179, 179);
}
.quick-bill-without-payment:hover{
    background-color: #eee;
}
.quick-bill-payment{
    background-color: #6ee98b;
    border: none;
    color: #fff;
}
.quick-bill-payment:hover{
    background-color: #3dce5f;
}