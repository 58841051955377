
.stock-out-product-container{
    background-color: #fff;
    padding: 1%;
}
.stock-out-product-top-container{
    display: flex;
    justify-content: space-between;
}
.stock-out-product-button-container{
    display: flex;
    width: 60%;
    align-items: center;
}
.add-stock-out-button{
    background-color: #ff9100;
    border: none;
    color: #fff;
    width: 50%;
    height: 40px;
    margin: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}
.add-purchase-button{
    width: 50% !important;
    font-size: 0.9rem !important;
}
.status-text-stock-out{
    background-color:#f2d0a2;
    color: #fe9b31;
    width: fit-content;
    margin: 0 auto;
    border-radius:16px;
    padding:1% 10%;
    font-size: 0.8rem;
    font-weight: 500;
}
.status-text-stock-out-pending{
    background-color:#b8deb1;
    color: #00bb0f;
    width: fit-content;
    margin: 0 auto;
    border-radius:16px;
    padding:1% 10%;
    font-size: 0.8rem;
    font-weight: 500;
}
.status-text-stock-out-reject{
    background-color:#deb1b1;
    color: #c60000;
    width: fit-content;
    margin: 0 auto;
    border-radius:16px;
    padding:1% 10%;
    font-size: 0.8rem;
    font-weight: 500;
}
.stock-out-tr{
    height:62px !important;
}
.add-stock-out-dialog{
    width: 45vw;
    padding:2% 6%;
}
.add-stock-out-dialog h3{
    margin: 2% ;
}
.add-stock-out{
    width:48% !important;
}
.add-stock-out-input-container{
    display: flex;
    flex-wrap: wrap;
}
.stock-out-button-container{
    display: flex;
    justify-content: center;
    margin: 4% 0;
}
.stock-out-product-container {
  background-color: #fff;
  padding: 1%;
}
.stock-out-product-top-container {
  display: flex;
  justify-content: space-between;
}

.add-stock-out-button {
  background-color: #ff9100;
  border: none;
  color: #fff;
  width: 50%;
  height: 40px;
  margin: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}
.add-purchase-button {
  width: 50% !important;
  font-size: 0.9rem !important;
}
.status-text-stock-out {
  background-color: #f2d0a2;
  color: #fe9b31;
  width: fit-content;
  margin: 0 auto;
  border-radius: 16px;
  padding: 1% 10%;
  font-size: 0.8rem;
  font-weight: 500;
}
.stock-out-tr {
  height: 62px !important;
}
.add-stock-out-dialog {
  width: 45vw;
  padding: 2% 6%;
}
.add-stock-out-dialog h3 {
  margin: 2%;
}
.add-stock-out {
  width: 48% !important;
}
.add-stock-out-input-container {
  display: flex;
  flex-wrap: wrap;
}
.stock-out-button-container {
  display: flex;
  justify-content: center;
  margin: 4% 0;

}

/* approve stock out product */
.approve-stock-container {
  background-color: #fff;
  padding: 1%;
}
.approve-stock-top-container {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
}
.approve-stock-status {
  display: flex;
  margin: 0 auto;
  width: 72%;
  justify-content: space-between;
}
.approve-stock-accept {
  width: 46%;
  background-color: #0019ff;
  padding: 1% 2%;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
}
.approve-stock-reject {
  background-color: #f50303 !important;
}
.sweet-alert-cancel-button {
  border: 1px solid #000 !important;
  color: #000 !important;
  background-color: transparent !important;
  width: 44% !important;
}
.sweet-alert-submit-button {
  border: none !important;
  width: 44% !important;
}
.sweet-alert-submit-button-blue {
  border: none !important;
  width: 44% !important;

}
.custom-swal-popup{

}
.custom-swal-title{
  color: red;
  font-size: 0.9rem;
}
.approve-action-taken{
    background-color: #0019ff;
    padding: 1% 2%;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
}

.validate-stock-out-products{
    color:red;
    font-size: 0.8rem;
    text-align: end;
}