.add-thob-model-dialog-container{
    width:47vw;
    padding: 4%;
}
.add-thob-model-dialog-container h3{
    margin: 0;
}
.add-thob-model-dialog-input-container{
    display: flex;
    align-items: center;
    margin: 4% 0;
}
.add-thob-model-dialog-input-container .from-length{
   width:15%;
}
.add-thob-model-dialog-input-container button{
    background-color: #000;
    color: #fff;
    width:8%;
    height: 40px;
    margin: 5% 1% 1% 5%;
    font-size: 1.5rem;
    cursor: pointer;
}