.pos-reader-preview-container {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0.5em;
}
.pos-reader-wrapper {
  display: flex;
  background-color: white;
  height: 93vh;
  overflow: hidden;
  padding: 1em;
}
.pos-reader-wrapper > div {
  flex: 1;
}
.r-download-container {
  background-color: #ebf2ff;
  padding: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}
.r-amount-h {
  font-size: 1em;
  font-weight: 700;
  display: block;
  text-align: center;
}
.r-download-container > :nth-child(2) {
  display: flex;
  gap: 0.5em;
}
.r-download-container :nth-child(2) > button {
  flex: 1;
  border: 1px solid transparent;
  padding: 1em;
  background-color: white;
  font-weight: 600;
  display: inline-flex;
  gap: 0.5em;
  width: 10em;
  cursor: pointer;
  justify-content: center;
  margin-top: 1em;
  transition: all 0.3s ease-in-out;
}
.r-download-container :nth-child(2) > button:hover {
  border: 1px solid black;
}
.r-btn-download {
  color: #1f532f;
}
.r-btn-print {
  color: #684a02;
}

.r-list-sale-product {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;
  height: 70vh;
  overflow-y: auto;
}
.r-product-container {
  padding: 1em;
  border: 1px solid #ababab;
  border-radius: 0.3em;
  margin: 5px;
  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.r-product-container:hover {
  border: 1px solid black;
}
.r-p-img {
  min-width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.r-p-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.r-content-head {
  display: block;
  font-size: 1em;
  font-weight: 600;
}
.r-content-body {
  font-size: 0.8em;
  color: #ababab;
}

.r-preview-pos-receipt {
  padding: 1em;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}

/* scroll bar  */
.pos-reader-preview-container ::-webkit-scrollbar {
  width: 8px;
  border: 1px solid #ccc;
  padding-left: 5px;
}

.pos-reader-preview-container ::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #9e90ff, #38a3ff); /* Corrected syntax */
  /* border-radius: 10px; */
  transition: all 0.5s ease-in-out;
}

.pos-reader-preview-container ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #877dff, #2c90ff); /* Corrected syntax */
}

.pos-reader-preview-container ::-webkit-scrollbar-track {
  background-color: transparent;
}

@media only screen and (max-width: 740px) {
  .pos-reader-wrapper {
    flex-direction: column;
    height: auto;
  }
  .r-list-sale-product {
    height: auto;
  }
  .r-preview-pos-receipt {
    display: none;
  }
}
