.bank-reconciliation {
  padding: 1%;
}
.bank-reconciliation .select-bank-name-container {
  background-color: white;
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  margin-block-end: 0.5em;
}

.bank-name-submit-btn {
  margin-top: 1.4em;
}
.bank-name-submit-btn button {
  width: 10em;
  height: 3em;
  background-color: #005dff;
  color: white;
  border:none;
  border-radius: 5px;


}
.headline-import-file{
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
    padding-inline: 37px;
    padding-bottom: 8px;
    align-items: center;
}

.headline-import-file p{
  color: #ed8054;
    font-weight: 700;
    margin: 0;
}
.uploading-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: dashed 1px #a5a2a2;
  width: 169px;
}
.headline-import-file-side-div{
  width: 269px;
    display: flex;
    justify-content: space-between;
}
.uploading-div p{
  color: black;
  font-weight: 200;
  margin: 0;
  font-size: smaller;
  /* padding-inline: 13px; */
  padding: 0px 64px 0px 20px;
}
.uploading-div button{
  border: none;
    background-color: #2e2ef9;
    height: 34px;
}
.submit-btn{
  width: 71px;
  color: white;
    background-color: #9aa2f3;
    border: none;
    border-radius: 3px;}
    .image-div{
      align-self: center;
      /* padding-top: 185px; */
      padding: 60px 0;
      
    }
    .image-div img{
      height: 156px;
    }
    .image-div p{
      text-align: -webkit-center;
    color: #cdcccc;
    }
    .image-head-name{
      padding-inline: 37px;
      font-weight: 600;
    }
    .drop-down-div{
      background-color: #edf2f9;
      padding: 8px 0px 0px 0;
      display:flex ;
    }

    /* Add this to your global styles or stylesheet */
.scroll-div::-webkit-scrollbar {
  width: 0 !important; /* Hide scrollbar width */
}

.scroll-div::-webkit-scrollbar-thumb {
  background-color: transparent !important; /* Hide thumb */
}

.scroll-div::-webkit-scrollbar-track {
  background-color: transparent !important; /* Hide track */
}
.sub-autocomplete{
  border: 1px solid;
  
    margin-top: 7px;
    padding: 3px 0px 1px 11px;
    border-radius: 4px 

}
