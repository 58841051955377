.order-delivery-top-container{
    background-color: #fff;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   
}
.order-delivery-top-container button{
    margin: 0 0 0 auto;
}
.order-delivery-bottom-container{
    background-color: #fff;
    padding: 1%;
    margin: 1% 0 0 0;
}
.order-delivery-bottom-container h3{
    margin: 1%;
}
.delivery-payment-btn{
    background-color: #63ac58 !important;
    margin: 0 0 0 12% !important;
}
.delivery-payment-btn:hover{
    background-color: #299c17 !important;
}
.delivery-payment-btn:disabled{
    background-color: #a1aaa1 !important;
    cursor: not-allowed;
}
.order-delivery-checkbox{
    accent-color: #000;
}
.delivery-top-input{
    width: 18% !important;
}
.delivery-status-delivered{
    background-color: #b3d68b;
    color: #fff;
    padding: 3px 4px;
    font-size: 0.75rem;
    border-radius: 4px;
}
.delivery-status-pending{
    background-color:  #ee9b5c ;
    color: #fff;
    padding: 3px 8px;
    font-size: 0.75rem;
    border-radius: 4px;
}
.order-delivery-bottom-table-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-delivery-bottom-table-top-right-container{
    width: 60%;
    display: flex;
    align-items: center;
}
.order-delivery-bottom-table-top-right-container .info-icon-btn{
    margin-left: 2%;
}
.order-delivery-bottom-table-top-right-container .info-icon
{
    color: #299c17;
    font-size: 1.26rem;
}
.order-delivery-payment-details-btn{
    width: 45%;
}
.order-delivery-payment-details-btn:disabled{
    background-color: rgb(183, 186, 214);
    cursor: not-allowed;
}
.order-delivery-old-balance-btn{
    background-color: #ef8700;
    width: 45%;
}
.order-delivery-old-balance-btn:disabled,.order-delivery-old-balance-btn:disabled:hover{
    cursor: not-allowed;
    background-color: #e6c8a2;
}

.order-delivery-old-balance-btn:hover{
    background-color: #d47a03;
}

.delivery-expand-table-th-container th{
  font-size: 0.8rem !important;
  font-weight: 600;
  height: 45px !important;
}
.delivery-expand-table-td-container td{
    background-color:#f3f3f5 !important;
    height: 50px !important;
   
}
.delivery-expand-table-arrow-icon{
    cursor: pointer;
    border-radius: 1% !important;
    background-color: rgb(221 219 219) !important;
    height: 20px;
    width: 20px;
}
 /* payment details */
.order-delivery-payment-details-container{
   padding: 2%;
   width: 60vw;
   display: flex;
   flex-direction: column;
}
.order-delivery-payment-details-table-container{
    border: 1px solid rgb(197, 197, 197);
    padding: 1%;
}
.order-delivery-payment-details-table-container h3{
    margin: 0;
}
.order-delivery-payment-details-amount-container{
    display: flex;
    justify-content: flex-end;
    margin: 2% 0;
}
.order-delivery-payment-details-amount-container p{
    font-size: 0.9rem;
    font-weight: 600;
    width: 20%;
    margin: 0;
    text-align: center;
}
.order-delivery-payment-details-amount-container h4{
    width: 23%;
    text-align: center;
    margin: 0;
}
.order-delivery-payment-details-container .delivery-payment-close-button{
    margin: 2% auto;
    align-self: center;
}
.delivery-table-container{
    max-height: 50vh;
}
/* old balances */
.order-delivery-old-balances-container{
  width: 60vw;
  padding: 1%;
}
.order-delivery-old-balances-container h3{
    margin: 1% 0;
}
.order-delivery-old-balances-input-container{
    border: 1px solid #eee;
    padding: 1%;
    display: flex;
    justify-content: space-around;

}
.order-delivery-old-balances-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}
.old-balances-payment-btn{
    width: 60%;
    height: 24px;
    font-size: 0.9rem;
    background-color: #63ac58;
}
.old-balances-payment-btn:hover{
    background-color: #48963d;
}
.delivery-old-balance-table{
    max-height: 62vh !important;
}
.deliver-btn:disabled{
    background-color: #646464 !important;
    cursor: not-allowed;
}