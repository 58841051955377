.grn-parent-container .top-bar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.grn-parent-container .top-bar .grn-input {
    width: 20%;
}
.grn-parent-container .top-bar .grn-input input{
background-color: #fff;
box-shadow: 0 0 6px rgb(202, 202, 202);
}
.auto-complete-input input{
    background-color: #fff !important;
}

.grn-parent-container .top-bar .drop-down{
    margin-right: auto;
}
.grn-parent-container .top-bar .drop-down div.MuiOutlinedInput-root {
    background-color: #fff;
    box-shadow: 0 0 6px rgb(202, 202, 202);
}
.grn-parent-container .first-row-container{
    display: flex;
    justify-content: space-between;
}
.grn-parent-container .first-row-container .fr-left-container{
    width: 49%;
    padding: 1%;
}
.first-row-container .fr-left-container .fr-left-form-container{
    background-color: #fff;
    padding: 2% 2% 20%;
    display: flex;
    flex-wrap: wrap;
}
.fr-left-container .fr-left-form-container .grn-input{
    width: 48%;
}
.first-row-container .fr-left-container .fr-left-buttons-container{
    display: flex;
    align-items: center;
    margin-top: 1%;
}
.fr-left-buttons-container .received-all-btn{
    margin-left: auto;
    margin-right: 0;
}
.fr-left-buttons-container .cancel-btn{
    margin-left: 0;
}
.first-row-container .fr-right-container{
    width: 49%;
    padding: 1%;
    background-color: #fff;
    margin: 1% 1% 1.5%;
    position: relative;
}
.fr-right-container .grn-fr-right-btn-container{
    background-color: #000;
    padding: 1%;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
}
.fr-right-container .grn-fr-right-btn-container button{
    height: 28px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.fr-right-container .grn-fr-right-btn-container button:hover{
    background-color: #fff;
}
.grn-parent-container .second-row-container{
    padding: 1%;
    margin: 1%;
    background-color: #fff;
    position: relative;
}
.second-row-container .grn-received-products-table{
    margin-bottom: 4%;
}
.second-row-container .net-amount-container{
    background-color: #a6a7af;
    padding: 1%;
    width: 100%;

}

.net-amount-container .grn-net-amount-inputs{
    margin-left: auto;
}

.second-row-container .net-amount-container .grn-net-amount-inputs p{
color: #fff;
}
.second-row-container .net-amount-container .grn-net-amount-inputs input{
    width: 60%;
    background-color: #fff;
}
.second-row-container .bottom-button-container{
    margin: 1% 0;
    position: relative;
}
.second-row-container .bottom-button-container button{
    width:15%;
}
.second-row-container .bottom-button-container .draft-btn{
    background-color: #f1840a;
    position: absolute;
    left: 40%;

}
.second-row-container .bottom-button-container .voucher-btn{
    background-color: #009f47;
    position: absolute;
    right: 0;
    width: 20% !important;
}

/* .... */
.grn-table-input{
    width: 80%;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #000;
    background: #eee;
    text-align: center;
}