.parent-container-voucher{
    padding: 2% 4%;
    width: 36vw;
}
.voucher-main-form-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6%;
}
.voucher-single-input{
    width: 48% !important;
}
.voucher-btn-wrapper{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}
.voucher-pay-btn{
    background-color: #0bc55c !important;
    width: 50% !important;
}
.voucher-pay-btn:hover{
    background-color: #05b14f !important;
}
.voucher-hr{
    border: none;
    border-bottom: 1px dashed #000;
    margin: 1% 0 4%;
}
.payment-fields-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6%;
   
}
.cancel-submit-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.voucher-action-btn{
    width: 25% !important;
    margin: 4% 2% !important;
}