.sales-customer-left{
    display: flex;
    flex-direction: column;
}

.sales-customer-left .single-container{
    display: flex;
    justify-content: space-between;
}

.autocomplete-inside-plusbtn{

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    top: 10px;
    right: 32px;
    width:20px;
    height: 20px;
}

.payment-terms-dialog-container{
    /* height: 20vh; */
    width: 25vw;
    padding: 7% 5%;

    display: flex;
    flex-direction: column;
}
.paymentterms-btns button{
    min-width: 60px;
}

.payment-terms-dialog-container .paymentterms-btns{
 
        display: flex;
        align-items: center;
        justify-content: center;
}

.paymentterms-cancelbtn{
    width: 16%;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 1%;
    border-radius: 4px;
    border: none;

}