/* work order top container */
.work-order-main-container {
  position: relative;
  /*top: 0; left: 0; right: 0; bottom: 0;*/
  /* z-index: 0;  */
}
.work-order-top-container {
  background-color: #fff;
  padding: 2%;
  border-radius: 4px;
}
.work-order-top-first-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work-order-top-first-left-container {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.work-order-top-first-left-container h3 {
  margin: 0 2%;
}
.work-order-top-first-right-container {
  width: 34%;
  display: flex;
  justify-content: flex-end;
}
.work-order-cutting-slip-btn {
  background-color: #d46a00 !important;
  width: 60% !important;
}
.work-order-cutting-slip-btn:hover {
  background-color: #e78018 !important;
}
.work-order-add-new-btn {
  width: 52% !important;
}
.work-order-top-radio {
  width: 55% !important;
  display: flex;
  align-items: center;
}
.work-order-top-radio label {
  margin: 0 10% 0 0;
  font-size: 0.9rem !important;
}
.work-order-top-second-container {
  display: flex;
  flex-wrap: wrap;
  margin: 2% 0;
}
.work-order-top-container-input {
  width: 18% !important;
}
.work-order-customer-inp-container{
  width: 25% !important;
}
.work-order-top-third-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work-order-top-third-button-container {
  display: flex;
  width: 25%;
}
.work-order-top-third-button-container button {
  width: 47% !important;
}
.work-order-top-third-radio-container {
  width: 71%;
}
.work-order-dress-type-radio {
  width: 100% !important;
  display: flex;
  align-items: center;

  /* position: relative; */
}
.work-order-dress-type-radio input {
  margin: 0 2% !important;
}
.shirt-suit-radio-container {
  width: 40%;
  margin: 0 2%;
  position: relative;
  display: flex;
}
.shirt-suit-dropdown-container {
  display: block;
  /* margin:-28% 0 0 45%; */
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 8px 6px #eee;
  width: 75%;
  height: fit-content;
  padding: 2% 0 2% 4%;
  z-index: 3;
  border-radius: 4px;
  left: 120px;
  bottom: 0;
  transform: translateY(42%);
}
.hide-radio-dropDown {
  display: none;
}
.shirt-suit-dropdown-container::after {
  content: "";
  position: absolute;
  /* top: 30%; */
  bottom: 30%;
  right: 100%;
  /* margin-top: -25px; */
  border-width: 14px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  filter: drop-shadow(-4px 0 4px #eee);
}

.non-focus {
  margin: 1% 0 1%;
  text-align: left;
  color: #000;
  width: 100%;
  height: 38px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  /* padding: 18px 4px 38px 48px; */
  padding: 9px 4px 28px 48px;
}
.focus {
  color: #000;
  background-color: #ebeefe;
  font-weight: 600;
}
/* work order middle container */
.work-order-middle-container {
  display: flex;
}
.work-order-middle-left-container {
  background-color: #fff;
  padding: 2%;
  width: 50%;
  margin: 0 1% 0 0;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
}
.work-order-middle-right-container {
  background-color: #fff;
  padding: 2%;
  width: 50%;
  margin: 0 0 0 1%;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 55%;
  height: fit-content;
}
.work-order-input {
  width: 48% !important;
}
.work-order-input-group {
  display: flex;
}
.work-order-input-group-first {
  margin: 0 2% 0 0;
}
.work-order-input-group-second {
  margin: 0 0 0 2%;
}

/* work order bottom container */
.work-order-bottom-container {
  background-color: #fff;
  padding: 2%;
  margin: 2% 0;
  border-radius: 4px;
}
.work-order-bottom-container h4 {
  margin: 0;
}
.work-order-bottom-products-container {
  padding: 1%;
  border: 1px solid rgb(177, 175, 175);
  margin: 2% 0;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}
.work-order-bottom-products-container .auto-complete-new div.MuiOutlinedInput-root{
  padding: 0;
  min-height: 30px !important;
}
.work-order-product-input {
  width: 15% !important;
}
.work-order-product-qty {
  width: 10% !important;
  position: relative;
}
.work-order-product-submit-btn {
  width: 12% !important;
  margin:  1% 2px !important;
  align-self: flex-end;
}
.work-order-bottom-second-container {
  display: flex;
  margin: 4% 0;
}
.work-order-bottom-second-left-container {
  border: 1px solid rgb(177, 175, 175);
  width: 45%;
  margin: 0 1% 0 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  max-height: 67vh;
  overflow-y: scroll;
  padding: 0 0 1% 0;
}
.work-order-bottom-second-right-container {
  border: 1px solid rgb(177, 175, 175);
  width: 55%;
  margin: 0 0 0 1%;
  padding: 1%;
}
.work-order-second-right-table-container {
  max-height: 57vh !important;
}
.work-order-action-btn:disabled i{
  color: rgb(164, 164, 164);
}
.work-order-bottom-second-right-container p {
  margin: 4px 8px;
  font-size: 1rem;
  font-weight: 600;
}

.work-order-bottom-second-left-table-container {
  width: 100%;
  border-collapse: collapse;
  max-height: 7vh;
  overflow: scroll;
}
.table-left-th {
  border-top-left-radius: 24px;
}
.table-right-th {
  border-top-right-radius: 24px;
}
.table-left-td {
  /* padding-left:1%; */
}
.table-right-td {
  /* padding-right:1%; */
}
.work-order-bottom-second-left-table-container thead {
  position: sticky;
  top: -6px;
  height: 42px;
  z-index: 2;
}
.work-order-bottom-second-left-table-container thead tr th {
  background-color: #000;
  color: #fff;
  font-size: 0.8rem;
  height: 40px;
  text-align: center;
}
.work-order-bottom-second-left-table-container tbody {
  cursor: n-resize;
  /* padding: 0 8px; */
}
.work-order-bottom-second-left-table-container tbody tr td {
  text-align: center;
  font-size: 0.8rem;
  height: 40px;
  border-bottom: 1px solid rgb(194, 192, 192);
}
.work-order-bottom-second-left-container::-webkit-scrollbar {
  width: 0px;
  background-color: #d3e3ec;
}
.work-order-bottom-second-left-container::-webkit-scrollbar-thumb {
  background-color: #000;
}

.work-order-bottom-second-button-container {
  display: flex;
  justify-content: space-between;
}
.work-order-bottom-second-button-group {
  width: 30%;
  display: flex;
}
.work-order-clear-btn {
  width: 45%;
}
.work-order-payment-btn {
  background-color: #f8a500 !important;
}
.work-order-payment-btn:disabled{
  background-color: #eee !important;
}
.workorder-add-product-validation{
  position: absolute;
  top: -10%;
  font-weight: 600;
  color: red;
}
.work-order-notes-add-button{
  background-color: #000;
    color: #fff;
    border-radius: 2px;
    width: 24px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 31px;
    right: 8px;
    cursor: pointer;
    font-size: 1rem;
}
.work-order-notes-content{
  height:100px;
  overflow-y: auto;
  padding: 2%;
}
.work-order-notes-content p{
  margin: 1px !important;
}
.work-order-notes-content::-webkit-scrollbar{
  width: 4px;
  background-color: #fff;
}
.work-order-notes-content::-webkit-scrollbar-thumb{
  background-color: #c1c1c1;
}

.held-order-btn{
  background-color: #2603d7;
  width: 100% !important;
  font-size: 0.9rem !important;
}
.clear-hold-mode-btn{
  background-color: #b77432;
  font-size: 0.8rem;
}