.shift-add-denomination-dialog{
    width: 50vw;
    padding:3%;
    display: flex;
    flex-direction: column;
}
.shift-add-denomination-dialog h4{
    margin: 0;
}
.add-denomination-input-container{
    display: flex;
    margin: 3% 0;
    align-items: center;
}
.add-denomination-input-container button{
    background-color: #000;
    height: 40px;
    width: 8%;
    color: #fff;
    margin: 4% 1% 0 1%;
    font-size: 1.3rem;
    cursor: pointer;
}
.add-denomination-input-container .denomination-input{
    width: 30% !important;
}
.shift-add-denomination-dialog .denomination-btn{
    align-self: center;
    width: 25%;
    margin: 2% 1% !important;
}
.denomination-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-denomination-input-container-grand-total{
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-end; */
}
.add-denomination-input-container-grand-total p{
    font-weight: 600;
    /* margin: 0; */
    margin-right: 10px;
}
.add-denomination-input-container-grand-total .global-single-input{
    width: 16%;
}