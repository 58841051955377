.add-po-main-container{
    background-color: #fff;
    padding: 1% 2%;
    margin: 1% 0;
}
.add-po-main-container hr{
    border: none;
    border-bottom: 1px solid #707070;
}
.add-po-main-container .form1-container{
    display: flex;
    flex-wrap: wrap;
}
.form1-container .add-purchase-input{
    width: 30%;
}
.add-po-main-container .form2-container{
    background-color: #fff;
    margin-top: 1%;
    position: relative;
}
.radio-groups-container{
    display: flex;
    width: 450px;
}
.add-po-main-container .item-information-container{
    display: flex;
}
.item-information-container .sm-input{
    width: 8%;
}
.item-information-container .md-input{
    width: 12%;
}
.item-information-container .add-po-input input{
    background-color: #fff !important;
    border-radius: 0;
    border: 1px solid #000;
}
.item-information-container .add-po-input-item input{
    background-color: #fff !important;
    border-radius: 0;
    /* border: 1px solid #000; */
}

.item-information-container div.MuiOutlinedInput-root{
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0;
}
.add-po-main-container .form2-container .total-balance-container .total-balance-container-child{
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: flex-end;
    margin:0 10% 0 auto
}
.total-balance-container .total-balance-container-child p{
    font-size: 0.9rem;
    font-weight: 600;
}
.total-balance-container .total-balance-container-child input{
    margin-left: 4%;
    height: 40px;
    background-color: #eee;
    border: none;
    width: 60%;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 4%;
}
.form2-container .plus-btn{
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #22b10c;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 6px;
    border: none;
}
.form2-container .plus-btn:hover{
    background-color: #05a84c;
}
.add-po-main-container .selected-items-table-container{
    padding: 0;
}
.add-po-main-container .tax-amount-container{
    background-color: #eee;
    margin: 0 0.8%;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tax-amount-container .tax-amount-container-child{
    width: 24%;
}
.tax-amount-container .second{
    width: 34% !important;
}
.tax-amount-container-child .total-input-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tax-amount-container-child .total-input-container input{
    margin-left: 4%;
    border: none;
    height: 48px;
    width: 70%;
    font-size: 1.2rem;
    padding-left: 2%;
    font-weight: 600;

}
.tax-amount-container-child .total-input-container p{
    font-weight: 600;
}
.tax-amount-container-child .total-input-container .disc-container{
    display: flex;
    justify-content: space-between;
    width: 73%;
}
.tax-amount-container-child .total-input-container .disc-container input.small{
    width: 30%;
}
.tax-amount-container-child .total-input-container .disc-container input.large{
    width: 68%;
}

.add-po-main-container .cancel-submit-container{
    display: flex;
    align-items: center;
    margin: 1% 0;
    justify-content: flex-end;
}
.add-products-validation{
    position: absolute;
    top: -8%;
    right: 0;
    font-weight: 600;
    color: red;
}