.employee-loan-details-top-container {
  display: flex;
  margin: 2% 0;
}
.employee-loan-details-top-left-container {
  width: 80%;
  padding: 0 4%;
}
.dashboard-employee-loan-details-input-containe {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-employee-loan-apply-loan {
  width: 62vw;
  padding: 2% 4%;
}
.dashboard-employee-loan-apply-loan-input-container {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-employee-loan-apply-loan-input-container .apply-loan-input {
  width: 31% !important;
}
.dashboard-employee-loan-apply-loan-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
}
.employee-loan-table-status{
  background-color:#e87373;
  color: #fff;
  padding: 2% 8%;
  font-size: 0.8rem;
  border-radius: 2px;
}
.emp-apply-loan-btn{
  background-color: #25ae29;
  height: 40px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 0.9rem;
  width: 17%;
  margin: 0 2%;
}
.emp-apply-loan-btn:hover{
  background-color: #1ec523 !important;
}
.employee-loan-top-container{
  display: flex;
  justify-content:flex-end;
  margin: 1% 0 2% 0;
}
.validation-alert-red{
  color: red;
  font-size: 0.7rem !important;
  margin-top: 1% !important;
  text-align: end;
}