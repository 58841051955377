.purchase-history-container{
    width: 60vw;
    padding: 2% 4%;
}
.purchase-history-container-first{
    display: flex;
    justify-content: space-between;
}
.purchase-history-heading{
    display: flex;
}
.purchase-history-heading p{
    margin: 0 4% 0 0;
    font-size: 1rem;
    font-weight: 600;
}
.purchase-history-heading-sub{
    color: blue;
    font-size: 0.8rem;
    font-weight: 600;
    margin:  1% 0;
}