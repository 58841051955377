.utility-settings-container{
    display: flex;
    height: 85vh;
}
.utility-settings-menu-container{
    display: flex;
    width: 100%;
    padding: 40px 0px;
    background-color: #fff;
    margin: 0 1%;
    flex-direction: column;
    border-radius: 4px;
}
.utility-settings-menu-list{
    width: 100%;
    display: flex;
    align-items: center;
    height: 38px;
    margin: 12px 0;
    font-weight: 600;
    font-size: 0.9rem;
    padding-left: 20px;
    cursor: pointer;
}
.utility-settings-menu-list:hover{
    background-color: #f2f3f9;
}
.utility-settings-menu-list p{
    font-size: 0.9rem;
    padding-left: 15px;
}
.discount-settings-container-body-section-percentage{
    align-self: flex-end;
    color: red;
    margin:2% !important;
}