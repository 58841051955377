.payroll-card-wrapper {
  background-color: #ffffff;
  padding: 1em;
  border-left: 5px solid #fbadfb;
  min-width: 27em;
  max-width: 31em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  height: 13em;
}
.payroll-card-wrapper:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.payroll-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.payroll-container > div {
  display: flex;
  /* justify-content: space-between; */
  gap: 1em;
}
.payroll-container > div :nth-child(3) {
    display: flex;
    align-self: baseline;
    justify-content: end;
}
.payroll-container > div :nth-child(3) .status-bar {
  display: flex;
  padding: 2px 8px;
  background-color: #e5fcea;
  border-radius: 20px;
  align-items: center;
  gap: 5px;
  /* min-width: 6em; */
  max-width: 6em;
  justify-content: center;
}
.payroll-container > div >div {
    min-width: 8em;
}
.payroll-container > div :nth-child(3) .status-bar .name {
  font-size: 13px !important;
  color: #127522;
  font-weight: 700 !important;
}
.payroll-container > div :nth-child(3) .status-bar span {
  font-size: 10px;
  color: #5eb77e;
}
.payroll-container > div .sub-name {
  margin: 0;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 600;
}
.payroll-container > div .name {
    font-size: 14px;
    font-weight: 600;
}
.payroll-container > div :nth-child(3) .status-bar .name {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}
