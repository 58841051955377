.staff-attendance-container{
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1%;
    
    width: 98%;
    border-radius: 2px;
}
.staff-attendance-container-top{
    background-color: #fff;
    width: 100%;
    display: flex;
    padding: 1% 2%;
    justify-content: space-between;
    align-items: center;
}

.staff-attendance-card-container{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}
.staff-attendance-card {
    background-color: #e4e7ff;
    padding: 2% 3%;
    width: 32%;
    margin: 1% 0 1% 0;
    margin-right: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
  }

.staff-attendance-card-icon-container {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #0020f1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .staff-attendance-card-text-container {
    width: 70%;
    color: #0020f1;
  }
  .staff-attendance-card-text-container h4 {
    margin: 1%;
  }
  .staff-attendance-card-text-container p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
  }



  /* Dialog View Attendance */
  /* Dialog View Attendance */
  /* Dialog View Attendance */

  .employee-attendance-view-container{
    width: 45vw;
    background: #fff;
    display: flex;
    flex-direction: column;
  }
  .employee-attendance-view-container-top{
    
    width: 96%;
    display: flex;
    margin: 3%;
    padding: 0% 1%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d8d8d8;
  }
  .employee-attendance-view-container-body{
    width: 96%;
    display: flex;
    flex-direction: column;
    margin: 0 3%;
    padding: 0% 1%;
  }
  .employee-attendance-view-container-body-section{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .btn-attendance-view-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }