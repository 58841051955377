.credit-wallet-container{
    display: flex;
    background-color: #fff;
    padding: 1%;
    border-radius: 4px;
}
.credit-wallet-left-container{
    /* flex: 50; */
    /* border-right: 1px solid rgb(160, 160, 160); */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
}

.credit-card-container{
    width: 80%;
    height:200px;
    border-radius: 8px;
  background: rgb(172, 115, 239);
  background: linear-gradient(
    148deg,
    rgba(172, 115, 239, 1) 30%,
    rgba(177, 119, 234, 1) 45%,
    rgba(251, 183, 160, 1) 88%
  );
  color: #fff;
  position: relative;
  box-shadow: 2px 2px 16px rgb(218, 216, 216);
  padding: 6%;
  
}
.credit-card-container h5{
    margin: 2% auto 2% 6%;
    text-align: left;
}
.credit-card-container .card-amount{
    margin: 6% auto 2% 6%;
    font-size: 1.6rem;
    text-align: left;
}
.credit-card-container p{
    margin: 14% auto 2% 6%;
    text-align: left;
}
.credit-card-container .card-line-1{
    width: 8%;
    background-color: #fff;
    position: absolute;
    top: 4px;
    right: 92px;
    border: none;
    border-top: 1px solid #fff;
}
.credit-card-container .card-line-2{
    width: 12%;
    background-color: #fff;
    position: absolute;
    transform: rotate(90deg);
    top: 88px;
    left: -4px;
    border: none;
    border-top: 1px solid #fff;
}
.credit-card-container .card-line-3{
    width: 20%;
    background-color: #fff;
    position: absolute;
    bottom: 12px;
    right: 32px;
    border: none;
    border-top: 1px solid #fff;
}
.credit-wallet-right-container{
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 2% 0;
}
.credit-wallet-right-container h4{
margin: 2% 14%;
}
.credit-wallet-right-container p{
    margin: 2% 14%;
    font-size: 2rem;
    font-weight: 600;
}
.credit-wallet-right-container .credit-wallet-amount-container{
    display: flex;
    margin: 3% 14%;
    justify-content: space-between;
    align-items: flex-end;
    height: 80px;
    width: 73%;
   
}
.credit-wallet-single-amount-container{
    display: flex;
    align-items: center;
    background-color: #fed8d8;
    justify-content: space-between;
    height: 40px;
    width: 47%;
    padding: 0 5%;
    border-radius: 4px;
    
}
.credit-wallet-single-amount-container p{
    font-size:1rem;
    font-weight:600;
    color:#eb0000 ;
    margin: 1%;
}
.credit-wallet-amount-container .second-amt-container{
    background-color: #d9ffec !important;
}
.credit-wallet-amount-container .second-amt-container p{
    color: #00c965;
}