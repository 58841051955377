.job-com-report-top-ctn-body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.job-com-report-top-ctn-btn{
    position: absolute;
    top: 70%;
    right: 0;
}
.job-com-table-icon-ctn{
    display: flex;
    float: right;
    padding: 2%;
    position: relative;
}