.pending-payment-voucher-parent-container .pending-payment-voucher-main-container{
    background-color: #fff;
    padding: 1%;
}
.pending-payment-voucher-main-container-top{
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
}
.pending-payment-voucher-main-container-top>:nth-child(2){
    color: #01b768;
    font-size: 2.4rem;
    justify-content: flex-end;
}
/* print view */
.pending-payment-voucher-single-print{
    margin: 3% 6%;
    border: 1px solid #eee;
}
.pending-payment-voucher-body-section{
    display: flex;
    border-bottom: 1px solid #eee;
}
.pending-payment-voucher-body-section-left{
    padding: 0 3%;
    width: 50%;
    border-right: 1px solid #eee;
    font-weight: 500;
}
.pending-payment-voucher-body-section-right{
    padding: 0 3%;
    width: 50%;
}
.pending-payment-voucher-body-signature-section{
    display: flex;
}
.pending-payment-voucher-body-signature-section-box{
    width: 50%;
    padding: 3%;
    font-weight: 500;
}