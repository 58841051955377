.establish-head{
    padding: 1%;
    margin: 4px 0;
    position: relative;
}
.establish-create{
    background-color: #133de0;
    color: #fff;
    border: none;
    border-radius: 4px;
    height: 32px;
    width: 120px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}
.establishment-card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
}
.establishment-card {
    background-color: #fff;
    padding: 10px;
    width: calc(25% - 20px);
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 6px solid #bba3e3;
    cursor: pointer;
}
.establishment-card-text-container {
    width: 100%;
}
.establishment-card-text-container h5 {
    margin: 1%;
    padding: 4%;
}
.establish-dialogue-form{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
   
}
.dialogue-ctn{
    padding: 11px 36px;
}
