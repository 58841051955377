.settings-cutting-slip-model{
    background-color: #fff;
    padding: 2%;
    border-radius: 8px;

}
.settings-cutting-slip-model-divider{
    border: 1px dashed #8f8a8a;

}
.settings-cutting-slip-model-one{
    padding: 1%;
}
.settings-cutting-slip-model-radio-button{
    /* display: flex;
    align-items: center; */
}
.settings-cutting-slip-model-radio-button input{
    accent-color: #000;
    cursor: pointer;
}
.settings-cutting-slip-model-radio-button label{
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 2px;
    font-weight: 600;
}