.salwar-cutting-slip-input-container{
    display: flex;
    width: 100%;
    justify-content: space-between
}
.salwar-cutting-slip-sub-input-container{
    width:44%;
    display: flex;
}
.large-input-salwar-cutting-slip{
    width: 45% !important;
    background-color: #fff !important;
    margin: 7% 0 1% 5% !important;
    justify-content: space-between;
}
.salwar-cutting-slip-table-container{
    min-height: 300px !important;
}

/******* salwar1 cutting slip css ********/
.salwar1-cutting-slip-middle-top-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 4px;
    /* flex-wrap: wrap; */
}
.salwar1-cutting-slip-middle-top-container P{
    font-size: 0.8rem;
    /* font-weight: 600; */
    margin: 0;
}
.salwar1-two{
    width:80%;
    justify-content: flex-end;
    margin-top: 4px;
}
.salwar1-cutting-slip-left-p{
    width:55px
}
.salwar-elastic-size{
  width:82px;
}