.jalabia-cutting-slip-middle-container {
  display: flex;
  flex-wrap: wrap;
}
.jalabia-cutting-slip-middle-input-container {
  border: 1px solid rgb(173, 171, 171);
  padding: 0.2%;
  width: 9%;
  height:32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #eee;
  margin: 1% 0 1% 0;
}
.jalabia-cutting-slip-input-value {
  font-size: 0.9rem;
  font-weight: 600;
}
.jalabia-cutting-slip-input-key{
  font-size: 0.7rem;
}
.large-input-jalabia-cutting-slip {
  width: 14% !important;
  background-color: #fff !important;
  margin: 1% 0 1% 1% !important;
  justify-content: space-between;
}
.text-container-jalabial-cutting-slip {
  border: none !important;
  background-color: #fff !important;
  justify-content: space-between;
}
.jalabia-cutting-slip-input-value-text {
  font-size: 0.8rem;
  font-weight: 600;
}
.notes-jalabia-cutting-slip {
  border: none !important;
  background-color: #fff !important;
  /* flex-direction: column; */
  /* align-items: flex-start !important; */
  width: 45% !important;
  height: 32px;
  padding: 0 !important;
  margin: 1% 0 0 0 !important;
  justify-content: right;
}
.notes-jalabia-cutting-slip p {
  margin: 0 5% 0 0;
}
.notes-jalabia-cutting-slip input {
  width: 70%;
  height: 100%;
  /* margin: 2% 0; */
  border: 1px solid rgb(173, 171, 171);
  border-radius: 4px;
}
.notes-level-input {
  /* margin-top: 2.5% !important; */
}
.jalabia-cutting-slip-tailor-qrcode-container {
  margin: 2% 0 0 0;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  align-self: flex-end;
  width: 100%;
  height: 120px;
}
.jalabia-cutting-slip-tailor-cuttingMaster-container {
  border: 1px solid rgb(163, 159, 159);
  padding: 0 1%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jalabia-cutting-slip-tailor-container {
  display: flex;
  /* justify-content: space-between; */
  /* padding: 1%; */
  margin:1%;
}
.jalabia-cutting-slip-tailor-container p {
  margin: 0;
  font-size: 0.75rem;
}
.jalabia-cutting-slip-tailor-input {
  border: 1px solid rgb(163, 159, 159);
  /* padding:2%; */
  width: 17%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jalabia-cutting-slip-cutting-master-container {
  display: flex;
  justify-content: space-between;
  /* padding: 1%; */
  margin:0 1%;
}
.jalabia-cutting-slip-cutting-master-input-container {
  border: 1px solid rgb(163, 159, 159);
  /* padding: 1% 2%; */
  width: 80%;
  height: 30px;
  display: flex;
}
.jalabia-cutting-slip-cutting-master-input-container p {
  border-right: 1px solid rgb(163, 159, 159);
  height: 100%;
  width: 25%;
  margin-left: 4px !important;
}
.jalabia-cutting-slip-cutting-master-container p {
  margin: 0;
  font-size: 0.8rem;
}
.jalabia-cutting-slip-qr-code-container {
  border: 1px solid rgb(163, 159, 159);
  /* padding: 1%; */
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}
.jalabia-cutting-slip-qr-code-container img {
  width: 99%;
  height: 90%;
}

/*********** jalabia cuttingslip1 css ***********/
.jalabia1-cutting-slip-middle-top-container{
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-top:4px ;
}
.jalabia1-cutting-slip-middle-top-container p{
  font-size: 0.8rem;
  /* font-weight: 600; */
  margin: 0;
}
.jalabia1-two{
  width: 70%;
}
.jalabia1-three{
  width:40%;
}
.jalabia1-four{
  justify-content: flex-end;
  width: 65%;
}
.jalabia-five{
  width: 40%;
}
.jalabia1-cutting-slip-middle-top-single-container{
  display: flex;
  /* justify-content: space-between; */
  border: 1px solid #000;
  border-radius: 4px;
  width:23%;
  padding: 4px;
  height: 100%;
}
.jalabia1-cutting-slip-single-left-p{
  width: 55px;
}
.jalabia1-shoulder{
  width: 65px;
}
.jalabia1-regal{
  width: 45px;
}
