.report-name-icon-container{
    display: flex;
    padding: 1% 2%;
    background-color: #f4efff;
    justify-content: space-between;
    margin: 1% 2%;
}
.report-name-icon-icon-container{
    display: flex;
    width: 21%;
    /* justify-content: space-between; */
    align-items: center;
}
.report-name-icon-name-container{
    width: 40%;
    margin: 1%;
}
.report-name-icon-single-name-container{
    display: flex;
}
.report-name-icon-single-name-container p{
    margin: 2%;
    font-size: 1rem;
    font-weight: 600;
}
.report-name-icon-single-name-container .name{
    width: 35%;
    display: flex;
    justify-content: space-between;
}
.report-name-icon-single-name-container .data{
    width: 55%;
}
.purchase-report-total-purchase-container{
    background-color:#e8dcff ;
    margin: 2%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1% 3%;
}
.purchase-report-total-purchase-container input{
    border: none;
    height: 40px;
    font-size: 1rem;
    padding: 1%;
    text-align: right;
    font-weight: 600;
    width: 20%;
}
.purchase-report-total-purchase-container p{
    margin: 1% 6% 1% auto;
    font-size: 1rem;
    font-weight: 600;
}
.purchase-report-single-view-container{
    width: 62vw;
    padding: 1%;
}
.purchase-report-single-view-icon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
}
.purchase-single-view-name-container{
    width: 80%;
    display: flex;
}
.purchase-single-view-name-container .purchase-single-view-name-first-container{
    width: 50%;
    border-right: 1px solid rgb(184, 182, 182);
}
.purchase-single-view-name-container .purchase-single-view-name-second-container{
    width: 50%;
    display: flex;
    flex-direction: column;
   justify-content: center;
}

.purchase-report-single-view-name p{
    font-size: 0.8rem !important;
    margin: 2% 4%;
}
.purchase-report-single-view-name .name{
  width: 50% !important;
}
.purchase-report-single-view-main-name p{
    font-size: 0.9rem;
    margin: 4%;
}

