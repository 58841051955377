.transfer-container{
    padding: 2%;
    background-color: #fff;
}
.transfer-top-container{
    display: flex;
    justify-content: space-between;
}
.transfer-top-container h3{
    margin:0;
}
.transfer-table{
    margin:1% 0;
    padding: 0;
}
.add-transfer-container{
    width: 60vw;
    padding: 2%;
}
.add-transfer-container h3{
    margin: 1% 0;
}
.add-transfer-input-container{
    display: flex;
    flex-wrap: wrap;
}
.add-transfer-input-second-container{
    border: 1px solid rgb(204, 194, 194);
    /* display: flex; */
    padding: 1%;
    border-radius: 3px;
    margin: 1% 0 3% 0;

}
.add-transfer-input-container-second{
    width: 100%;
    display: flex;
}
.add-transfer-product-name{
    width:40% !important;
    position: relative;
}
.add-transfer-qty{
    width: 26% !important;
    position: relative;
}
.add-transfer-unit{
    width: 20% !important;
}
.add-transfer-trns-qty{
    width: 25% !important;
    position: relative;
}
.add-transfer-add-button-container{
    display: flex;
    justify-content:flex-end;
}
.add-transfer-add-button{
    background-color: #009b53;
    border: none;
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
    width: 7%;
    height: 46px;
    margin: 1%;

}
.add-transfer-table{
    padding:0 2%;
    margin: 2% 0;
}
.add-transfer-table tbody tr td{
  background-color: #fff !important;

}
.add-transfer-button-container{
    display: flex;
    justify-content: center;
    margin:4% 0;
}
.add-transfer-checkbox-container{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    width: 80%;
}
.add-transfer-checkbox{
    display: flex;
    align-items: center;
}
.add-transfer-checkbox input{
    width: 12px;
    height: 12px;
    accent-color: #000;
    cursor: pointer;
}
.add-transfer-checkbox label{
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
}

.add-transfer-radio-btn-container{
    display: flex;
    margin-top: 4%;
    width: 46%;
    justify-content: space-between;
}
.add-transfer-radio-btn label{
   font-size: 0.9rem;
   font-weight: 600;
   margin-left: 6px;
   cursor: pointer;
}
.add-transfer-radio-btn input{
    accent-color: #000;
    cursor: pointer;
}
