.cash-reconciliation {
  padding: 1%;
}
.date-picker-wrapper-container {
  background-color: white;
  padding: 2em;
  margin-block-end: 0.5em;
}
.date-picker-input-box-container {
  display: flex;
  justify-content: center;
  gap: 2em;
  margin-block-end: 1.5em;
}
.date-picker-input-box-container .global-single-input {
  width: 25%;
}

.table-wrapper-container {
  padding: 1%;
  background-color: white;
}

.action-bar {
  display: flex;
  justify-content: flex-end;
}
.action-bar button {
  height: 2em;
  width: 2em;
}
.action-bar .download-btn {
  color: red;
}

.head-cash-book {
  display: flex;
  justify-content: flex-end;
  gap: 20%;
  padding: 0% 15%;
}
.cash-book-wrapper-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 2%;
  padding-left: 10%;
}

.cash-book {
  /* padding: 1%; */
}
.cash-book-wrapper-container input {
  width: 15%;
  height: 2.5em;
  border: none;
}
.balance-as-per-company-book,
.amount-not-reflected-in-cash,
.balance-as-per-cash-book {
  display: flex;
  margin-bottom: 2%;
  gap: 5%;
  background-color: #a6a7af;
  padding: 2%;
}
.total-value-show-input-box-container {
  display: flex;
  gap: 5%;
  width: 100%;
}

.cash-book-wrapper-container h4 {
  font-weight: bold;
  width: 20em;
  margin: 0;
  color:white;
}

.save-cancel-container {
  display: flex;
  justify-content: center;
  gap: 2em;
  margin-block-start: 2em;
  margin-block-end: 2em;
}

.save-cancel-container button {
  padding: 10px;
  width: 10%;
  height: 3.5em;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.save-cancel-container .save-btn {
  background-color: #1cd100;
  color: white;
}
.save-cancel-container .cancel-btn {
  color: black;
  background-color: white;
  border: 1px solid black;
}
