.ledger-input-container {
  width: 100%;
  display: flex;
}
.ledger-input-container-box {
  display: flex;
  flex-wrap: wrap;
}
.ledger-input-container-btn-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 6px;
  margin-block-end: 20px;
}
.ledger-input-container-btn {
  background-color: #005dff;
  color: white;
  border: none;
  padding: 10px;
  width: 10em;
  border-radius: 5px;
  cursor: pointer;
}
.ledger-container {
  padding: 2% 3%;
}
.bottom-line{
  border-bottom: 1px solid #ebebeb;

}
.ledgerSection-input-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;


}
.ledgerSection-input-container > :nth-child(1) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block-end: 2em;
}
.ledgerSection-input-container > :nth-child(2) {
  width: 100%;
  display: flex;
  margin-block-end: 2em;
  
}
.ledgerSection-input-container > :nth-child(3) {
  width: 100%;
  
}
.tax-rate-input-section {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.ledger-section-container-input-bank{
  display: flex;
  flex-wrap: wrap;
 
}
.ledger-section-container-input-bank > div{
width: 100%;
display: flex;
column-gap: 5px;
justify-content: space-between;
margin-block-end: 2em;
}
