:root {
  --border: 1px solid #e5e5e5;
}

.pay-in-slip .total-container > div {
  width: 95% !important;
}
.pay-in-slip .pay-in-slip-employee-wrapper {
  width: 100%;
  flex-wrap: wrap;
}
.pay-in-slip .pay-in-slip-employee-wrapper .slip-details {
  width: 100%;
}
.pay-in-slip .pay-in-slip-employee-wrapper .slip-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pay-in-slip .pay-in-slip-employee-wrapper .slip-header .save-document {
  display: flex;
  gap: 10%;
}

.pay-in-slip .pay-in-slip-employee-wrapper .print-btn,
.download-btn {
  border: none;
  width: 5em;
  height: 2.2em;
  cursor: pointer;
}

.pay-in-slip .pay-in-slip-employee-wrapper .print-btn {
  background-color: #cccfff;
}
.pay-in-slip .pay-in-slip-employee-wrapper .print-btn i {
  font-size: 1.5em;
  color: blue;
}
.pay-in-slip .pay-in-slip-employee-wrapper .download-btn {
  background-color: #fccccc;
  color: red;
}
.pay-in-slip .pay-in-slip-employee-wrapper .css-bq05nv-MuiSvgIcon-root {
  font-size: 1.9em;
  color: red;
}

.slip-details .employee-details {
  display: flex;
  width: 100%;
}
.slip-details .employee-details > :nth-child(1) ul li {
  justify-content: flex-start;
}
.slip-details .employee-details > :nth-child(2) ul li {
  justify-content: flex-end;
}

.slip-details .employee-details > div {
  width: 100%;
  display: inherit;
}
.slip-details .employee-details > div ul {
  list-style: none;
  padding-left: 0;
  width: 100%;
}
.slip-details .employee-details > div ul li {
  display: flex;
}
.slip-details .employee-details > div ul li .employee-details-user-data {
  margin: 1% 0;
  width: 30%;
}
.slip-details .employee-details > div ul li .employee-details-user-data span {
  text-align: start;
}

.employee-bank-details {
  padding: 2% 5%;
}
.employee-bank-details > :nth-child(1) {
  border-left: var(--border);
  border-top: var(--border);
  border-bottom: var(--border);
}
.employee-bank-details > :nth-child(2) {
  border: var(--border);
}
.employee-bank-details > div ul li {
  justify-content: center !important;
}
.employee-bank-details > div ul li .employee-details-user-data {
  width: 40% !important;
}

.attendance-value-table {
  padding: 2% 5%;
}
.attendance-value-table table {
  border-collapse: collapse;
  border: var(--border);
  width: 100%;
}
.attendance-value-table table th {
  border-collapse: collapse;
  border: var(--border);
  padding: 1%;
  text-align: center;
  width: 50%;
}
.pay-in-slip-employee
  .attendance-value-table
  #attendance-table
  .attendance-head
  th,
.attendance-body td {
  border-collapse: collapse;
  border-left: var(--border);
  border-right: var(--border);
  padding: 1%;
  text-align: center;
}
.pay-in-slip-employee-hr {
  border-top: 2px dotted #707070;
  width: 100%;
}

.earnings-and-deduction.attendance-value-table table th {
  text-align: start;
  padding: 2%;
}
#earnings-and-deduction-table .earnings-and-deduction-head #main-head th {
  width: 0% !important;
}
#earnings-and-deduction-table
  .earnings-and-deduction-head
  #main-head
  :nth-child(1) {
  color: red;
}
#earnings-and-deduction-table
  .earnings-and-deduction-head
  #main-head
  :nth-child(2) {
  color: green;
}
#earnings-and-deduction-table .earnings-and-deduction-head #sub-head th {
  width: 25% !important;
  font-size: 13px;
}
.earnings-and-deduction-body tr td {
  padding: 2%;
  font-size: 13px;
  font-weight: 600;
}
.earnings-and-deduction-body tr .earnings {
  border-right: var(--border);
}
.earnings-and-deduction-body tr .table-total {
  border-top: var(--border);
  padding: 2%;
}
.pay-in-slip-employee .payroll-payslip-container-body-footer h4 {
  text-align: center;
  width: 100% !important;
}

.attendance-report .submit-btn-container {
  /* display: flex;
justify-content: center;
align-items: center; */
}
.staff-payroll-edit-bottom-container .btn {
  background-color: #ffaa00;
  color: white;
}
.payment-report.payroll-payslip-container-header {
  padding: 3% 5%;
}
#main-head.payment-report :nth-child(1) {
  color: black !important;
}
#main-head.payment-report :nth-child(2) {
  color: black !important;
}

@media print {
  #print-slip {
    transform: scale(0.8);
    padding: 3%;
  }

  #print-slip .slip-header title {
    text-align: center;
    width: 100%;
  }

  #print-slip .payroll-payslip-container-body-footer h4 {
    text-align: center;
    width: 100% !important;
  }

  #print-slip .payroll-payslip-container-body-footer {
    padding: 1% 0;
    margin: 0%;
    height: 11%;
  }
  
  #print-slip .payroll-payslip-container-body-footer p,
  h4 {
    margin: 1px;
  }
}
