.expense-top-container{
    display: flex;
    justify-content: space-between;
    padding: 0 1%;
}
.expense-top-container h3{
    margin-left: 8px;
}
.expense-top-container .staff-expense-button{
    background-color: #d50a0a;
    font-size: 0.8rem !important;
    font-weight: 600;
}
.expense-top-container .staff-expense-button-disabled{
    background-color: rgb(245, 171, 171);
    cursor: no-drop;
}
.expense-top-container .expense-button{
    font-size: 0.8rem !important;
    font-weight: 600;
}


.add-expense-dialog-container{
    width: 38vw;
    padding:4% 6%;
}
.add-expense-dialog-container h3{
    margin: 0;
}
.staff-expense-input{
    width: 98% !important;
    margin: 3% 1% !important;
}
.expense-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:6% 0 4% 0;
    width: 100%;
}
.expense-button-container button{
    width: 23% !important;
}

/* outlet Expense css */
.add-outlet-expense-container{
    display: flex;
    flex-wrap: wrap;
}
.outlet-expense-input{
    width: 48%;
    position: relative;
}
.outlet-expense-payment-button{
    width: 60%;
    background-color: #0bc55c;
    align-self: center;
    margin: 11% 0 0 0;
    border: none;
    height: 40px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
.outlet-expense-payment-button:hover{
    background-color: #2fdb7a;
}
.outlet-expense-password-input{
    width: 94% !important;
    margin: 3%;
    position: relative;
}
.password-visibility-icon{
    position: absolute;
    right: 3%;
    top: 37%;
}


/* add expense type */
.add-expense-top-container{
   background-color: #fff;
   padding: 2%;
}
.add-expense-top-container h3{
    margin: 0;
}
.add-expense-input-container{
    display: flex;
}
.add-expense-button{
    align-self: flex-end;
    margin: 1% 1% 1% auto;
}